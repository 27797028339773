import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getFirestore, collection, getDocs, where, query, orderBy, limit } from "firebase/firestore";
import app from "../../../firebase.js";
import moment from "moment";
import Icon from "../../Icon";
import styles from "./notification.module.sass";

const Notification = ({ className }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState();
  const [scrolling, setScrolling] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);  // Track if the user has seen the activities
  const db = getFirestore(app);
  const rtdb = getDatabase(app);
  const navigate = useNavigate();
  const { appid } = useParams();
  const userId = `${JSON.parse(localStorage.getItem('user'))?.id}_${localStorage.getItem('orgId')}`;
  
  const activityListRef = useRef(null);
  
  // Fetch unread count from Realtime Database
  useEffect(() => {
    if (userId) {
      const unreadRef = ref(rtdb, `users/${userId}/unreadCount`);
      onValue(unreadRef, (snapshot) => {
        const data = snapshot.val();
        if (data!==null&&data!==undefined) {
          setUnreadCount(data);  // Update unreadCount when it changes
        }
      });
    }
  }, [userId, rtdb, visibleHelp]);
  

  useEffect(() => {
    setActivities([])
    if (visibleHelp) {
      unreadCount&& fetchActivities();
    }
  }, [visibleHelp,unreadCount]);

  // Fetch activities from Firestore
  const fetchActivities = async () => {
    if (loading) return;
      setLoading(true);
      try {
        const orgId=localStorage.getItem('orgId')
        const q = query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", false), // Only include documents where activity_feed is true
            where("organization_id", "==", orgId), // Only include documents where app_id
            orderBy("timestamp", "desc"),
            limit(unreadCount)
        );
  
        const querySnapshot =orgId&& await getDocs(q);
        const newActivities = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        setActivities((prevActivities) => {
          const prevActivityIds = new Set(prevActivities.map((activity) => activity.id));
          const uniqueActivities = newActivities.filter((activity) => !prevActivityIds.has(activity.id));
          return [...prevActivities, ...uniqueActivities];
        });
      } catch (error) {
        console.error("Error fetching activities: ", error);
      } finally {
        setLoading(false);
        handleScroll();
      }
  };

  // Mark all as read when scrolling to the bottom
  const handleScroll = () => {
    const listHeight = activityListRef.current.scrollHeight;
    const containerHeight = activityListRef.current.clientHeight;
    const scrollTop = activityListRef.current.scrollTop;
    const bottom = listHeight === scrollTop + containerHeight;
    
    if (bottom && listHeight >= containerHeight) {
      // setScrolling(true);
      setHasSeen(true);  // User has seen the activities
      // markAllAsRead();  // Mark all activities as read by updating unreadCount to 0
    }
  };

  // Mark all activities as read
  const markAllAsRead = async() => {
    if (userId) {
      try {
        await set(ref(rtdb, `users/${userId}/unreadCount`), 0);
      } catch (error) {
        console.log("error",error)
      }
    }
  };

  // Reset unread count when visibleHelp changes
  useEffect(() => {
    if (visibleHelp) {
    }
    else{
      setUnreadCount(0);
      hasSeen&& markAllAsRead();
      setHasSeen(false); // Reset hasSeen when the section becomes visible
      setActivities([])
    }
  }, [visibleHelp,hasSeen]);

  const boldText = (text) => {
    return text.replace(/'(.*?)'/g, (match, p1) => {
      return `<span style="color: #2A85FF">${p1}</span>`;
    });
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisibleHelp(false)}>
        <div style={{position:"relative"}}>
        {unreadCount?<div style={{fontSize:"12px",position:"absolute",top:"-10%",left:"70%",zIndex:10,background:"rgba(249, 198, 38, 0.9)",color:"white",padding:"2px",borderRadius:"100%",lineHeight:"12px",height:"16px",width:"16px",textAlign:"center"}}>{unreadCount}</div>:""}
        <div className={cn(styles.user, className, {[styles.active]: visibleHelp})}>
            <button className={styles.headNotification} onClick={() => setVisibleHelp(!visibleHelp)}>
                <Icon className={styles.notificationIcon} name="notification" size="24"/>
            </button>
          <div className={styles.body}>
            <div className={styles.activityTable}>
              <div className={styles.title}>Notification</div>
              <ul
                className={styles.activityList}
                ref={activityListRef}
                onScroll={handleScroll}
              >
                {(activities?.length > 0 && unreadCount) ? (
                  activities.map((activity) => (
                    <li key={activity.id} className={styles.activityItem}>
                      <span className={styles.iconWrapper}>
                        <Icon
                          className={cn(
                            styles.activityIcon,
                            {
                              [styles.create]: activity?.action_type === "CREATE",
                              [styles.edit]: activity?.action_type === "UPDATE",
                              [styles.delete]: activity?.action_type === "ARCHIVE",
                            }
                          )}
                          name={
                            activity?.action_type === "UPDATE"
                              ? "edit"
                              : activity?.action_type === "ARCHIVE"
                              ? "trash"
                              : activity?.action_type === "CREATE"
                              ? "file-add"
                              : "activity"
                          }
                          size="20"
                        />
                      </span>
                      <div className={styles.activityContent}>
                        <div className={styles.performedBy}>
                          <div className={styles.activity}>
                            <div
                              title={activity.description}
                              className={styles.description}
                              dangerouslySetInnerHTML={{ __html: boldText(activity.description) }}
                            ></div>
                            <div className={styles.userdetail}>
                              <div className={styles.name}>by {activity.performed_by}</div>
                              <div className={styles.timeStamp}>
                                <div className={styles.date}>
                                  <div>
                                    {activity?.timestamp &&
                                      moment(activity?.timestamp).format('DD/MM/YYYY hh:mm A')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p
                    style={{
                      color: "#777",
                      fontWeight: "600",
                      textAlign: "center",
                      fontSize: "13px",
                      background: "none",
                    }}
                  >
                    No Notification Available
                  </p>
                )}
              </ul>
              {activities?.length > 0 && (
                <div className={styles.cardSave}>
                  <button className={styles.save} onClick={() => navigate(`/activityFeed/${appid}`)}>
                    View all
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Notification;
