import React, { useEffect } from "react";
import styles from './billing.module.sass'
import Plans from "../Plans";
import BillingHistory from "../BillingHistory";
import BillingCycle from "../BillingCycle";
import Payment from "../Payments";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loaders/LoaderProgress";
import { getAllPaymentAction, getInvoiceAction, getMyPlanAction } from "../../../../redux/action/organisation/Billing/billingAction";
import OrganizationHeader from '../../../../components/Header/OrganizationHeader'

const Pricing = () => {

    const dispatch = useDispatch()

    const getAllBillingHistory = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== undefined){
            let customer = {
                customerId: JSON.parse(customerId)
            }
            dispatch(getInvoiceAction(customer))
        }
    }

    let {invoices, isLoadingInvocies} = useSelector((state) => state.billing)

    useEffect(() => {
        getAllBillingHistory()
    }, [])

    if(isLoadingInvocies){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <OrganizationHeader title='Billings' />
            <div className={styles.wrapper}>
                <BillingHistory className={styles.card} items={invoices}/>
            </div>
        </>
    )

}

export default Pricing