import React from "react";
import Card from "../../../../../components/Card";
import styles from './notification.module.sass'
import TextInput from "../../../../../components/TextInput";
import Icon from "../../../../../components/Icon";
import cn from "classnames";

const NotificationSettings = ({className}) => {
    return(
        <>
            <Card classTitle="title-red">
                <div className={styles.wrapper}>
                    <div className={styles.cardHead}>
                        <p className={styles.title}>General Notifications</p>
                        <p className={styles.info}>Manage your organisation notification.</p>
                    </div>
                    <div className={styles.cardBody}>
                        <div className={styles.general}>

                            <div className={styles.group}>
                                <div className={styles.field}>Subscription</div>
                                <div className={styles.field}>Email</div>
                                <div className={styles.field}>Contact</div>
                                <div className={styles.fieldAction}>Action</div>
                            </div>
                            
                            <div className={styles.group}>
                                <TextInput className={styles.field} disabled value='Billing-Dirtcube Interactive' />
                                <TextInput className={styles.field} disabled value='admin@dirtcube.xyz' />
                                <TextInput className={styles.field} disabled value='Select value' />
                                <div className={styles.fieldAction}>
                                    <button className={styles.action}>
                                        <div className={styles.icon}><Icon name='add' size='12' /> </div>
                                        <div className={styles.add}>Add</div>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className={styles.wrapper}>
                    <div className={styles.cardHead}>
                        <p className={styles.title}>Invoices</p>
                        <p className={styles.info}>Manage invoice notification.</p>
                    </div>
                    <div className={styles.cardBody}>
                        <div className={cn(styles.general, {[styles.border]:true})}>
                            
                            <div className={styles.group}>
                                <div className={styles.field}>Billing-Dirtcube Interactive</div>
                                <div className={styles.field}>admin@dirtcube.xyz</div>
                                <div className={styles.field}>-</div>
                                <div className={styles.fieldAction}>
                                    <button className={styles.action}>
                                        <div className={styles.icon}><Icon name='add' size='12' /> </div>
                                        <div className={styles.add}>Add</div>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default NotificationSettings