import React, { useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./otp.module.sass";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePlaceHolder from '../../../../media/images/placeholders/Profile.png'

const SpaceModal = ({onClose, setWorkSpace, spaces, showLoader, setShowLoader, setLoaderType, showModal, setShowQuickGuideModal}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selected, setSelected] = useState(null)

  const save = () => {
    let selectedOrg = spaces && spaces.filter(e => e.id === selected)
    let apiKeys = selectedOrg[0].api_keys
    localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
    let orgId = selectedOrg[0].id
    localStorage.setItem('orgId', orgId)
    console.log(selectedOrg)
    let orgName = selectedOrg[0].name
    localStorage.setItem('orgName', JSON.stringify(orgName))
    let location = selectedOrg[0]?.location
    sessionStorage.setItem('location', JSON.stringify(location)) 
    let customerId = selectedOrg[0]?.external_customer_id
    sessionStorage.setItem('customerId', JSON.stringify(customerId)) 
    let gstTreatment = selectedOrg[0]?.gst_treatment
    sessionStorage.setItem('gstTreatment', JSON.stringify(gstTreatment))
    navigate('/home')
    setShowLoader(true)
    setLoaderType(1)
    setTimeout(() => {
      setShowLoader(false)
      if(showModal){
        setShowQuickGuideModal(true)
      }
    }, 4000)
  }

  const selectOrg = (x) => {
    let workSpace = spaces && spaces.map(item => item.id === x ? {...item, selected: true} : {...item, selected: false})
    setWorkSpace(workSpace)
    setSelected(x)
  }

  return (
    <>
      <ToastContainer />
      <div className={styles.otpScreen}>
        <div className={styles.otp_outer_container}>
          <div className={styles.otp_inner_container}>
            <div className={styles.otpWrapper}>
              <div className={styles.wrapper}>
                <div className={styles.code}>
                  <div className={styles.body}>
                    <div className={styles.title}>Select Organisation<span className={styles.blueText}>!</span></div>
                    <div className={styles.info}>Please select one organisation to continue.</div>
                    
                    <div className={styles.selection}>
                      {spaces && spaces.map(item => (
                        <>
                          <div className={cn(styles.workSpace, {[styles.active] : item.selected})} onClick={() => selectOrg(item.id)}>
                            <div className={styles.logo}>
                              <img src={(item.default_logo_url && item.default_logo_url !== null) ? item.default_logo_url : ProfilePlaceHolder} alt={item.name}/>
                            </div>
                            <div className={styles.detail}>
                              <div className={styles.name}>{item.name}</div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className={styles.verifyAccount}>
                      <button disabled={selected === null} className={cn("button-save-small-full", styles.button)} onClick={save}>
                        <span>Continue</span>
                      </button>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpaceModal;
