// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase, ref, get, set, child} from "firebase/database"; 
import axios from "axios";
import { BASE_URL, GET_APPS} from "./resources/APIEndpoints";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Realtime Database
const realtimeDb = getDatabase(app);  // Initialize the Realtime Database

const dbRef = ref(realtimeDb, "users"); // Reference to your "users" node in Realtime Database

// Function to increase unreadCount for all users in the same organization
const increaseUnreadCountForAllUsers = async ( appId = null) => {
  try {
    // Get all users in the "users" collection
    const snapshot = await get(dbRef);
    
    if (snapshot.exists()) {
      const users = snapshot.val(); // Get the users as an object

      // Iterate over each user in the object
      for (const userKey in users) {
        if (users.hasOwnProperty(userKey)) {
          const userData = users[userKey];
          const currentUserKey = appId?`${JSON.parse(localStorage.getItem('user'))?.id}_${localStorage.getItem('orgId')}_${appId}`:`${JSON.parse(localStorage.getItem('user'))?.id}_${localStorage.getItem('orgId')}`;
          // Skip the current user by checking the key
          if (userKey === currentUserKey) {
            continue; // Skip updating this user
          }

          if (appId) {
            // Case 1: Increase unread count for users with matching `appId`
            if (userData.appId === appId) {
              const unreadRef = ref(realtimeDb, `users/${userKey}/unreadCount`);

              // Get the current unread count
              const unreadCountSnapshot = await get(unreadRef);
              const currentUnreadCount = unreadCountSnapshot.exists() ? unreadCountSnapshot.val() : 0;

              try {
                // Increment the unread count
                await set(unreadRef, currentUnreadCount + 1);
                console.log(`Unread count incremented for user with appId: ${appId}`);
              } catch (error) {
                console.error("Error updating unread count for appId:", error);
              }
            }
          } else {
            const orgId=localStorage.getItem('orgId');
            // Case 2: Increase unread count for users with matching `orgId` and no `appId`
            if (userData.organisationId === orgId && !userData.appId) {
              const unreadRef = ref(realtimeDb, `users/${userKey}/unreadCount`);

              // Get the current unread count
              const unreadCountSnapshot = await get(unreadRef);
              const currentUnreadCount = unreadCountSnapshot.exists() ? unreadCountSnapshot.val() : 0;

              // Increment the unread count
              try {
                await set(unreadRef, currentUnreadCount + 1);
                console.log(`Unread count incremented for user with organisationId: ${orgId}`);
              } catch (error) {
                console.error("Error updating unread count for orgId:", error);
              }
            }
          }
        }
      }
    } else {
      console.log("No users found");
    }
  } catch (error) {
    console.error("Error updating unreadCount for users:", error);
  }
};

const getAllApps = async(orgID,api_keys) => {
  let appsDetails = []
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? api_keys.productionAPIKey : localStorage.getItem('environment') == 'Staging' ? api_keys.stagingAPIKey : api_keys.devAPIKey)}}
    let getAllAppsByOrg = {
        organisationId: orgID
    }
    // let appid
    let res = await axios.post(BASE_URL + GET_APPS, getAllAppsByOrg, headers)
    if(res && res.status == 200 && res?.data?.data?.projectDetails){
        // appid=res.data.data.projectDetails && res.data.data.projectDetails[0].id
        return res.data.data.projectDetails;
    }
}

const CheckMemberOnLogin = async (orgs,memberId,orgId) => {
  try {
    // const realtimeDb = getDatabase(app);
    // Step 2: Call Get_Apps API for each organization ID
    const appPromises = [];
    for (const org of orgs) {
      const apps = await getAllApps(org.id, org.api_keys);
      appPromises.push(...apps.map((e)=>({memberId,organisationId:e.organisationId,appId:e.id})));
      appPromises.push({memberId,organisationId:org.id})
    }

    // Step 3: Create User Entries in Firebase Realtime Database
    // const dbRef = ref(realtimeDb, 'users');

    // Loop through all organizations and apps to create user entries
    for (const e of appPromises) {
      let memberUniqueKey
      if(e?.appId){
        memberUniqueKey=`${e.memberId}_${e.organisationId}_${e.appId}`
        await createUserIfNotExists( memberUniqueKey, {memberId:e.memberId,organisationId:e.organisationId,appId:e.appId,unreadCount:0});
      }
      else{
        memberUniqueKey=`${e.memberId}_${e.organisationId}`
        await createUserIfNotExists( memberUniqueKey, {memberId:e.memberId,organisationId:e.organisationId,unreadCount:0});
      }
    }

    // for (const org of organizations) {
    //   // Entry without app_id (for the organization only)
    //   const userKeyWithoutApp = `${userId}_org_${org.id}`;
    //   await createUserIfNotExists(dbRef, userKeyWithoutApp, {
    //     userId,
    //     organization_id: org.id,
    //     unreadCount: 0,
    //   });

    //   // Entries for each app
    //   for (const app of apps.filter((a) => a.organisationId === org.id)) {
    //     const userKeyWithApp = `${userId}_org_${org.id}_app_${app.id}`;
    //     await createUserIfNotExists(dbRef, userKeyWithApp, {
    //       userId,
    //       organization_id: org.id,
    //       app_id: app.id,
    //       unreadCount: 0,
    //     });
    //   }
    // }

    console.log("User entries created/validated successfully.");

  } catch (error) {
    console.error("Error during login or data creation:", error);
  }
};

// Helper function to create a user entry if it does not already exist
const createUserIfNotExists = async ( userKey, userData) => {
  const userRef = ref(realtimeDb, `users/${userKey}`);
  const snapshot = await get(userRef);

  if (!snapshot.exists()) {
    await set(userRef, userData);
    console.log(`User entry created for key: ${userKey}`);
  } else {
    console.log(`User entry already exists for key: ${userKey}`);
  }
};


export default app;
export { app, db, realtimeDb, increaseUnreadCountForAllUsers, CheckMemberOnLogin};