import react, { useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './games.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import GamesPlaceHolder from '../../../media/images/placeholders/Game.png'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

const Games = ({className, games}) => {

    const {appid} = useParams()

    console.log(games)

    if(games){
        return(
            <>
                <Card className={cn(styles.card, className)} title='Games' classTitle="title-yellow">
                    <div className={styles.gamesContainer}>
                        
                        {games && games.length == 0 &&
                            <div className={styles.games}>
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <Link className={styles.all} to={`/games/${appid}`}>Games</Link> yet!</span></div>
                                </div>
                            </div>
                        }

                        {games && games.length > 0 &&
                            <>
                                <div className={styles.wrapper}>
                                    <div className={styles.table}>
                                        {games && games.slice(0, 6).map((e, index) => (
                                            <>
                                                <div className={styles.tableRow}>
                                                    <div className={styles.tableCol}>
                                                        <img src={(e.iconUrl && e.iconUrl != null && e.iconUrl != '') ? e.iconUrl : GamesPlaceHolder} alt={`${e.name}`} />
                                                    </div>
                                                    <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                                    <div className={styles.tableCol}><div className={styles.createdAt}>Created At</div><div className={styles.date}>{(e.createdAt && e.createdAt != null) ?  moment(e.createdAt).format('DD/MM/YYYY hh:mm A') : '-'}</div></div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                {(games && games.length > 6) &&
                                    <div className={styles.viewAll}>
                                        <Link className={styles.all} to={`/games/${appid}`}>View All</Link>
                                    </div>
                                }
                            </>
                        }

                    </div>
                </Card>
            </>
        )
    }
}

export default Games