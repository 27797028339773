import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './storeFilter.module.sass'
import Icon from "../../../../Icon";
import axios from "axios";
import { BASE_URL } from "../../../../../resources/APIEndpoints";
import Form from "../../../../Form";
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaDataAction } from "../../../../../redux/action/common/commonAction";
import { Range, getTrackBackground } from "react-range";
import Checkbox from "../../../../Checkbox";
import Radio from "../../../../Radio";
import { getAllStoresAction } from "../../../../../redux/action/builds/economy/stores/storeAction";
import { useParams } from "react-router-dom";

const StoreFilterModal = ({className, filters, action, onClose}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()

    let filterList = [{id: 1, name : 'Store Platform'}, {id: 2, name : 'Store Location'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)
    
    const [genreOption, setGenreOption] = useState([])
    const [platformOption, setPlatformOption] = useState([])
    const [locationOption, setLocationOption] = useState([])

    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({platformId : e.id, name : (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-'), platform: (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-')})
        })
        setPlatformOption(Platforms)

        let genre = []
        res.data.data.gameGenerMaster && res.data.data.gameGenerMaster.forEach((e,i) => {
            genre.push({id : e.id, name : (e.genreName[0].toUpperCase() + e.genreName.slice(1)), genre:e.genreName})
        })
        setGenreOption(genre)
    }

    useEffect(() => {
        getOptionalData()
    }, [])

    const onGameGenre = (e, name) => {
        if(e.target.checked){
            filters.setGenre([...filters.genre, name])
        }else{
            filters.setGenre(filters.genre.filter(item => item !== name))
        }
    }

    const onGamePlatform = (e, name) => {
        if(e.target.checked){
            filters.setPlatform([...filters.platform, name])
        }else{
            filters.setPlatform(filters.platform.filter(item => item !== name))
        }
    }

    const onGameLocation = (e, name) => {
        if(e.target.checked){
            filters.setLocation([...filters.location, name])
        }else{
            filters.setLocation(filters.location.filter(item => item !== name))
        }
    }

    const saveFilter = () => {
        action()
        onClose()
    }

    const resetFilter = () => {
        filters.setPlatform([])
        filters.setLocation([])
        let stores = {
            projectId : appid,
            offset: 0,
            limit: 8,
        }
        dispatch(getAllStoresAction(stores))
        onClose()
    }

    const [filterPlatform, setFilterPlatform] = useState('')
    const [filterLocation, setFilterLocation] = useState('')

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index===activeIndex})} key={index} onClick={() => {setActiveIndex(index)}}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Store Platform' && filters.platform.length > 0 && <span className={styles.countPill}>{filters.platform.length}</span>}
                                                    {e.name == 'Store Location' && filters.location.length > 0 && <span className={styles.countPill}>{filters.location.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='16'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Platform" type="text" name="search" icon="search" onChange={(e) => {setFilterPlatform(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                            {platformOption && platformOption.filter(option => option.name && option.name.toLowerCase().includes(filterPlatform.toLowerCase())).map((x, i) => {
                                                return(
                                                    <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.platform.includes(x.platform)} onChange={(e)=>{onGamePlatform(e, x.platform)}} />
                                                )
                                            })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 1 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Location" type="text" name="search" icon="search" onChange={(e) => {setFilterLocation(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                            {locationOption && locationOption.filter(option => option.name && option.name.toLowerCase().includes(filterLocation.toLowerCase())).map((x, i) => {
                                                return(
                                                    <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.location.includes(x.id)} onChange={(e)=>{onGameLocation(e, x.id)}} />
                                                )
                                            })}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn(styles.button, "button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default StoreFilterModal