import React, { useEffect, useState } from 'react'
import styles from './billing.module.sass'
import TextInput from '../../../../components/TextInput'
import {useDispatch, useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import Dropdown from '../../../../components/Dropdown'
import SearchDropdown from '../../../../components/Dropdown/SearchDropdown'
import { getAllStatesForSignUpAction, getCitiesAction, getRegionsAction } from '../../../../redux/action/common/commonAction';

const BillingDetails = ({onSave, user, setUser, props}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [location, setLocation] = useState(user.location)
    const [locationId, setLocationId] = useState(null)
    const [locationOptions, setLocationOptions] = useState([])

    const [region, setRegion] = useState(user.billingAddress.state)
    const [regionId, setRegionId] = useState(null)
    const [regionOptions, setRegionOptions] = useState([])

    const [city, setCity] = useState(user.billingAddress.city)

    const onChangeUser = (e) => {
        const {name, value} = e.target
        setUser((user) => ({...user, billingAddress: {...user.billingAddress, [name]: value}}))
    }

    const configureCountry = () => {
        if(locationOption && locationOption?.length > 0){
            setLocationOptions(locationOption)
        }
    }

    const configureStates = () => {
        if(regionOption && regionOption?.length > 0){
            setRegionOptions(regionOption)
        }
    }

    const getRegions = () => {
        let country = {
            country : location,
            limit : 50,
            offset : 0
        }
        dispatch(getAllStatesForSignUpAction(country))
    }

    const skip = () => {
        onSave(prevState => prevState + 1)
    }

    const back = () => {
        onSave(prevState => prevState - 1)
    }

    const next = () => {
        setUser((user) => ({...user, location: location, billingAddress: {...user.billingAddress, country : location, state : region}}))
        onSave(prevState => prevState + 1)   
    }

    let locationOption = useSelector((state) => {
        return state.common.countries
    })

    let regionOption = useSelector((state) => {
        return state.common.states
    })

    useEffect(() => {
        if(location !== null && location !== ''){
            getRegions()
        }
    }, [location])

    useEffect(() => {
        configureCountry()
    }, [locationOption])

    useEffect(() => {
        configureStates()
    }, [regionOption])

    return (
        <>
            <ToastContainer/>
            <div className={styles.personalInfo}>
                <div className={styles.formContainer}>
                    <div className={styles.form}>
                        <div className={styles.formHeader}>
                            <div className={styles.formTitle}>Add Your Billing <br /> Details.<span
                                className={styles.blueText}>!</span></div>
                            <div className={styles.formContent}>Set up your organization's billing address and GST Number for billing.</div>
                        </div>
                        <div className={styles.formBody}>

                            <div className={styles.group}>
                                <div className={styles.fields}>
                                    <SearchDropdown disabled={true} placeholder='Country' options={locationOptions} value={location} setValue={setLocation} setId={setLocationId}/>
                                </div>
                                <TextInput position="bottom" className={styles.fields} type='text' name='zip' placeHolder='Zipcode' value={user.billingAddress.zip} onChange={(e) => {onChangeUser(e)}} />
                            </div>

                            <div className={styles.single}>
                                <TextInput position="bottom" className={styles.fields} type='text' name='streetLineOne' placeHolder='Address 1' value={user.billingAddress.streetLineOne} onChange={(e) => {onChangeUser(e)}} />
                            </div>

                            <div className={styles.single}>
                                <TextInput position="bottom" className={styles.fields} type='text' name='streetLineTwo' placeHolder='Address 2' value={user.billingAddress.streetLineTwo} onChange={(e) => {onChangeUser(e)}}/>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.fields}>
                                    <SearchDropdown  placeholder='State' options={regionOptions} value={region} setValue={setRegion} setId={setRegionId}/>
                                </div> 
                                <TextInput position="bottom" className={styles.fields} type='text' name='city' placeHolder='City' value={user.billingAddress.city} onChange={(e) => {onChangeUser(e)}}/> 
                            </div>
                            {((user.isGST && user.location === 'India') || (!user.solo && user.location !== 'India')) &&
                                <div className={styles.single}>
                                    <TextInput position="bottom" className={styles.fields} type='text' name='gstNo' placeHolder={location === 'India' ? 'GST No' : 'VAT No'} value={user.billingAddress.gstNo} onChange={(e) => {onChangeUser(e)}}/>
                                </div>
                            }
                            
                        </div>
                        <div className={styles.formFooter}>
                            <div className={styles.skip} onClick={skip}>Skip</div>
                            <div className={styles.action}>
                                <button className={styles.Back} onClick={back}>Back</button>
                                <button className={styles.Next} onClick={next}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingDetails