import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../components/Modals/ModalComponent";
import AddMemberModal from "../../../../../components/AppSettingComponent/MemberComponent/AddMembersModal";
import addIcon from '../../../../../media/images/icons/light.png'

const Control = ({className, memberAction, action, usedFor, type, width, members}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div className={styles.createButton} onClick={x.action}>
              <Link className={type == 'small' ? cn("button-tiny", styles.button) : cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} action={x.action} alt='gamehub_add_icon'width={20} height={20}/>Add Members</Link>
            </div>
        ))}
      </div>
      <Modal width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddMemberModal memberAction={memberAction} action={action} usedFor={usedFor} members={members} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
