import React from "react";
import cn from "classnames";
import styles from "./Switch.module.sass";

const Switch = ({ className, value, onChange, size }) => {
  return (
    <label className={cn(styles.switch, className, {[styles.small] : (size === 'small')})}>
      <input
        className={styles.input}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <span className={styles.inner}>
        <span className={styles.box}>
          <span className={cn(styles.text, {[styles.left] : !value}, {[styles.right] : value})}>{value ? 'Yes' : 'No'}</span>
        </span>
      </span>
    </label>
  );
};

export default Switch;
