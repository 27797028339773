import React, { useEffect } from "react";
import styles from './billing.module.sass'
import Plans from "../Plans";
import BillingHistory from "../BillingHistory";
import BillingCycle from "../BillingCycle";
import Payment from "../Payments";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loaders/LoaderProgress";
import { getAllPaymentAction, getInvoiceAction, getMyPlanAction } from "../../../../redux/action/organisation/Billing/billingAction";
import OrganizationHeader from '../../../../components/Header/OrganizationHeader'
import PaymentList from "./PaymentList";
import BillingDetails from "../BillingDetails";
import Address from "../../../Organization/Settings/Profile/Organisation/Address";
import { getAllMetaDataAction } from "../../../../redux/action/common/commonAction";
import NotificationSettings from "./NotificationSettings";

const Pricing = () => {

    const dispatch = useDispatch()

    const getAllPayments = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== null && customerId !== undefined){
            let customer = {
                customerId: JSON.parse(customerId)
            }
            dispatch(getAllPaymentAction(customer))
        }
    }


    let {myPlan, payment, isLoadingPayments} = useSelector((state) => state.billing)

    useEffect(() => {
        getAllPayments()
        getAllLocation()
    }, [])

    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }

    if(isLoadingPayments){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <OrganizationHeader title='Billings' />
            <div className={styles.wrapper}>
                <PaymentList options={payment} plan={myPlan}/>
                <Address locationOption={locationOption} data={OrgProfile}/>
                <NotificationSettings />
            </div>
        </>
    )

}

export default Pricing