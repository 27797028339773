import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './stopModal.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import { getAllMetaDataAction } from '../../../redux/action/common/commonAction'
import { stopLiveOpsAction, stopTaskAction, stopTaskGroupAction } from '../../../redux/action/engage/LiveOps/LiveOpsAction'
import app,{increaseUnreadCountForAllUsers} from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const StopScheduleModal = ({className, name, type, as, id, scheduleId, instanceId, liveOps, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const db = getFirestore(app);

  const Stop = async () => {
    try {
        let stopLiveOps
        if(type === 'Leaderboard' || type === 'Instant Battle' || type === 'Tournament'){
          stopLiveOps = {
            instanceId : instanceId,
            leaderboardId : id
          }
          await dispatch(stopLiveOpsAction(stopLiveOps,type, as, appid, onClose, navigate,id))
        }else if(type === 'Task'){
          stopLiveOps = {
            taskId : id
          }
          await dispatch(stopTaskAction(stopLiveOps, onClose, type, appid))
        }else if(type === 'StepSeries' || type === 'Mission'){
          stopLiveOps = {
            taskGroupId : id
          }
          await dispatch(stopTaskGroupAction(stopLiveOps, onClose, type, appid))
        }
        const user = JSON.parse(localStorage.getItem('user'))
        const memberID = user?.id
        const memberName = user?.name
        const orgId = (localStorage.getItem('orgId'))
        // Log activity in Firestore
        const activityLog = {
          timestamp: new Date().toISOString(),
          action_type: "UPDATE",
          app_id:appid,
          resource: (type=="StepSeries"?"Step Series":type).toUpperCase(),
          resource_id: id, // Use the ID from response
          description: `${type=="StepSeries"?"Step Series":type} '${name}' stopped successfully`,
          quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
          operation_status: "SUCCESS",
          activity_feed: true,
          priority: "LOW",
          performed_by_id: memberID,
          organization_id: orgId,
          performed_by:memberName,
          request_json:JSON.stringify(stopLiveOps),
        };
        await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
    }catch (error) {
      console.error("Error whie logging activity:", error);
    }
  }

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeading}>
                  <span>Stop {type}</span>
                </div>
            </div>

            <div className={styles.modalBody}>
              {type === 'Leaderboard' &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the leaderboard <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and no new scores will be accepted. This termination applies to all instances of the current and recurring leaderboards.</span>
                </>
              }
              {(type === 'Tournament' || type === 'Instant Battle') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the competition <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and no new scores will be accepted. This termination applies to all instances of the current and recurring competitions.</span>
                </>
              }
              {(type === 'Task') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the task <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the task will no longer be available for completion. This termination applies to all instances of the current and recurring tasks.</span>
                </>
              }
              {(type === 'Mission') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the mission <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the mission will no longer be available for completion. This termination applies to all instances of the current and recurring missions.</span>
                </>
              }
              {(type === 'StepSeries') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the step series <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the step series will no longer be available for completion. This termination applies to all instances of the current and recurring step series.</span>
                </>
              }
            </div>

            <div className={styles.modalFooter}>
              <div className={styles.fields}>
                <button className={cn(styles.button, 'button-danger-small-full')} onClick={Stop}>Confirm</button>
              </div>
              <div className={styles.fields}>
                <button className={cn(styles.button, 'button-cancel-small-full')} onClick={onClose}>Cancel</button>
              </div>
            </div>

          </div>
        </div>
  )
}

export default StopScheduleModal