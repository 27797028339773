import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';
import 'ace-builds/src-noconflict/theme-ambiance';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-dawn';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import ace from 'ace-builds/src-noconflict/ace';
import styles from './aceJsonEditor.module.sass';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../../Dropdown/CommonDropdown'
import {ReactComponent as ZoomIn} from "../../../../../media/images/icons/zoomIn.svg"
import {ReactComponent as ZoomOut} from "../../../../../media/images/icons/zoomOut.svg"
import cn from 'classnames';

const JsonEditor = ({ className, index, data, value, setValue, type, onClose }) => {
    const { appid } = useParams();
    const { id } = useParams();
    const dispatch = useDispatch();

    const [jsonValue, setJsonValue] = useState('');
    const [annotations, setAnnotations] = useState([]);
    const [isFullscreen, setFullscreen] = useState(false);
    // const [markers, setMarkers] = useState([]);

    const aceThemes=[
        "cobalt",
        "ambiance",
        "twilight",
        "monokai",
        "kuroir",
        "xcode",
        "dawn"
    ]

    const [currentTheme,setCurrentTheme]=useState(aceThemes[0])

    const configurePlayerData = () => {
        if (data === null) {
            setJsonValue(''); // If data is null, set it to an empty string
        } else if (typeof data === 'string') {
            setJsonValue(data); // If data is already a string, use it directly
        } else {
            setJsonValue(JSON.stringify(data, null, 2)); // Otherwise, stringify the data
        }
    };

    useEffect(() => {
        configurePlayerData();
    }, [data]);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleChange = (newValue) => {
        setJsonValue(newValue);

        // Validate JSON and set annotations if there are errors
        try {
            JSON.parse(newValue);
            setAnnotations([]); // Clear annotations if JSON is valid
            // setMarkers([]); // Clear markers if JSON is valid
        } catch (error) {
            const errorMessage = error.message;

            // Extract line number from error message
            const lineMatch = errorMessage.match(/at position \d+ \(line (\d+) column (\d+)\)/);
            const lineNumber = lineMatch ? parseInt(lineMatch[1], 10) - 1 : 0;

            // Extract column number from error message (if possible)
            // You might need to adjust this based on your specific error message format
            const columnNumber = lineMatch ? parseInt(lineMatch[2], 10) - 1 : 0;

            setAnnotations([{
                row: lineNumber,
                column: columnNumber,
                text: errorMessage,
                type: 'error'
            }]);

        }
    };

    const save = () => {
        let playerDataList = [...value];
        try {
            playerDataList[index].value = JSON.parse(jsonValue || '{}'); // Parse jsonValue or default to an empty object
        } catch (error) {
            console.error('Invalid JSON:', error);
            return;
        }
        setValue(playerDataList);
        onClose();
    };

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div style={{margin:"auto 0"}} className={styles.block}></div><span style={{margin:"auto 0"}}>Json Editor</span>
                            <div style={{width:"256px",margin:"0 64px 0 auto"}}>
                                <Dropdown value={capitalizeFirstLetter(currentTheme)} setValue={setCurrentTheme} options={
                                    aceThemes.map((e)=>{return {name:capitalizeFirstLetter(e)}})
                                }/>
                            </div>
                        </div>
                        
                        <div className={styles.modalBody} >
                            <div className={styles.contentBody}>
                                <div className={styles.payout}>
                                    <div className={styles.group} >
                                        <div className={styles.editor}>
                                            <AceEditor
                                                mode="json"
                                                theme={currentTheme.toLocaleLowerCase()}
                                                name="jsonEditor"
                                                fontSize={12}
                                                width="640px"
                                                height="500px"
                                                value={jsonValue}
                                                onChange={handleChange}
                                                annotations={annotations}
                                                style={isFullscreen?{position:"fixed",top:"0",left:"0",zIndex:"5",height:"100vh",width:"100vw"}:{borderRadius:"8px",margin:"auto"}}
                                                // markers={markers}
                                                setOptions={{
                                                    enableBasicAutocompletion: true,
                                                    enableLiveAutocompletion: true,
                                                    enableSnippets: true,
                                                    useWorker: false,
                                                    // showGutter: true 
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.modalFooter} style={{padding:"0px"}}>
                            <div className={styles.btnGroup}>
                                <button className={cn(styles.button, 'button-save-small')} onClick={save}>Confirm</button>
                                <button className={cn(styles.button, 'button-cancel-small')} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JsonEditor;
