import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './createPlayer.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createCustomSignupAction, editPlayerProfileAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
 
const AddPlayer = ({className, type, onClose, action}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const Navigate = useNavigate()

    const [customId, setCustomId] = useState('')

    const onChangeCustomId= (e) => {
        setCustomId(e.target.value)
    }
    const db = getFirestore(app);
    const playerProfile = useSelector((state) => {
        return state.players.profile
    })
    const linkAccount = async () => {
        if(action == 'Create Player'){
            let accountDetails = {
                projectId : appid,
                customId : customId
            }
            try {
                // Await the response from createGameAction
                await dispatch(createCustomSignupAction(accountDetails, Navigate, appid))
                // Access the response data (e.g., game ID)
                    // const IdFromResponse = res?.data?.data?.id;

                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "PLAYER",
                        // resource_id: IdFromResponse, // Use the ID from response
                        description: `Player '${customId&&customId}' account added successfully`,
                        quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName,
                        request_json:JSON.stringify(accountDetails),
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                    console.log("Activity logged successfully");
                
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }else{
            let userDetail = {
                projectId : appid,
                userId : id,
                customId : customId
            }
            try {
                await dispatch(editPlayerProfileAction(userDetail, appid, id, null, 0))
                // Access the response data (e.g., game ID) 
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    app_id:appid,
                    resource: "PLAYER",
                    resource_id: id, // Use the ID from response
                    description: `Player '${customId}' updated successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(userDetail),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            
            } catch (error) {
                
            }
        }
        onClose()
    }

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Link Account</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.contentBody}>
                                <TextInput isId={true} value={customId} label='Custom ID' type='text' onChange={(e)=>{onChangeCustomId(e)}} />
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={cn(styles.button, 'button-save-small')} onClick={linkAccount}>Save</button>
                                <button className={cn(styles.button, 'button-cancel-small')} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPlayer