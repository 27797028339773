import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from './quickAction.module.sass'
import Icon from "../../Icon";
import { ReactComponent as  Apps} from "../../../media/images/icons/apps.svg";
import { ReactComponent as  Settings} from "../../../media/images/icons/settings.svg";
import { ReactComponent as  AccessManager} from "../../../media/images/icons/access_manager.svg";
import { ReactComponent as  Sandbox} from "../../../media/images/icons/sandbox.svg";
import OutsideClickHandler from "react-outside-click-handler";


const QuickAction = ({className}) => {

    const {pathname} = useLocation()
    const [show, setShow] = useState(false)

    const quickMenu = [
        {
            title: "Apps",
            icon : <Apps />,
            active : false,
            url: "/apps"
        },
        {
            title: "Settings",
            icon : <Settings />,
            active : false,
            url: "/settings-organization-profile"
        },
        {
            title: "People",
            icon : <AccessManager />,
            active : false,
            url: "/access-manager-accepted"
        },
        {
            title: 'Billing',
            icon : <Icon name='payment' size='16' className={styles.payment} fill='#6F767E' />,
            active: false,
            url: '/billing-overview'
        },
        {
            title: 'Sandbox',
            icon : <Sandbox />,
            active: false,
            url: (window.location.origin.includes('console') ? `https://sandbox.specterapp.xyz/` : window.location.origin.includes('staging') ? `https://sandboxdev.netlify.app/` : `https://sandboxdev.netlify.app/`)
        },
        {
            title: 'Usage',
            icon : <Icon name='pie-chart' size='16' className={styles.payment} fill='#6F767E' />,
            active: false,
            url: '/usage-analytics'
        },
        // {
        //     title: 'Notifications',
        //     icon : <Icon name="notification" size='16' className={styles.payment} fill='#6F767E' />,
        //     active: false,
        //     url: null
        // }
    ]

    return(
        <>
            <OutsideClickHandler onOutsideClick={() => setShow(false)}>
                <div className={styles.containerQuickAction}>
                    <div className={styles.quickAction}>
                        <div className={cn(styles.action, {[styles.active] : show})} onClick={() => setShow(!show)}>
                            <div className={styles.bentoMenu}>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.menu} >
                                    {quickMenu && quickMenu.map((item, index) => (
                                        <NavLink target={item.title === 'Sandbox' ? '_blank' : null} to={item.url} className={styles.options} key={index}>
                                            <div className={styles.icon}>
                                                {item.icon}
                                            </div>
                                            <div className={styles.title}>
                                                {item.title}
                                            </div>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default QuickAction