import React, {useState, useEffect,useRef, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './usageAnalytics.module.sass'
import { ToastContainer } from 'react-toastify';
import axios from "axios";
import { BASE_URL, GET_API_ANALYTICS, GET_API_HISTORY, GET_USAGE_OVERVIEW, GET_APPS, GET_EVENT_ANALYTICS, GET_EVENT_HISTORY} from "../../../resources/APIEndpoints";
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import Dropdown from '../../../components/Dropdown'
import { ReactComponent as  Info} from "../../../media/images/icons/info.svg";
import OrganizationHeader from '../../../components/Header/OrganizationHeader'
// import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut, Line, Pie, Bar, Radar, PolarArea, Bubble, Scatter} from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler, RadialLinearScale, ArcElement } from 'chart.js';
import { style } from '@mui/system'
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  RadialLinearScale,
  ArcElement,
  // ChartDataLabels
);

const CustomLegend = ({ labels, colors, toggleDataset, hiddenSegments }) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
        {labels.map((label, index) => (
          <div
            key={index}
            onClick={() => toggleDataset(index)}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '16px',
              cursor: 'pointer',
              opacity: hiddenSegments.includes(index) ? 0.5 : 1,
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: colors[index],
                borderRadius: '4px',
                margin: '8px',
                border: hiddenSegments.includes(index) ? '2px solid #ccc' : 'none',
              }}
            ></div>
            <span>{label}</span>
          </div>
        ))}
      </div>
    );
  };
  
  const CustomDoughnutChart = (props) => {
    const [hiddenSegments, setHiddenSegments] = useState([]);
    const chartRef = useRef(null);
    const resData =props?.data
    const resLabel = []
    const resValue = []
    for (let key in resData) {
        resLabel.push(key)
        resValue.push(resData[key])
    }
    const isDataEmpty = resValue.every(e => !e || e.count === 0);

    const data = {
      labels: resLabel||[
        'Matches',
        'Economy',
        'Events',
        'Progression',
        'Achievements',
        'Leaderboards',
        'Competitions',
      ],
      datasets: [
        {
          // data: resValue.map((e)=>(Math.floor(e.count+1000000*Math.random()))),
          data: resValue.map((e)=>(Math.floor(e.count))),
          // ||[1485, 1023, 683, 1023, 683, 800, 1485],
          backgroundColor: resValue.map((e)=>(e.colorCode)),
          // ||[
          //   '#4D9BF9',
          //   '#FFA14D',
          //   '#4DE2D1',
          //   '#FFCA48',
          //   '#FFD88D',
          //   '#CABDFF',
          //   '#A4F6A4',
          // ],
          borderWidth: 2,
          borderColor: '#ffffff',
          hoverOffset: 10,
        },
      ],
    };
  
    const options = {
        responsive:true,
        cutout: '50%',
        layout: {
          padding: {
            top: 10, // Optional: external padding for the chart
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        plugins: {
        legend: {
          display: false, // Hide the default legend
        },
        tooltip: {
            backgroundColor: '#272B30',
            bodyFont: {
                size: 16,
            },
            padding: 10,
            cornerRadius: 8,
            callbacks: {
              title: function (context) {
                const dataset = context[0].dataset.data;
                const value = context[0].raw || 0;
                const total = dataset.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(2);
                return `${context[0].label} (${Math.round(percentage)}%)`;  // Show the percentage in the title
              },
              label: function (context) {
                const value = context.raw || 0;
                const dataset = context.dataset.data;
                const total = dataset.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(2);
                return ` ${value.toLocaleString()}`;
              },
                labelColor: function(context) {
                  const color =context?.dataset?.backgroundColor?.[context.dataIndex]
                  return {
                      borderColor: '#fff',
                      backgroundColor: `${color}`,
                      borderWidth: 1,
                      borderRadius: 4,
                  };
              },
            },
        },
        // datalabels: {
        //   color: '#fff',
        //   formatter: (value, context) => {
        //     return value;
        //   },
        //   font: {
        //     weight: 'bold',
        //     size: 14,
        //   },
        //   anchor: 'end',
        //   align: 'start',
        //   offset: 10,
        // },
      },
      
    //   onClick: (e, element) => {
    //     if (element.length > 0) {
    //       const index = element[0].index;
    //       toggleDataset(index);
    //     }
    //   },
    };
  
    const toggleDataset = (index) => {
      const chart = chartRef.current;
      const meta = chart.getDatasetMeta(0);
      meta.data[index].hidden = !meta.data[index].hidden;
      setHiddenSegments((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
      chart.update();
    };
  
    return (
      <>
      <div style={{ margin: '0 auto' }}>
        {isDataEmpty?<div className={styles.noData}>No Data Available</div>
        :
         <div style={{width:"300px",height:"300px",margin:"24px auto 0 auto"}}>
            <Doughnut ref={chartRef} data={data} options={options}/>
        </div>
        }
        <CustomLegend
          labels={data.labels}
          colors={data.datasets[0].backgroundColor}
          toggleDataset={toggleDataset}
          hiddenSegments={hiddenSegments}
        />
        </div>
      </>
    );
  };
  
  const StackedBarChart = () => {
    const [hiddenSegments, setHiddenSegments] = useState([]);
    const chartRef = useRef(null);
  
    const data = {
      labels: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24'],
      datasets: [
        {
          label: 'Matches',
          data: [25, 18, 10, 10, 10, 10, 25, 18, 10],
          backgroundColor: '#4D9BF9',
          borderRadius: 5,
        },
        {
          label: 'Economy',
          data: [5, 35, 15, 15, 15, 15, 5, 35, 15],
          backgroundColor: '#FFA14D',
          borderRadius: 5,
        },
        {
          label: 'Events',
          data: [3, 13, 23, 23, 23, 23, 3, 13, 23],
          backgroundColor: '#4DE2D1',
          borderRadius: 5,
        },
        {
          label: 'Progression',
          data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
          backgroundColor: '#FFCA48',
          borderRadius: 5,
        },
        {
          label: 'Achievements',
          data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
          backgroundColor: '#FFD88D',
          borderRadius: 5,
        },
        {
          label: 'Competitions',
          data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
          backgroundColor: '#A4F6A4',
          borderRadius: 5,
        },
        {
          label: 'Leaderboards',
          data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
          backgroundColor: '#CABDFF',
          borderRadius: 5,
        }
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Hide default legend
        },
        tooltip: {
            backgroundColor: '#272B30',
            bodyFont: {
                size: 16,
            },
            padding: 10,
            cornerRadius: 8,
            callbacks: {
                label: function (context) {
                    const label = context.label || '';
                    const value = context.raw || '';
                    return `  ${label}: ${value}`;
                },
                labelColor: function(context) {
                  const color =context?.dataset?.backgroundColor
                  return {
                      borderColor: '#fff',
                      backgroundColor: `${color}`,
                      borderWidth: 1,
                      borderRadius: 4,
                  };
              },
            },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
          grid: {
            display: true,
            color: '#E5E5E5',
          },
          ticks: {
            callback: (value) => `${value}`,
          }
        },
      },
    };
  
    const toggleDataset = (index) => {
      const chart = chartRef.current;
      const meta = chart.getDatasetMeta(index);
      meta.hidden = !meta.hidden;
  
      setHiddenSegments((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
  
      chart.update();
    };
  
    return (
      <>
        <div style={{ height:"400px", width:"auto" }}>
          <Bar ref={chartRef} data={data} options={options} />
        </div>
        <CustomLegend
          labels={data.datasets.map(ds => ds.label)}
          colors={data.datasets.map(ds => ds.backgroundColor)}
          toggleDataset={toggleDataset}
          hiddenSegments={hiddenSegments}
        />
      </>
    );
  };









  const MultiLineChart = ({dat}) => {
    const [hiddenLines, setHiddenLines] = useState([]);
    const chartRef = useRef(null);

    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    
    const convertDataForChart = (inputData) => {
      const labels = Object.keys(inputData[Object.keys(inputData)[0]]);
      const datasets = Object.keys(inputData).map((key) => {
        const borderColor = generateRandomColor();
        const backgroundColor = borderColor + '33'; // Adding transparency
        return {
          label: key,
          // data: Array.from({ length: labels.length }, () => Math.floor(Math.random() * 1000) + 1),
          data: Object.values(inputData[key]),
          borderColor,
          backgroundColor,
          borderWidth: 2,
          tension: 0.4,
        };
      });
      return { labels, datasets };
    };
    const data = convertDataForChart(dat)
    
  
    // const data = {
    //   labels: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24'],
    //   datasets: [
    //     {
    //       label: 'Matches',
    //       data: [25, 18, 10, 10, 10, 10, 25, 18, 10],
    //       borderColor: '#4D9BF9',
    //       backgroundColor: 'rgba(77, 155, 249, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Economy',
    //       data: [5, 35, 15, 15, 15, 15, 5, 35, 15],
    //       borderColor: '#FFA14D',
    //       backgroundColor: 'rgba(255, 161, 77, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Events',
    //       data: [3, 13, 23, 23, 23, 23, 3, 13, 23],
    //       borderColor: '#4DE2D1',
    //       backgroundColor: 'rgba(77, 226, 209, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Progression',
    //       data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
    //       borderColor: '#FFCA48',
    //       backgroundColor: 'rgba(255, 202, 72, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Achievements',
    //       data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
    //       borderColor: '#FFD88D',
    //       backgroundColor: 'rgba(255, 216, 141, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Competitions',
    //       data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
    //       borderColor: '#A4F6A4',
    //       backgroundColor: 'rgba(164, 246, 164, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     },
    //     {
    //       label: 'Leaderboards',
    //       data: [2, 6, 12, 12, 12, 12, 2, 6, 12],
    //       borderColor: '#CABDFF',
    //       backgroundColor: 'rgba(202, 189, 255, 0.3)',
    //       borderWidth: 2,
    //       tension: 0.4,
    //     }
    //   ],
    // };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Hide default legend
        },
        tooltip: {
          backgroundColor: '#272B30',
          bodyFont: {
              size: 16,
          },
          padding: 10,
          cornerRadius: 8,
          callbacks: {
              label: function (context) {
                const label = context.dataset.label || '';
                const value = context.raw || '';
                return `  ${label}: ${value?.toLocaleString()}`;
              },
              labelColor: function(context) {
                const color =context?.dataset?.borderColor
                return {
                    borderColor: '#fff',
                    backgroundColor: `${color}`,
                    borderWidth: 1,
                    borderRadius: 4,
                };
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
            color: '#E5E5E5',
          },
          ticks: {
            callback: (value) => `${value}`,
          },
        },
      },
    };
  
    const toggleDataset = (index) => {
      const chart = chartRef.current;
      const meta = chart.getDatasetMeta(index);
      meta.hidden = !meta.hidden;
  
      setHiddenLines((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
  
      chart.update();
    };
  
    return (
      <>
        <div style={{ height: '400px', width: 'auto' }}>
          <Line ref={chartRef} data={data} options={options} />
        </div>
        <CustomLegend
          labels={data.datasets.map(ds => ds.label)}
          colors={data.datasets.map(ds => ds.borderColor)}
          toggleDataset={toggleDataset}
          hiddenSegments={hiddenLines}
        />
      </>
    );
  };





const UsageAnalytics = ()=>{

  const intervals = ["Last 7 days", "This month", "All time"];
  const [sorting, setSorting] = useState(intervals[0]);
  const pieDataCharts = {
    labels: ['Matches', 'Economy', 'Events'],
    datasets: [
        {
            data: [1485, 1023, 683],
            backgroundColor: ['#4D9BF9', '#FFA14D', '#4DE2D1'],
            borderWidth: 2,
            hoverOffset: 4,
            borderColor: '#FFF',
        },
    ],
};

const toggleOption = [{id : 1, name : 'Live'}, {id : 2, name : 'Upcoming'}]
const [toggle, setToggle] = useState('Select Category')

const [apps,setApps] = useState([]);
const [apiCategories,setApiCategories] = useState([]);
const [apiHistoryCategories,setApiHistoryCategories] = useState([]);
const [eventCategories,setEventCategories] = useState([]);
const [eventHistoryCategories,setEventHistoryCategories] = useState([]);

const [usageOverviewData, setUsageOverviewData] = useState();

const [apiCallsData, setApiCallsData] = useState();
const [eventCallsData, setEventCallsData] = useState();

const [apiHistory, setApiHistory] = useState();
const [eventHistory, setEventHistory] = useState();

const [selectedAppApiAnalytics,setSelectedAppApiAnalytics]=useState();
const [selectedAppApiAnalyticsName,setSelectedAppApiAnalyticsName]=useState();
const [selectedCategoryApiAnalytics,setSelectedCategoryApiAnalytics]=useState();

const [selectedAppEventAnalytics,setSelectedAppEventAnalytics]=useState();
const [selectedAppEventAnalyticsName,setSelectedAppEventAnalyticsName]=useState();
const [selectedCategoryEventAnalytics,setSelectedCategoryEventAnalytics]=useState();

const [selectedAppApiHistory,setSelectedAppApiHistory]=useState();
const [selectedAppApiHistoryName,setSelectedAppApiHistoryName]=useState();
const [selectedCategoryApiHistory,setSelectedCategoryApiHistory]=useState();

const [selectedAppEventHistory,setSelectedAppEventHistory]=useState();
const [selectedAppEventHistoryName,setSelectedAppEventHistoryName]=useState();
const [selectedCategoryEventHistory,setSelectedCategoryEventHistory]=useState();

const getApiUsage = async() => {

    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getApiAnalytics = {
        projectId : selectedAppApiAnalytics,
        category: selectedCategoryApiAnalytics,
        organisationId: localStorage.getItem('orgId'),
    }
    const res = await axios.post(BASE_URL + GET_API_ANALYTICS, getApiAnalytics, headers)
    res?.data?.data&&setApiCallsData(res?.data?.data)
    const resLabel = []
    for (let key in res?.data?.data) {
      resLabel.push(key)
    }
    setApiCategories(resLabel.map((e)=>({name:e})));
}

const getAllApps = async() => {
  let appsDetails = []
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getAllAppsByOrg = {
        organisationId: localStorage.getItem('orgId')
    }
    // let appid
    let res = await axios.post(BASE_URL + GET_APPS, getAllAppsByOrg, headers)
    if(res && res.status == 200){
        // appid=res.data.data.projectDetails && res.data.data.projectDetails[0].id
        res?.data?.data?.projectDetails&&setApps(res.data.data.projectDetails)
    }
}

function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}

const getUsageOverview = async() => {

    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getApiAnalytics = {
      // projectId : appid,
      organisationId: localStorage.getItem('orgId'),
    }
    const res = await axios.post(BASE_URL + GET_USAGE_OVERVIEW, getApiAnalytics, headers)
    res?.data?.data&&setUsageOverviewData(res?.data?.data)
}

const getEventUsage = async() => {

    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getApiAnalytics = {
        projectId : selectedAppEventAnalytics,
        category: selectedCategoryEventAnalytics,
        organisationId: localStorage.getItem('orgId'),
    }
    const res = await axios.post(BASE_URL + GET_EVENT_ANALYTICS, getApiAnalytics, headers)
    res?.data?.data&&setEventCallsData(res?.data?.data)
    const resLabel = []
    for (let key in res?.data?.data) {
      resLabel.push(key)
    }
    setEventCategories(resLabel.map((e)=>({name:e})));
}

const getApiHistory = async() => {

    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getApiAnalytics = {
        projectId : selectedAppApiHistory,
        category: selectedCategoryApiHistory,
        organisationId: localStorage.getItem('orgId'),
    }
    const res = await axios.post(BASE_URL + GET_API_HISTORY, getApiAnalytics, headers)
    res?.data?.data&&setApiHistory(res?.data?.data)
    const resLabel = []
    for (let key in res?.data?.data) {
      resLabel.push(key)
    }
    setApiHistoryCategories(resLabel.map((e)=>({name:e})));
}

const getEventHistory = async() => {

    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getApiAnalytics = {
        projectId : selectedAppEventHistory,
        category: selectedCategoryEventHistory,
        organisationId: localStorage.getItem('orgId'),
    }
    const res = await axios.post(BASE_URL + GET_EVENT_HISTORY, getApiAnalytics, headers)
    res?.data?.data&&setEventHistory(res?.data?.data)
    const resLabel = []
    for (let key in res?.data?.data) {
      resLabel.push(key)
    }
    setEventHistoryCategories(resLabel.map((e)=>({name:e})))
}

useEffect(()=>{
    getAllApps()
    getUsageOverview()
    getApiUsage()
    getEventUsage()
    getApiHistory()
    getEventHistory()

}, [
  selectedAppApiAnalytics,
  selectedCategoryApiAnalytics,
  selectedAppEventAnalytics,
  selectedCategoryEventAnalytics,
  selectedAppApiHistory,
  selectedCategoryApiHistory,
  selectedAppEventHistory,
  selectedCategoryEventHistory
])
  

    return(
        <>
        <ToastContainer />
        <OrganizationHeader title='Usage' />
        <div className={styles.wrapper}>
        <Card className={styles.card} classCardHead={styles.head} title="Cost" classTitle={cn("title-blue", styles.title)} 
        // head={<div className={styles.toggle}><Dropdown type='competition' value={toggle} setValue={setToggle} options={toggleOption} /><Dropdown type='competition' value={toggle} setValue={setToggle} options={toggleOption} /></div>}
        >
            <div className={styles.details}>
                <div className={styles.apis}>
                    <div className={styles.data}>
                        <p className={styles.topText}>API Used<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{((usageOverviewData?.apiUsed?.availableNo&&usageOverviewData?.apiUsed?.limit)||usageOverviewData?.apiUsed?.availableNo==0)?(parseInt(usageOverviewData?.apiUsed?.limit-usageOverviewData?.apiUsed?.availableNo)?.toLocaleString()+" of "+formatNumber(parseInt(usageOverviewData?.apiUsed?.limit))):("NA")}</p>
                        {/* <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#83BF6E"}}><Icon fill="#83BF6E" name={"arrow-top"} size={16}/>37.8%</span> this Month</span>
                        </div> */}
                    </div>
                </div>
                <div className={styles.currentCost}>
                <div className={styles.data}>
                        <p className={styles.topText}>Current Cost<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{(usageOverviewData?.currencyCode&&usageOverviewData?.currentCost)?(usageOverviewData?.currencyCode +" "+ parseInt(usageOverviewData?.currentCost)?.toLocaleString()):("NA")}</p>
                        {/* <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#83BF6E"}}><Icon fill="#83BF6E" name={"arrow-top"} size={16}/>37.8%</span> this Month</span>
                        </div> */}
                    </div>
                </div>
                <div className={styles.lastCost}>
                <div className={styles.data}>
                        <p className={styles.topText}>Previous Month Cost<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{(usageOverviewData?.currencyCode&&usageOverviewData?.lastMonthCost)?(usageOverviewData?.currencyCode +" "+ usageOverviewData?.lastMonthCost):"NA"}</p>
                        {/* <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#FF6A55"}}><Icon fill="#FF6A55" name={"arrow-bottom"} size={16}/>37.8%</span> this Month</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.apis}>
                    <div className={styles.data}>
                        <p className={styles.topText}>API Data Transfer<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{((usageOverviewData?.dataTransfer?.availableNo&&usageOverviewData?.dataTransfer?.limit)||usageOverviewData?.dataTransfer?.availableNo==0)?(Number(usageOverviewData?.dataTransfer?.availableNo)?.toLocaleString()+" "+usageOverviewData?.dataTransfer?.unit+" of "+formatNumber(Number(usageOverviewData?.dataTransfer?.limit))+" GB"):("NA")}</p>
                        {/* <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#83BF6E"}}><Icon fill="#83BF6E" name={"arrow-top"} size={16}/>37.8%</span> this Month</span>
                        </div> */}
                    </div>
                </div>
                {/* <div className={styles.currentCost}>
                <div className={styles.data}>
                        <p className={styles.topText}>Current Cost<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{(usageOverviewData?.currencyCode&&usageOverviewData?.currentCost)?(usageOverviewData?.currencyCode +" "+ parseInt(usageOverviewData?.currentCost)?.toLocaleString()):("NA")}</p>
                        <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#83BF6E"}}><Icon fill="#83BF6E" name={"arrow-top"} size={16}/>37.8%</span> this Month</span>
                        </div>
                    </div>
                </div>
                <div className={styles.lastCost}>
                <div className={styles.data}>
                        <p className={styles.topText}>Previous Month Cost<Icon style={{verticalAlign:"top",marginLeft:"4px"}} name={"info"} size={16}/></p>
                        <p className={styles.headText}>{(usageOverviewData?.currencyCode&&usageOverviewData?.lastMonthCost)?(usageOverviewData?.currencyCode +" "+ usageOverviewData?.lastMonthCost):"NA"}</p>
                        <div className={styles.tab}>
                        <span className={styles.tabText} style={{color: "#6F767E"}}><span style={{color:"#FF6A55"}}><Icon fill="#FF6A55" name={"arrow-bottom"} size={16}/>37.8%</span> this Month</span>
                        </div>
                    </div>
                </div> */}
            </div>
        </Card>

        <div className={styles.usage}>
            <div className={styles.apiUsage}>
                <Card className={styles.card} classCardHead={styles.head} title="API Usage" classTitle={cn("title-red", styles.title)} 
                // head={<div className={styles.toggle}><Dropdown placeholder={"Select App"} type='competition' value={selectedAppApiAnalyticsName} setValue={setSelectedAppApiAnalyticsName} id={selectedAppApiAnalytics} setId={setSelectedAppApiAnalytics} options={apps?.map((e)=>({id:e?.id,name:e?.name}))} /><Dropdown placeholder={"Select Category"} type='competition' value={selectedCategoryApiAnalytics} setValue={setSelectedCategoryApiAnalytics} options={apiCategories} /></div>}
                >
                  <CustomDoughnutChart data={apiCallsData} type="api"/>
                </Card>
            </div>
            <div className={styles.eventUsage} >
                <Card className={styles.card} classCardHead={styles.head} title="Event Usage" classTitle={cn("title-yellow", styles.title)} 
                // head={<div className={styles.toggle}><Dropdown placeholder={"Select App"} type='competition' value={selectedAppEventAnalyticsName} setValue={setSelectedAppEventAnalyticsName} id={selectedAppEventAnalytics} setId={setSelectedAppEventAnalytics} options={apps?.map((e)=>({id:e?.id,name:e?.name}))} /><Dropdown placeholder={"Select Category"} type='competition' value={selectedCategoryEventAnalytics} setValue={setSelectedCategoryEventAnalytics} options={eventCategories} /></div>}
                >
                  <CustomDoughnutChart data={eventCallsData} type="event"/>
                </Card>
            </div>
        </div>
        <Card className={styles.card} classCardHead={styles.head} title="History" classTitle={cn("title-blue", styles.title)}>
          <div style={{display:"flex"}}>
          <span style={{fontSize:"18px",fontWeight:600,marginLeft:"24px",marginTop:"24px"}}>Api History</span>
          {/* <div className={styles.toggle}><Dropdown placeholder={"Select App"} type='competition' value={selectedAppApiHistoryName} setValue={setSelectedAppApiHistoryName} id={selectedAppApiHistory} setId={setSelectedAppApiHistory} options={apps?.map((e)=>({id:e?.id,name:e?.name}))} /><Dropdown placeholder={"Select Category"} type='competition' value={selectedCategoryApiHistory} setValue={setSelectedCategoryApiHistory} options={apiHistoryCategories} /></div> */}
          </div>
            <div style={{margin:"12px 0 0 0",border:"1px solid #efefef", borderRadius:"8px",padding:"24px"}}>
                {apiHistory&&<MultiLineChart dat={apiHistory} type="api"/>}
            </div>
            <br></br>
            <div style={{display:"flex"}}>
            <span style={{fontSize:"18px",fontWeight:600,marginLeft:"24px"}}>Event History</span>
            {/* <div className={styles.toggle}><Dropdown placeholder={"Select App"} type='competition' value={selectedAppEventHistoryName} setValue={setSelectedAppEventHistoryName} id={selectedAppEventHistory} setId={setSelectedAppEventHistory} options={apps.map((e)=>({id:e?.id,name:e?.name}))} /><Dropdown placeholder={"Select Category"} type='competition' value={selectedCategoryEventHistory} setValue={setSelectedCategoryEventHistory} options={eventHistoryCategories} /></div> */}
            </div>
            <div style={{margin:"12px 0 0 0",border:"1px solid #efefef", borderRadius:"8px",padding:"24px"}}>
                {eventHistory&&<MultiLineChart dat={eventHistory} type="event"/>}
            </div>

        </Card>
        </div>
        </>
    )
}

export default UsageAnalytics

///////////////////////////////
