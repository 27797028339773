import React, {useEffect, useState, useRef} from 'react'
import cn from 'classnames'
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../components/Card'
import Radio from '../../../../../components/Radio';
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import Tooltip from '../../../../../components/Tooltip';
import { BASE_URL, GET_APPS } from '../../../../../resources/APIEndpoints';
import Icon from '../../../../../components/Icon';
import styles from './match.module.sass'
import Match from '../../../../../media/images/placeholders/Mission.png'
import FileImages from '../../../../../components/File/Images'
import FileVideos from '../../../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import Dropdown from '../../../../../components/Dropdown';
import GameDropdown from '../../../../../components/Dropdown/CreatableDropdown';
import { getListOfAllGamesAction } from '../../../../../redux/action/builds/games/gamesAction';
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { createMatchAction } from '../../../../../redux/action/builds/matches/matchesAction';
import moment from 'moment';
import Toast from '../../../../../components/Toast';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput';
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";


const MatchInformation = ({ className,isChanged, setIsChanged, onClose}) => {

    const { appid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const placeholder = Match
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [matchIcon, setMatchIcon] = useState(null)
    const [matchId, setMatchId] = useState("")
    const [matchName, setMatchName] = useState("");
    const [matchDescription, setMatchDescription] = useState()
    const [tags, setTags] = useState([]);

    // Match Information
    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setMatchIcon(res.data.getUrl)
        var config = {
          headers : {'Content-Type' : `${file.type}`},
          method: 'put',
          url: res.data.postUrl,
          data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setMatchIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    
    const onMatchName = (e) => {
      if(e.target.value == ''){
        setMatchName('')
      }else{
        errors.nameError = ''
        setMatchName(e.target.value)
      }
    }

    const onMatchId = (e) => {
      if(e.target.value == ''){
        setMatchId('')
      }else{
        errors.idError = ''
        setMatchId(e.target.value)
      }
    }

    const onMatchDescription = (e) => {
        setMatchDescription(e.target.value)        
    }


    // Match and Leaderboard Detail
    const [minPlayer, setMinPlayer] = useState(null)
    const [maxPlayer, setMaxPlayer] = useState(null)
    const [leaderboardName, setLeaderboardName] = useState('')
    const [leaderboardId, setLeaderboardId] = useState('')
    const [leaderboardDescription, setLeaderbordDescription] = useState('')
    const [leaderboardMethod, setLeaderboardMethod] = useState('')
    const [leaderboardRequired, setLeaderboardRequired] = useState(false)
    const [frequency, setFrequency] = useState(true)

    const onMinPlayer = (e) => {
      setMinPlayer(parseInt(e.target.value))
    }

    const onMaxPlayer = (e) => {
      setMaxPlayer(parseInt(e.target.value))
    }

    const onLeaderboardName = (e) => {
      if(e.target.value == ''){
        setLeaderboardName('')
      }else{
        errors.leaderboardNameErr = ''
        setLeaderboardName(e.target.value)
      }
    }

    const onLeaderboardId = (e) => {
      if(e.target.value == ''){
        setLeaderboardId('')
      }else{
        errors.leaderboardIdErr = ''
        setLeaderboardId(e.target.value)
      }
    
    }

    const onLeaderboardDescription = (e) => {
      setLeaderbordDescription(e.target.value)
    }

    const [games, setGames] = useState()
    const [gameName, setGameName] = useState()
    const [gameId, setGameId] = useState('')
    const [position, setPosition] = useState(null)
    const [matchFormatOption, setMatchFormatOption] = useState()
    const [matchFormat, setMatchFormat] = useState('')
    const [matchFormatId, setMatchFormatId] = useState(null)
    const [matchOutcomeOption, setMatchOutcomeOption] = useState()
    const [matchOutcome, setMatchOutcome] = useState('')
    const [matchOutcomeId, setmatchOutcomeId] = useState(null)
    const [leaderboardSourceOption, setLeaderboardSourceOption] = useState()
    const [leaderboardSource, setLeaderboardSource] = useState('Match')
    const [leaderboardSourceId, setLeaderboardSourceId] = useState(1)
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    const [leaderboardOutcome, setLeaderboardOutcome] = useState('')
    const [leaderboardOutcomeId, setLeaderboardOutcomeId] = useState(null)
    const [recurringOptions, setRecurringOption] = useState()
    const [recurring, setRecurring] = useState('')
    const [recurringId, setRecurringId] = useState()


    
    const onPosition = (e) => {
      setPosition(parseInt(e.target.value))
    }

    const getMetaData = () => {
      dispatch(getAllMetaDataAction())
    }

    let isMetaDataLoading = useSelector((state) => {
      return state.common.isLoading
    })

    let allMetaData = useSelector((state) => {
      return state.common.metaData
    })

    useEffect(()=>{ 
      configureMetaData()
    }, [allMetaData])

    const configureMetaData = () => {
      setMatchFormatOption(allMetaData.matchFormatIds && allMetaData.matchFormatIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.matchFormatName == 'single_player' ? 'Single Player' : obj.matchFormatName == 'multi_player' ? 'Multiplayer' : obj.matchFormatName == 'multi_player_team' ? 'Multiplayer Team' : ''),
        };
      }))
      setMatchWinningConditionOptions(allMetaData.matchWinningConditionIds && allMetaData.matchWinningConditionIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.name == 'higher' ? 'Higher Wins' : obj.name == 'lower' ? 'Lower Wins' : ''),
        };
      }))
      setMatchOutcomeOption(allMetaData.matchOutcomeIds && allMetaData.matchOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.matchOutcomeName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      setLeaderboardSourceOption(allMetaData.leaderboardSourceTypeMasterIds && allMetaData.leaderboardSourceTypeMasterIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.name == 'match' ? 'Match' : obj.name == 'statistics' ? 'Statistics' : ''),
        };
      }))
      setLeaderboardOutcomeOption(allMetaData.leaderboardOutcomeIds && allMetaData.leaderboardOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.filter(obj => obj.intervalName !== 'monthly' && obj.intervalName !== 'yearly' && obj.intervalName !== 'all_time' && obj.intervalName !== 'custom').map((obj) => {
        return {
          id: obj.id,
          name: (obj.intervalName.charAt(0).toUpperCase() + obj.intervalName.slice(1))?.replace(/_/g, ' '),
        };
      }))
    }

    let getAllGames = async() => {
      let games = {
        projectId : appid
      }
      await dispatch(getListOfAllGamesAction(games))
    }

    useEffect(()=>{
      if(!isGameListFetched){
        getAllGames()
      }
      if(!isMetaDataLoading){
        getMetaData()
      }
    }, [])

    let allGames = useSelector((state) => {
      return state.games.games
    })

    let isGameListFetched = useSelector((state) => {
      return state.games.isGameListFetched
    })

    const configureGames = () => {
      setGames(allGames)
    }

    useEffect(()=>{
      configureGames()
    }, [allGames])

    // Custom Data
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', gameErr : '', matchFormatErr : '', winningConditionErr: '', matchResultErr : '', leaderboardErr : '', leaderboardNameErr : '', leaderboardIdErr : '', leaderboardSourceErr : '', leaderboardRankingErr : '', frequencyErr : ''})

    const validation = (data, leadeboardData) => {

        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('matchName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.matchId == '') {
            error.idError = 'Match ID is required';
            isValid = false;
            if(data.name != ''){
              const element = document.getElementById('matchId');
              const offset = 140;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.gameId == '') {
            error.gameErr = 'Game is required';
            isValid = false;
            if(data.name != '' && data.matchId != ''){
              const element = document.getElementById('gameName');
              const offset = 140;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if(data.matchFormatTypeMasterId == null){
            error.matchFormatErr = 'Match Format is required'
            isValid = false;
            if(data.name != '' && data.matchId != '' && data.gameId != ''){
              const element = document.getElementById('matchFormat');
              const offset = 100;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if(data.matchOutcomeTypeMasterId == null){
            error.matchResultErr = 'Match Result Criteria is required'
            isValid = false;
            if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null){
              const element = document.getElementById('matchResult');
              const offset = 100;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if(data.matchFormatTypeMasterId == 2 && data.matchWinningConditionTypeMasterId == null){
          error.winningConditionErr = 'Winning Condition is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null && data.matchOutcomeTypeMasterId != null){
            const element = document.getElementById('winningCondition');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

        if((data.isLeaderboard == true && leadeboardData.name == '')){
            error.leaderboardNameErr = 'Display Name is required'
            isValid = false;
            if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null){
              const element = document.getElementById('leaderboardName');
              const offset = 140;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if((data.isLeaderboard == true && leadeboardData.leaderboardId == '')){
            error.leaderboardIdErr = 'Leaderboard ID is required'
            isValid = false;
            if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchOutcomeTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null && (data.isLeaderboard == true && leadeboardData.name != '')){
              const element = document.getElementById('leaderboardId');
              const offset = 140;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if((data.isLeaderboard == true && leadeboardData.leaderboardOutcomeTypeMasterId == null)){
            error.leaderboardRankingErr = 'Ranking Method is required'
            isValid = false;
            if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchOutcomeTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null && (data.isLeaderboard == true && leadeboardData.name != '') && (data.isLeaderboard == true && leadeboardData.leaderboardId != '')){
              const element = document.getElementById('leaderboardRanking');
              const offset = 100;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
          return true
        }
    }

    const [disable, setDisable] = useState(false)

    const saveMatch = async () => {
      const metaDataList = [...metaData];
      const finalMetaData = {};
      for(var i = 0; i < metaDataList.length ; i++){
        finalMetaData[metaDataList[i].key] = metaDataList[i].value;
      }

      let matchTags = []
      tags && tags.forEach((e)=>{
        matchTags.push(e.text)
      })

      let match = {
        projectId : appid,
        name : matchName,
        matchId : matchId,
        minPlayers : (matchFormatId == 1 ? 1 : minPlayer),
        maxPlayers : (matchFormatId == 1 ? 1 : maxPlayer),
        description : matchDescription,
        logo : matchIcon,
        matchFormatTypeMasterId : matchFormatId,
        matchOutcomeTypeMasterId : matchOutcomeId,
        numberOfPostion : (matchOutcomeId == 4 ? position : null),
        gameId : gameId,
        isLeaderboard : leaderboardRequired,
        meta : finalMetaData,
        tags : matchTags,
        matchWinningConditionTypeMasterId : (matchFormatId == 1 ? 1 : matchWinningConditionId)
      }
      const podiumRanksDetail = []
      podiumRanks && podiumRanks.forEach((e, i) => {
        var key_one = e.ranks
        var key_two = e.score
        podiumRanksDetail.push({[key_one]:key_two})
      })

      // let a = new Date(startDateTime)
      // let leaderboard = {
      //   leaderboardId : leaderboardId,
      //   name : leaderboardName,
      //   description : leaderboardDescription,
      //   leaderboardOutcomeDetails : (leaderboardOutcomeId == 4 ? podiumRanksDetail : []),
      //   isRecurring : (recurringId == 6 ? false : true),
      //   gameId : gameId,
      //   active : true,
      //   archive : false,
      //   projectId : appid,
      //   matchId : null,
      //   leaderboardOutcomeTypeMasterId : leaderboardOutcomeId,
      //   sourceTypeId : leaderboardSourceId,
      //   intervalId : recurringId,
      //   startDate : (recurringId == 6 ? moment.utc(moment(startDateTime)).format("YYYY-MM-DD HH:mm") : null),
      //   endDate : (recurringId == 6 ? moment.utc(moment(endDateTime)).format("YYYY-MM-DD HH:mm") : null)
      // }
      const db = getFirestore(app);

      if (validation(match)) {
        try {
            // Await the response from createGameAction
            const res = await dispatch(createMatchAction(match, navigate, appid, setDisable))
            // Access the response data (e.g., game ID)
            if (res) {
                const matchIdFromResponse = res?.data?.data?.id;
                console.log("Match ID from response:", matchIdFromResponse);

                setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "CREATE",
                    app_id:appid,
                    resource: "MATCH",
                    resource_id: matchIdFromResponse, // Use the ID from response
                    description: `Match '${matchName}' created successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            }
        } catch (error) {
            console.error("Error creating game or logging activity:", error);
        }
      }
    }

    const [podiumRanks, setPodiumRanks] = useState([])

    const addRank = () => {
      setPodiumRanks(prevState => [...prevState, {ranks : null, score : null}])
    }

    const onRankChange = (e) => {
      let initialRanks = []
      for(var i = 0; i < parseInt(e) ; i++){
        initialRanks.push({ranks : i+1, score : null})
      }
      setPodiumRanks(initialRanks)
    }

    const onScoreChange = (e, index) => {
      let totalPodiumRanks = [...podiumRanks]
      totalPodiumRanks[index].score = e
      setPodiumRanks(totalPodiumRanks)
    }

    const removePodiumRanks = (index) => {
      let removeField = [...podiumRanks]
      let podiumFields = removeField.filter((e,i,c)=>{
          return i != index
      })
      setPodiumRanks(podiumFields)
    }

    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')

    const onChangeStartDateTime = (e) => {
      setStartDateTime(e.target.value)
    }

    const onChangeEndDateTime = (e) => {
      setEndDateTime(e.target.value)
    }
    const cancel = () => {
      navigate(`/matches/${appid}`)
      setIsChanged(false)
    }

    const [matchWinningCondition, setMatchWinningCondition] = useState('')
    const [matchWinningConditionId, setMatchWinningConditionId] = useState(null)
    const [matchWinningConditionOptions, setMatchWinningConditionOptions] = useState() 

    const setchange = () => {
      if (
          (matchIcon==null||matchIcon==undefined)&&
          (matchName===undefined||matchName==''||matchName=="")&&
          (matchId===undefined||matchId==''||matchId=="")&&
          // (gameGerne.length==0)&&
          (matchDescription==undefined||matchDescription==''||matchDescription=="")&&
          (gameId===undefined||gameId==''||gameId=="")&&
          (matchFormatId===null||matchFormatId==''||matchFormatId=="")&&
          (matchOutcomeId===null||matchOutcomeId==''||matchOutcomeId=="")&&
          // (leaderboardRequired==false)&&
          // (leaderboardName===undefined||leaderboardName==''||leaderboardName=="")&&
          // (leaderboardId===undefined||leaderboardId==''||leaderboardId=="")&&
          // (leaderboardDescription===undefined||leaderboardDescription==''||leaderboardDescription=="")&&
          // (leaderboardOutcomeId===null||leaderboardOutcomeId==''||leaderboardOutcomeId=="")&&
          // (recurringId===undefined||recurringId==''||recurringId=="")&&
          (tags.length==0)&&
          (metaData.length == 1 && metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
      )
      {
          setIsChanged(false)
      }
      else 
      {
          setIsChanged(true)
      }
  }

  useEffect(()=>{
      setchange()
      
  },[matchIcon,matchId,matchName,matchDescription,gameId,matchFormatId,matchOutcomeId,tags,metaData])
    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
      if(files && files.length > 0){
        setMedia(files && files.map((e, i) => ({...e, status : false})))
      }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
              <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {matchIcon != null ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={matchIcon} setIcon={setMatchIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput id='matchName' className={styles.field} tooltip={TooltipTitle.MatchName} errorMessage={errors.nameError} label="Match Name*" name="matchName" type="text" required onChange={(e)=>{onMatchName(e)}}/>
                        <TextInput value={matchId} isId={true} id='matchId' className={styles.field} tooltip={TooltipTitle.MatchId} errorMessage={errors.idError} label="Match ID*" name="matchID" type="text" required onChange={(e)=>{onMatchId(e)}}/>
                    </div>
                    <div className={styles.textGroup}>
                      <TextArea value={matchDescription} tooltip={TooltipTitle.MatchDescription} rows={5} label="Match Description" onChange={(e)=>{onMatchDescription(e)}}/>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Information" classTitle="title-red">
              <div className={styles.description}>
                <div className={styles.group}>
                  <div className={styles.field}>
                    <GameDropdown tooltip={TooltipTitle.MatchGame} id='gameName' label='Select Game*' errorMessage={errors.gameErr} placeholder='Choose Game' type="game" options={games} value={gameName} setValue={setGameName} setId={setGameId}/>
                  </div>
                  <div className={styles.field}>
                    <Dropdown tooltip={TooltipTitle.MatchFormat} id='matchFormat' label='Select Match Format*' errorMessage={errors.matchFormatErr} placeholder='Choose Match Format' value={matchFormat} setValue={setMatchFormat} setId={setMatchFormatId} options={matchFormatOption}/>
                  </div>
                </div>
                {(matchFormatId == 2 || matchFormatId == 3) &&
                  <div className={styles.group}>
                    <TextInput className={styles.field} tooltip={TooltipTitle.MatchPlayer} onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()}  label="Minimum Player" name="minPlayer" type='number' onWheelCapture={e => {e.currentTarget.blur()}} min={0} max={maxPlayer} required onChange={(e)=>{onMinPlayer(e)}}/>
                    <TextInput className={styles.field} tooltip={TooltipTitle.MatchPlayer} onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key)&&e.preventDefault()}  label="Maximum Player" name="maxPlater" type='number' onWheelCapture={e => {e.currentTarget.blur()}} min={minPlayer} required onChange={(e)=>{onMaxPlayer(e)}}/>
                  </div>
                }
                <div className={styles.group}>
                  <div className={styles.field}>
                    <Dropdown tooltip={TooltipTitle.MatchResult} id='matchResult' label='Match Result Criteria*' errorMessage={errors.matchResultErr} placeholder='Choose Match Criteria' value={matchOutcome} setValue={setMatchOutcome} setId={setmatchOutcomeId} options={matchOutcomeOption}/>
                  </div>
                  {matchFormatId != 1 &&
                    <div className={styles.field}>
                      <Dropdown id='winningCondition' errorMessage={errors.winningConditionErr} label='Select Winning Condition Type*' placeholder='Select Winning Condition Type' value={matchWinningCondition} setValue={setMatchWinningCondition} setId={setMatchWinningConditionId} options={matchWinningConditionOptions}/>
                    </div>
                  }
                </div>

                <div className={styles.group}>
                  {matchOutcomeId == 4 &&
                    <TextInput tooltip={TooltipTitle.MatchPosition} className={styles.field} label="No of Position" name="noOfPosition" type='number' onWheelCapture={e => {e.currentTarget.blur()}}  required onChange={(e)=>{onPosition(e)}}/>
                  }
                </div>

              </div>
            </Card>
            
            {/* <Card className={cn(styles.card, className)} title="Leaderboard Creation" classTitle="title-purple">
              <div className={styles.description}>
                <div className={styles.group}>
                  <div className={styles.fieldRadio}>
                    <div className={styles.radioLabel}>
                        <span>Do you want to create Leaderboards for this match?*</span>
                    </div>
                    <div className={styles.variants}>
                        <Radio className={styles.radio} name="leaderboard" value={leaderboardRequired} onChange={() => setLeaderboardRequired(true)} content="Yes" />
                        <Radio className={styles.radio} name="leaderboard" value={!leaderboardRequired} onChange={() => setLeaderboardRequired(false)} content="No" />
                    </div>
                  </div>
                </div>
                {leaderboardRequired &&
                <>
                  <div className={styles.group}>
                    <TextInput id='leaderboardName' className={styles.field} tooltip={TooltipTitle.MatchLeaderboard} errorMessage={errors.leaderboardNameErr} label="Leaderboard Name*" name="leaderboardName" type="text" required onChange={(e)=>{onLeaderboardName(e)}}/>
                    <TextInput id='leaderboardId' className={styles.field} tooltip={TooltipTitle.MatchLeaderboardId} errorMessage={errors.leaderboardIdErr}  label="Leaderboard ID*" name="leaderboardId" type="text" required onChange={(e)=>{onLeaderboardId(e)}}/>
                  </div>

                  <div className={styles.textGroup}>
                    <TextArea tooltip={TooltipTitle.MatchLeaderboardDescription} rows={5} label="Leaderboard Description" onChange={(e)=>{onLeaderboardDescription(e)}}/>
                  </div>

                  <div className={styles.group}>
                    <TextInput tooltip={TooltipTitle.MatchLeaderboardSource} disabled={true} label='Leaderboard Source*' value={leaderboardSource} className={styles.field}/>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.MatchLeaderboardRanking} id='leaderboardRanking' label='Leaderboard Ranking Method*' errorMessage={errors.leaderboardRankingErr} placeholder='Choose Leaderboard Ranking Method' value={leaderboardOutcome} setValue={setLeaderboardOutcome} setId={setLeaderboardOutcomeId} options={leaderboardOutcomeOption}/>
                    </div>
                  </div>
                  
                  {leaderboardOutcomeId == 4 &&
                    <>
                      <div className={styles.group}>
                        <div className={styles.podiumSystem}>
                              <div className={styles.podiumGroup}>
                                  <div className={styles.rankLabel}>
                                      <span className={styles.podiumLabelTxt}>No of Podium Ranks</span><span onClick={addRank} className={styles.podiumLabelImg}><AddIcon /></span>
                                  </div>
                                  <div>
                                      <input type='number' onWheelCapture={e => {e.currentTarget.blur()}}  className={styles.inputField} value={podiumRanks.length > 0 ? podiumRanks.length : ''} onChange={(e)=>{onRankChange(e.target.value)}}/>
                                  </div>
                              </div>
                        </div>
                      </div>
                      {podiumRanks && podiumRanks.length > 0 && (
                        <>
                          <div className={styles.groupRanking}>
                            <div className={styles.fieldBlank}></div>
                            <div className={styles.fieldRank}>Starting Rank</div>
                            <div className={styles.field}>Score</div>
                          </div>

                          {podiumRanks && podiumRanks.map((e,i) => (
                            <>
                              <div className={styles.groupRanking} key={i}>
                                <div className={styles.fieldBlank}></div>
                                <div className={styles.fieldRank}>{i+1}</div>
                                <div className={styles.field}><TextInput key={i} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  value={e.score} onChange={(e)=>{onScoreChange(parseInt(e.target.value), i)}} /></div>  
                                <div className={styles.fieldRemove}><span onClick={()=>{removePodiumRanks(i)}}><Remove /></span></div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                        
                    </>
                  }

                  <div className={styles.group}>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.MatchFrequency} label='Recurring' placeholder='Choose Leaderboard Recurring' value={recurring} setValue={setRecurring} setId={setRecurringId} options={recurringOptions}/>
                    </div>
                  </div>
                  
                  {recurringId == 6 &&
                    <div className={styles.group}>
                      <TextInput tooltip={TooltipTitle.MatchStartDate} label='Start Date/Time' name='startDateTime' type='datetime-local' className={styles.field} onChange={(e)=>{onChangeStartDateTime(e)}}/>
                      <TextInput tooltip={TooltipTitle.MatchEndDate} label='End Date/Time' name='endDateTime' type='datetime-local' className={styles.field} onChange={(e)=>{onChangeEndDateTime(e)}}/>
                    </div>
                  }
                </>
              }
              </div>
            </Card> */}

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.MatchTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.MatchMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={saveMatch}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default MatchInformation