import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './itemFilter.module.sass'
import Icon from "../../../../Icon";
import axios from "axios";
import { BASE_URL } from "../../../../../resources/APIEndpoints";
import Form from "../../../../Form";
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaDataAction } from "../../../../../redux/action/common/commonAction";
import { Range, getTrackBackground } from "react-range";
import Checkbox from "../../../../Checkbox";
import Radio from "../../../../Radio";
import { getAllItemAction } from "../../../../../redux/action/builds/economy/items/itemAction";
import { useParams } from "react-router-dom";

const ItemFilterModal = ({className, filters, action, onClose}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()

    let filterList = [{id: 1, name : 'Item Type'}, {id: 2, name : 'Item Sub Type'}, {id: 3, name : 'Limited Availability'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)

    const itemSubTypeOptions = [{id: 1, name: "Stackable", subType: "stackable"}, {id: 2,name: "Equippable", subType: "equippable"}, {id: 3,name: "Tradeable", subType: "tradeable"}, {id: 4,name: "Rentable", subType: "rentable"}, {id: 5,name: "Time Stackable", subType: "time stackable"}];
    const itemTypeOptions = [{id:1, name: 'Durable', type: 'durable'}, {id: 2, name: 'Consumable', type: 'consumable'}]

    const [filterType, setFilterType] = useState('')
    const [filterSubType, setFilterSubType] = useState('')

    const onItemType = (e, name) => {
      if(e.target.checked){
        filters.setType([...filters.type, name])
      }else{
        filters.setType(filters.type.filter(item => item !== name))
      }
  }

  const onItemSubType = (e, name) => {
      if(e.target.checked){
        filters.setSubType([...filters.subType, name])
      }else{
        filters.setSubType(filters.subType.filter(item => item !== name))
      }
  }

    const saveFilter = () => {
      action()
      onClose()
    }

    const resetFilter = () => {
      filters.setType([])
      filters.setSubType([])
      filters.setIsLimited(null)
      let allItem = {
        projectId : appid,
        offset: 0,
        limit: 8,
      }
      dispatch(getAllItemAction(allItem))
      onClose()
    }

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                          <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index === activeIndex})} key={index} onClick={() => setActiveIndex(index)}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Item Type' && filters.type.length > 0 && <span className={styles.countPill}>{filters.type.length}</span>}
                                                    {e.name == 'Item Sub Type' && filters.subType.length > 0 && <span className={styles.countPill}>{filters.subType.length}</span>}
                                                    {e.name == 'Limited Availability' && filters.isLimited != null && <span className={styles.countPill}>{1}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='16'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Item Type" type="text" name="search" icon="search" onChange={(e) => {setFilterType(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {itemTypeOptions && itemTypeOptions.filter(option => option.name && option.name.toLowerCase().includes(filterType.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox className={styles.checkbox} key={i} content={x.name} toggle={true} reverse={true} value={filters.type.includes(x.type)} onChange={(e)=>{onItemType(e, x.type)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }
                                    
                                    {activeIndex == 1 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Item SubType" type="text" name="search" icon="search" onChange={(e) => {setFilterSubType(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {itemSubTypeOptions && itemSubTypeOptions.filter(option => option.name && option.name.toLowerCase().includes(filterSubType.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox className={styles.checkbox} key={i} content={x.name} toggle={true} reverse={true} value={filters.subType.includes(x.subType)} onChange={(e)=>{onItemSubType(e, x.subType)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }

                                    {activeIndex == 2 && 
                                      <>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                              <span>Limited Availability?</span>
                                            </div>
                                            <div className={styles.variants}>
                                              <Radio className={styles.radio} name="isLimited" content="Yes" value={filters.isLimited != null ? filters.isLimited : null} onChange={() => filters.setIsLimited(true)}/>
                                              <Radio className={styles.radio} name="isLimited" content="No" value={filters.isLimited != null ? !filters.isLimited : null} onChange={() => filters.setIsLimited(false)}/>
                                            </div>
                                        </div>
                                      </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn(styles.button, "button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ItemFilterModal