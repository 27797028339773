import React, {useEffect, useState, useRef, useContext} from 'react'
import cn from 'classnames'
import customAxios from '../../utils/interceptor';
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/Card'
import TextInput from '../../components/TextInput';
import SearchDropdown from '../../components/Dropdown/SearchDropdown'
import styles from './appSettings.module.sass'
import GlobalStateContext from '../../components/Context/GlobalStates/GlobalStateContext';
import App from '../../media/images/placeholders/App.png'
import {ToastContainer, toast } from 'react-toastify';
import Toast from '../../components/Toast';
import { BASE_URL, GET_APPS, GET_MEDIA } from '../../resources/APIEndpoints';
import { editAppAction, getAppDetailsAction } from '../../redux/action/apps/appsAction';
import {addMediaAction, getAllMediaAction ,deleteMediaAction} from '../../redux/action/AppSettings/Media/mediaAction';
import { ReactComponent as AddButtonIcon } from '../../media/images/icons/add_button.svg'
import FileUploadSidebar from '../../components/FileUploadSidebar'
import TooltipTitle from '../../Tooltip/TooltipTitle';
// import { Icon } from '@mui/material';
import EmptyStates from "../../components/EmptyStates";
import Modal from "../../components/Modals/ModalComponent";
import DeleteMediaModal from "../../components/AppSettingComponent/MediaNewFrame/DeleteMediaModal"
import FileCardComponent from '../../components/AppSettingComponent/MediaBundlesFrame'
import Icon from '../../components/Icon';
import {ReactComponent as ArchiveSvg} from '../../media/images/icons/archive.svg'
import Form from '../../components/Form';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Loader from '../../components/Loaders/LoaderProgress';
import app,{increaseUnreadCountForAllUsers} from "../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const AppSettingsInfo = ({ className, onClose}) => {

    const { appid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
    const [search,setSearch]=useState('')
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const folderName = 'bundles'

    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
          setUserAccess(false)
      }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
          let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
          if(appPermission?.length > 0){
              setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
          }
      }else{

      }
    }
    
    useEffect(() => {
        getUserAccess()
    }, [])

    const placeholder = App
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [appIcon, setAppIcon] = useState(null)
    const [appName, setAppName] = useState("");
    const [platform, setPlatform] = useState([])
    const [visibleModal, setVisibleModal] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({}); // State for individual upload progress
    const [filesToUpload, setFilesToUpload] = useState([]); // State to hold files to be uploaded
    const [cancelTokens, setCancelTokens] = useState({}); 

    const Cancel = () => {
        setAppIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const onAppName = (e) => {
        if(e.target.value == '' || e.target.value == null){
            setAppName('')
        }else{
            errors.nameErr = ''
            setAppName(e.target.value)
        }
    }

    let usedFor = null

    const onUploadProgress = (progressEvent, fileIndex) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        setUploadProgress(prevProgress => ({
            ...prevProgress,
            [fileIndex]: percentage
        }));
    }
    const onFileUpload = async (e) => {
        let screenshotHolder = [];
        for (var i = 0; i < e.target.files.length; i++) {
            screenshotHolder.push(e.target.files[i]);
        }
        e.target.value=null
        const newFiles = screenshotHolder.map(file => ({
            id: `${file.name}-${Date.now()}`, // Unique ID for the dummy object
            name: file.name,
            type: file.type,
            size: file.size,
            isUploading: true
        }));

        setBundleMedia(prev=>{
            const existingFiles=prev
            existingFiles.mediaDetails=[...newFiles,...prev.mediaDetails]
            return existingFiles
        })

        setFilesToUpload(prevFiles => [...prevFiles, ...newFiles]);
        screenshotHolder && screenshotHolder.forEach(async (element, index) => {
            const fileIndex = filesToUpload.length + index;
            const file_binary = await convertbase64(element);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            setCancelTokens(prevTokens => ({
                ...prevTokens,
                [fileIndex]: source
            }));
            
            const res = await axios.get(`https://admin.staging.specterapp.xyz/v1/admin/upload-url?contentType=${element.type}&projectId=${appid}&category=${folderName}&fileName=${element.name}`);
            let imageUrl = res.data.getUrl;
            var config = {
                headers: { 'Content-Type': `${element.type}` },
                method: 'put',
                url: res.data.postUrl,
                data: convertDataURIToBinary(file_binary),
                onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, fileIndex),
                cancelToken: source.token
            }
            await axios(config)
                .then(async function (response) {
                    let uploadLogo = {
                        projectId: appid,
                        contents: [{
                            contentType: element.type,
                            fileName: element.name,
                            category: folderName,
                            url: res.data.getUrl,
                            fileSize: element.size / 1024
                        }]
                    }
                    try {
                        await dispatch(addMediaAction(uploadLogo, null, appid, folderName, false));
                        const user = JSON.parse(localStorage.getItem('user'))
                        const memberID = user?.id
                        const memberName = user?.name
                        const orgId = (localStorage.getItem('orgId'))
        
                        // Log activity in Firestore
                        const activityLog = {
                            timestamp: new Date().toISOString(),
                            action_type: "CREATE",
                            app_id:appid,
                            resource: "MEDIA",
                            // resource_id: appid, // Use the ID from response
                            description: `Media added successfully in ${capitalizeFirstLetter(folderName)}.`,
                            quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                            activity_feed: true,
                            priority: "LOW",
                            performed_by_id: memberID,
                            organization_id: orgId,
                            performed_by:memberName,
                            request_json:JSON.stringify(uploadLogo),
                        };
                        await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                    } catch (error) {
                        
                    }
                    setTimeout(() => {
                        getMedia();
                        setUploadProgress({})
                        setCancelTokens({})
                    }, 500);
                    // toast.success(<Toast type='Success' messages={`Screenshot ${index + 1} Uploaded Successfully`} />, { position: 'top-right', icon: false, hideProgressBar: true, autoClose: 2000, style: { background: '#DEF1E5' } });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Upload canceled', fileIndex); // Handle the cancellation
                    } else {
                        console.error(error);
                    }
                });
        });
    }
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }
    
    useEffect(()=> {
        getAppDetail()
        getOptionalData()
    }, [appid])


    const getAppDetail = async() => {
        let getAppDetail = {
            ids : [appid],
            organisationId: localStorage.getItem('orgId')
        }
        dispatch(getAppDetailsAction(getAppDetail))
    }

    let appDetail = useSelector((state) => {
        return state.apps.appDetails
    })

    let loading = useSelector((state) => {
        return state.apps.isLoading
    })

    const configureAppDetail = () => {
        if(appDetail && appDetail?.length > 0){
            let app = appDetail[0]
            localStorage.setItem('App', app.name)
            localStorage.setItem('AppIcon', app.iconUrl)
            setAppName(app.name)
            setLogo({src: (app.iconUrl != null ? app.iconUrl : App), alt: 'Upload an Image'}); 
            setAppIcon(app.iconUrl)
            setCategory(app.projectCategoryMaster != null ? app.projectCategoryMaster.categoryName : '')
            setCategoryId(app.projectCategoryMaster != null ? app.projectCategoryMaster.id : null)
        }
    }

    useEffect(() => {
        configureAppDetail()
    }, [appDetail])

    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setCategoriesOption(res.data.data.projectCategoryMasterIds && res.data.data.projectCategoryMasterIds.map(e => ({ id : e.id, name : e.categoryName})))
    }

    const [errors, setErrors] = useState({nameErr : '', categoryIdErr : ''})

    const [disable, setDisable] =  useState(false)

    const [category, setCategory] = useState()
    const [categoryId, setCategoryId] = useState()
    const [categoriesOption, setCategoriesOption] = useState([])

    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleVideo, setVisibleVideo] = useState(false)
    const [visibleImage, setVisibleImage] = useState(false)

    const [media, setMedia] = useState([])
    const [bundleMedia, setBundleMedia] = useState([])
    const [type, setType] = useState(0)

    const getMedia = async () => {
        
        const token = localStorage.getItem('token');
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        
        let media = {
            projectId : appid,
            filters : {
                "categories" : [folderName],
                // "extensions" : ["jpeg"]
            },
            // typeId : activeIndex,
            // category : folderName,
            offset: ((page-1)*limit),
            limit: limit,
        }
        // dispatch(getAllMediaAction(media))
        try {

            let res = await axios.post(BASE_URL + GET_MEDIA, media,headers);
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                setBundleMedia(res.data.data)
            }
        } catch (error) {
            console.error("Error fetching media:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
            return;
        }
    }
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
    const getSearchMedia = async(search) => {

        const token = localStorage.getItem('token');
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

        let getMediaData = {
            search : search,
            projectId : appid,
            // category : folderName,
            filters : {
                "categories" : [folderName],
                // "extensions" : ["jpeg"]
            },
            offset: ((page-1)*limit),
            limit: limit
            // typeId : 0
        }
        // await dispatch(getAllMediaAction(getMediaData))
        try {
            let res = await axios.post(BASE_URL + GET_MEDIA, getMediaData,headers);
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                setBundleMedia(res.data.data)
            }
        } catch (error) {
            console.error("Error fetching media:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
            return;
        }

    }
    const onSearch = () => {
        if(search == ''){
            getMedia()
        }else{
            getSearchMedia(search)
        }
      }

    let files = useSelector((state) => {
        return state.media.media
    })

    const configureMedia = () => {
        if(files){
            setMedia(files)
        }else{
            setMedia([])
        }
    }

    const handlePageChange = (event, value)  => {
        setPage(value)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const deleteAllMedia = async () => {
        let pageCount =  Math.ceil(bundleMedia?.totalCount/limit);
        let allItems = [];
    
        const token = localStorage.getItem('token');
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    
    
        for (let i = 0; i < pageCount; i++) {
            let media = {
                projectId: appid,
                filters: {
                    "categories": [folderName],
                },
                offset: i * limit,
                limit: limit,
            };
    
            try {
                let res = await axios.post(BASE_URL + GET_MEDIA, media,headers);
                if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                    res.data.data.mediaDetails.map((x)=>{
                        allItems.push(x.id);
                    })
                }
            } catch (error) {
                console.error("Error fetching media:", error);
                toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                    position: 'top-right',
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000,
                    style: { background: '#DEF1E5' }
                });
                return;
            }
        }
    
            let deleteMediaData = {
                projectId: appid,
                ids: allItems,
            };
            try {
                await dispatch(deleteMediaAction(deleteMediaData, appid, folderName));
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "ARCHIVE",
                    app_id:appid,
                    resource: "MEDIA",
                    // resource_id: appid, // Use the ID from response
                    description: `Media archived successfully from ${capitalizeFirstLetter(folderName)}.`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(deleteMediaData),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            } catch (error) {
                console.error("Error deleting media:", error);
                toast.error(<Toast type='Error' messages={`Failed to delete all media`} />, {
                    position: 'top-right',
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000,
                    style: { background: '#DEF1E5' }
                });
            }
        
    
        toast.success(<Toast type='Success' messages='All media deleted successfully!' />, {
            position: 'top-right',
            icon: false,
            hideProgressBar: true,
            autoClose: 2000,
            style: { background: '#DEF1E5' }
        });
    };
    

    const cancelUpload = (fileIndex) => {
        if (cancelTokens[fileIndex]) {
            const cancelRef= cancelTokens[fileIndex]
            cancelRef.cancel("Upload canceled");
            setFilesToUpload(prevFiles => prevFiles.filter((_, i) => i !== fileIndex));
            setUploadProgress(prevProgress => {
                const { [fileIndex]: _, ...rest } = prevProgress; // Remove progress entry
                return rest;
            });
            setCancelTokens(prevTokens => {
                const { [fileIndex]: _, ...rest } = prevTokens; // Remove cancel token entry
                return rest;
            });
            setBundleMedia(prev=>{
                const existingFiles=prev
                const existingMediaDetails=existingFiles?.mediaDetails.filter((_, i) => i != fileIndex);
                existingFiles.mediaDetails=existingMediaDetails
                return existingFiles
            })
        }
    }





  const exportMedia = async () => {
    const pageCount = Math.ceil( bundleMedia?.totalCount / limit);
    let exportedData = [];

    const token = localStorage.getItem('token');
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

    for (let i = 0; i < pageCount; i++) {
        let media = {
            projectId: appid,
            filters: {
                "categories": [folderName],
            },
            offset: i * limit,
            limit: limit,
        };

        try {
            let res = await axios.post(BASE_URL + GET_MEDIA, media,  headers );
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                exportedData.push(...res.data.data.mediaDetails);
            }
        } catch (error) {
            console.error("Error fetching media:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
        }
    }

    // Create a new JSZip instance
    const zip = new JSZip();
    const folder = zip.folder(`${folderName}`);

    // Add images to the zip
    const filePromises = exportedData.map(async (file) => {
        if (!file.url) {
            console.error("File URL is undefined:", file);
            toast.error(<Toast type='Error' messages={`File URL is undefined: ${file.name}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
            return;
        }

        try {
            const decodedURL = decodeURIComponent(file.url);
            const response = await axios.get(decodedURL, { responseType: 'blob', headers: { 'Cache-Control': 'no-cache' } });
            if (response.status !== 200) {
                console.error(`Failed to fetch ${decodedURL}`, response.status, response.statusText);
                throw new Error(`Failed to fetch ${decodedURL}`);
            }
            const blob = response.data;
            const fileType = response.headers['content-type'];
            folder.file(file.name, blob, { binary: true });
        } catch (error) {
            console.error("Error fetching file:", error);
            toast.error(<Toast type='Error' messages={`Failed to fetch file: ${file.name}`} />, {
                position: 'top-right',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                style: { background: '#DEF1E5' }
            });
        }
    });

    await Promise.all(filePromises);

    // Generate the zip and trigger download
    zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${folderName}.zip`);
    });
};

let isLoading = useSelector((state) => {
    return state.media.isLoading
})

    const db = getFirestore(app);


    useEffect(() => {
        onSearch()
    }, [page,search])


    useEffect(() => {
        configureMedia()
    }, [files])


    const validation = (data) => {
        let error = {}
        let isValid = true
        if(data.name == '' || data.name == null){
          error.nameErr = 'Name is required'
          isValid = false
          const element = document.getElementById('appName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
    
        if(data.projectCategoryMasterId == '' || data.projectCategoryMasterId == null){
          error.categoryIdErr = 'Category is required'
          isValid = false
          if(data.name != '' && data.name != null){
              const element = document.getElementById('appCategory');
              const offset = 140;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;
              window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }
    
        setErrors(error)
        if(isValid){
          return true
        }
    
      }
  

    const saveApp = async () => {

        let appsDetail = {
            id : appid,
            name : appName,
            iconUrl : appIcon,
            projectCategoryMasterId : categoryId
        }
        if(validation(appsDetail)){
            try {
                await dispatch(editAppAction(appsDetail, appid, setDisable))
                // Access the response data (e.g., game ID) 
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    app_id:appid,
                    resource: "APP",
                    resource_id: appid, // Use the ID from response
                    description: `App information for '${appName}' updated successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                    operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(appsDetail),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            } catch (error) {
                
            }
        }
    }

    if(isLoading || loading){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
    <>
        <ToastContainer />
        <div className={styles.container}>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef}/>
                            {!userAccess && <label className={styles.button} onClick={() => {setVisibleHelp(true); setType(0)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {appIcon != null && !userAccess ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media?.mediaDetails} setMedia={setMedia} types={type} id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={appIcon} setIcon={setAppIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput disabled={userAccess} id='appName' tooltip={TooltipTitle.Appname} errorMessage={errors.nameErr} className={styles.field} value={appName} label="Name*" name="Name" type="text" required onChange={(e)=>{onAppName(e)}}/>
                        <div className={styles.field}>
                            <SearchDropdown disabled={userAccess} tooltip={TooltipTitle.AppCategory}  id='appCategory' errorMessage={errors.categoryIdErr}  options={categoriesOption} value={category} setValue={setCategory} setId={setCategoryId} label="Select Category*" placeholder='Select Category' name="Category" required onChange={(e)=>{onAppName(e)}}/>
                        </div>
                    </div>
                </div>

                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={cn(styles.button, 'button-save-small')} onClick={saveApp}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')}>Cancel</button>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Remote Assets" classTitle="title-blue" head={
                <label for={`files`} className={styles.headButton} style={{marginLeft:"auto"}}>
                    <Icon name="upload" className={styles.headAddIcon}/>Upload
                    <input disabled={userAccess} multiple id={`files`} accept={folderName=='bundles'?'*':"image/*, video/*"} className={cn(styles.input, {[styles.disabled] : userAccess})} type="file" onChange={(e)=>{onFileUpload(e)}}/>
                </label>
                }>
                <div style={{height:"1px",background:"#EFEFEF",margin:"12px"}}></div>
                <div style={{marginBottom:"24px",display:"flex"}}>
                    <div className={styles.filters}>
                        <div className={styles.buttons}>
                            <Icon name='filter' size='20' className={styles.icon} />
                            <span className={styles.buttonText}>Filter</span>
                        </div>
                        <div className={styles.buttons} onClick={()=>exportMedia()}>
                            <Icon name='download' className={styles.icon} />
                            <span className={styles.buttonText}>Download All</span>
                        </div>
                    </div>
                    <div style={{marginLeft:"auto"}}><Form size='sm' className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {setSearch(e.target.value)}}/></div>
                </div>



                <div className={styles.wrapper}>
                    {(!(bundleMedia?.mediaDetails && bundleMedia?.mediaDetails?.length > 0 ) && !uploadProgress?.[0] > 0) &&
                        <>
                            <EmptyStates type='Media'/>
                        </>
                    }   

                    {bundleMedia?.mediaDetails && bundleMedia?.mediaDetails?.length > 0 && isLoading == false &&
                        <div className={styles.filePreview}>
                            {bundleMedia?.mediaDetails && bundleMedia?.mediaDetails.map((x, index) => (
                                <FileCardComponent access={userAccess} onCancel={()=>cancelUpload(index)} folderName={folderName} getMedia={getMedia} type={x.type == 'image' ? 'Images' :x.type == 'video' ? 'Videos':'others'} className={styles.product} item={x} key={index} released progress={uploadProgress[index] || 0}/>
                            ))}
                        </div>
                    }
                    <Modal visible={visibleModal} onClose={() => {setVisibleModal(false)}}>
                        <DeleteMediaModal onProceed={()=>{deleteAllMedia()}} onClose={() => {setVisibleModal(false)}}/>
                    </Modal>
                    {!isLoading && bundleMedia?.mediaDetails&& bundleMedia?.mediaDetails?.length > 0 &&
                        <div className={styles.pagination}>
                            <Stack spacing={2}>
                            <Pagination renderItem={(item) => (
                                <PaginationItem
                                {...item}
                                sx={{
                                    '&.MuiPaginationItem-root': {
                                    minWidth: '28px',
                                    height: '28px',
                                    fontSize: '12px'
                                    },
                                    '&.Mui-selected': {
                                    color: 'rgb(255, 255, 255)',
                                    fontWeight : 'bold',
                                    border : '1px solid rgb(42, 133, 255)',
                                    background : 'rgba(42, 133, 255)'
                                    },
                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                    color: 'rgb(255, 255, 255)',
                                    fontWeight : 'bold',
                                    border : '1px solid rgb(42, 133, 255)',
                                    background : 'rgba(42, 133, 255)'
                                    }
                                }}
                                />
                            )} siblingCount={1} page={page} count={Math.ceil(bundleMedia?.totalCount/limit)} color="primary" onChange={handlePageChange} />
                            </Stack>
                        </div>
                    }
                </div> 
            </Card>
        </div>
    </>    
    )
}

export default AppSettingsInfo