import React from "react";
import cn from "classnames";
import styles from './sort.module.sass'
import Sort from '../../../../components/Sort'
import {ReactComponent as Arrow} from '../../../../media/images/icons/Union.svg'

const Sorting = ({className, setPage, value, setValue, data, sortIndex, setSortIndex, selected, setSelected, sort, setSort}) => {
    return(
        <>
            <Sort setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort}>
                <div className={cn(styles.buttons, className, { [styles.active]: (value?.name !== null)})}>
                    <span className={styles.sortIcon}>
                    <Arrow className={cn(styles.up, { [styles.active]: (value?.value !== null && value?.value === 0)})}/>
                    <Arrow className={cn(styles.down, { [styles.active]: (value?.value !== null && value?.value === 1)})}/>
                    </span>
                    <span className={styles.buttonText}>
                        {value?.name ? (value?.name === 'updated_at' ? 'Updated At' : value?.name === 'created_at' ? 'Created At' : value?.name == 'name' ? 'Name' : '') : 'Sort'}
                    </span>
                </div>
            </Sort>
        </>
    )
}

export default Sorting