import React, {useEffect, useState} from 'react'
import bcrypt from 'bcryptjs'
import styles from './login.module.sass'
import TextInput from '../../../../components/TextInput'
import {ReactComponent as GoogleSVG} from '../../../../media/images/icons/google.svg'
import Icon from '../../../../components/Icon'
import {useDispatch} from 'react-redux'
import {getEmailVerificationAction, loginUser} from '../../../../redux/action/authenticate/authenticationAction'
import {ToastContainer, toast} from 'react-toastify';
import {useNavigate, Link, useLocation} from 'react-router-dom'
import './style.css'
import Spectre from '../../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../../media/images/x_fill.svg'
import Modal from '../../../../components/Modals/ModalComponent'
import OtpModal from '../../../../components/OTPModal'
import Lottie from 'lottie-react'
import {jwtDecode} from "jwt-decode";
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";
import { BASE_URL } from '../../../../resources/APIEndpoints'
import Toast from '../../../../components/Toast'

const SignUp = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

    const style = {
        width: 560,
        height: 608,
    }

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [organizationType, setOrganizationType] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [organization, setOrganization] = useState(true)
    const [showOtp, setShowOtp] = useState(false)
    const [googleId, setGoogleId] = useState(null);
    const [errors, setErrors] = useState({email:''})

    const onEmail = (email) => {
        setEmail(email)
    }

    const onGoogleSignUp = (googleId) => {
        setGoogleId(googleId);
    }

    const signUp = () => {
        let sendOtp = {
            email: email
        }
        if(validation(sendOtp)){
            localStorage.setItem('signup', 'normal')
            localStorage.setItem('userEmail', JSON.stringify(email))
            dispatch(getEmailVerificationAction(sendOtp, navigate, setShowOtp))

        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            signUp()
        }
    }

    function validateEmailAddress(emailAddress) {
        let atSymbol = emailAddress.indexOf("@");
        let dotSymbol = emailAddress.lastIndexOf(".");
        let spaceSymbol = emailAddress.indexOf(" ");
    
        if ((atSymbol != -1) &&
            (atSymbol != 0) &&
            (dotSymbol != -1) &&
            (dotSymbol != 0) &&
            (dotSymbol > atSymbol + 1) &&
            (emailAddress.length > dotSymbol + 1) &&
            (spaceSymbol == -1)) {
            return true;
        } else {
            return false;
        }
    }
      

    const validation = (data) => {
        const error = {}
        let isValid = true
        
        if(!validateEmailAddress(email)){
            error.email = 'Please enter a valid email address.'
            isValid = false
        }
        if(data.email == null || data.email == ''){
            error.email = 'Email is required'
            isValid = false
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const getPlanCode = () => {
        const searchParams = new URLSearchParams(location.search)
        const planCodeParam = searchParams.get('plan_code')
        if(planCodeParam){
            sessionStorage.setItem('plan_code', JSON.stringify(planCodeParam))
        }
    }

    const removeItem = () => {
        localStorage.removeItem('signup')
        localStorage.removeItem('userName')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userGoogleId')
    }

    useEffect(() => {
        removeItem()
    }, [])

    useEffect(() => {
        getPlanCode()
    }, [location])

    const signUpWithGoogle = async (response) => {
        const userInfo = await axios
            .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {Authorization: `Bearer ${response.access_token}`},
        })
        .then(res => res.data);

        try{
            const userExist = await axios.post(`${BASE_URL}/member/email-exist`, {email : userInfo.email})
            let user = {
                email: userInfo.email,
                password: null,
                googleId: userInfo.sub
            }
            onGoogleSignUp(userInfo.sub);
            localStorage.setItem('userEmail', JSON.stringify(userInfo.email))
            localStorage.setItem('userGoogleId', JSON.stringify(userInfo.sub))
            localStorage.setItem('userName', JSON.stringify(userInfo.name))
            localStorage.setItem('signup', 'google')
            navigate(`/user-detail`)
        }catch(err){
            err.response.data.errors && err.response.data.errors.map((e) =>{
                toast.error(<Toast type='Error' messages={e.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            setTimeout(() => {
                navigate('/')
            }, 2000)
        }
    }

    const signup = useGoogleLogin({
        clientId: CLIENT_ID,
        onSuccess: signUpWithGoogle,
        onError: error => console.log('Sign up Failed')
    })

    

    return (
        <>
            <ToastContainer/>
            <div className={styles.signUp}>
                <div className={styles.signUp_container_outer}>
                    <div className={styles.logo}>
                        <SpecterLogo/>
                    </div>
                    <div className={styles.signUp_container_inner}>
                        <div className={styles.Branding}>
                            <HalfCircle className={styles.halfCircle}/>
                            <Cross className={styles.crossFilled}/>
                            <FullCircle className={styles.fullCircle}/>
                            <FullHollowCircle className={styles.hollowCircle}/>
                            <div className={styles.animation}>
                                <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true}
                                        autoPlay={true} style={style}/>
                            </div>
                        </div>
                        <div className={styles.form}>
                            <HollowCross className={styles.hollowCross}/>
                            <div className={styles.signInForm}>
                                <img src={FilledCrossed} className={styles.filledCross}/>
                                <SmallerFullCircle className={styles.fullCircle}/>
                                <div className={styles.signInFormContainer}>
                                    <div className={styles.formTitle}>
                                        <p className={styles.title}>Stay Ahead <br/> of the Curve<span
                                            className={styles.blueText}>!</span></p>
                                        <p className={styles.content}>Create your free Specter account and supercharge
                                            your development today!</p>
                                    </div>

                                    <div className={styles.formFiedls}>
                                        <TextInput position="bottom" errorMessage={errors.email}  className={styles.field} type="email" placeHolder="Your Email"
                                                   onKeyPress={handleKeyPress} currency={<Icon name="mail" size="20"/>}
                                                   onChange={(e) => {
                                                       onEmail(e.target.value)
                                                   }} fieldName={email}/>
                                    </div>

                                    <div className={styles.signInButton}>
                                        <button className={styles.Save} onClick={signUp}>Continue</button>
                                    </div>

                                    <div className={styles.logInOption}>
                                        <div className={styles.sep}></div>
                                        <div className={styles.option}>or</div>
                                        <div className={styles.sep}></div>
                                    </div>

                                    {<div className={styles.signInGoogle} onClick={signup}>
                                        <div className={styles.icon}><GoogleSVG/></div>
                                        <div className={styles.text}>Google</div>
                                    </div>}

                                    <div className={styles.policy}>
                                        <span>By continuing, you accept Specters <Link to='https://www.specterapp.xyz/termsOfService' className={styles.links}><span>Terms of Service</span></Link> & <Link to='https://www.specterapp.xyz/privacyPolicy' className={styles.links}><span>Privacy Policy</span></Link></span>
                                    </div>

                                    <div className={styles.message}>
                                        <span>Already have a account? <Link to='/'
                                                                            className={styles.links}><span>Log in</span></Link></span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Modal width='small' visible={showOtp} onClose={() => {setShowOtp(false)}}>
                <OtpModal email={email} hideModal={setShowOtp} onClose={() => {setShowOtp(false)}}></OtpModal>
            </Modal>
        </>
    )
}

export default SignUp