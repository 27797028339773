import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './createPayout.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../Radio'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { grantPMAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import Dropdown from '../../../../Dropdown'
import { BASE_URL, GET_GRANT_PM, GET_USER_WALLET } from '../../../../../resources/APIEndpoints'
import axios from 'axios'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
const GrantPM = ({className, type, onClose}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const [payoutOptions, setPayoutOptions] = useState([])
    const [types, setTypes] = useState(false)
    const [wallets, setWallets] = useState()
    const [amount, setAmount] = useState(null)
    const [payout, setPayout] = useState(false)
    const [walletId, setWalletId] = useState(null)

    useEffect(() => {
        getWalletDetails()
    }, [])

    const db = getFirestore(app);
    const playerProfile = useSelector((state) => {
        return state.players.profile
    })

    const getWalletDetails = async() => {
        let details = {
            userId : id,
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(BASE_URL + GET_GRANT_PM, details, headers)
        let data = []
        res.data.data && res.data.data.forEach((e,i) => {
            data.push({id : e.progressionMarkerId, name : e.name})
        })
        setPayoutOptions(data)
    }

    const onAmount = (e) => {
        setAmount(parseInt(e.target.value))
    }

    const createPayout = async () => {
        try {
            let PM = {
                projectId: appid,
                userId: id,
                amount: amount,
                progressionMarkerId: walletId,
                operation: types === false ? 'add' : 'subtract',
            };
    
            // Dispatch the action to create payout
            const res = await dispatch(grantPMAction(PM, appid, id));
    
            // If dispatch is successful, continue to log the activity
            const user = JSON.parse(localStorage.getItem('user'));
            const memberID = user?.id;
            const memberName = user?.name;
            const orgId = localStorage.getItem('orgId');
    
            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id: appid,
                resource: "PLAYER",
                resource_id: id, // Use the ID from response
                description: `Progression Marker for Player '${playerProfile?.[0]?.username??playerProfile?.[0]?.email??playerProfile?.[0]?.linkedAccounts[0]?.userId??playerProfile?.[0]?.linkedAccounts[0]?.phoneNumber??playerProfile?.[0]?.customId}' granted successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by: memberName,
                request_json: JSON.stringify(PM),
            };
    
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
    
            // Close the modal or perform any post-operation tasks
            onClose();
        } catch (error) {
            // Handle the error properly here (e.g., show a toast, log to console, etc.)
            console.error("Error creating payout or logging activity:", error);
        }
    };
    

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Grant Progression Marker</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.contentBody}>
                                <div className={styles.payout}>
                                    <div className={styles.group}>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                                <span>Add/Subtract</span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} name="type" value={!types} onChange={()=>{setTypes(false)}} content="Add" />
                                                <Radio className={styles.radio} name="type" value={types} onChange={()=>{setTypes(true)}} content="Subtract" />
                                            </div>
                                        </div>
                                        <div className={styles.field}>
                                            <Dropdown label='Select Progression Marker' placeholder='Select Progression Marker' options={payoutOptions} value={wallets} setValue={setWallets} setId={setWalletId}/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={styles.payout}>
                                    <div className={styles.group}>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                                <span>Schedule Payout</span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} name="schedule" value={!payout} onChange={()=>{setPayout(false)}} content="Immediate" />
                                                <Radio className={styles.radio} name="schedule" value={payout} onChange={()=>{setPayout(true)}} content="Set Date/Time" />
                                            </div>
                                        </div>
                                        <TextInput disabled={walletId == null ? true : false} className={styles.field} label='Set Amount' type='text' onChange={(e)=>{onAmount(e)}}/>
                                    </div>
                                </div>

                                <div className={styles.group}>

                                </div>
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={createPayout}>Save</button>
                                <button className={styles.cancel} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GrantPM