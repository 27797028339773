import React, { useContext, useEffect, useState } from 'react'
import styles from './overview.module.sass'
import AppDetails from './AppDetails'
import Economy from './Economy'
import Games from './Games'
import Matches from './Matches'
import Events from './Events'
import Progression from './Progression'
import Competition from './Competition'
import Activity from './ActivityFeeds'
import Resource from './Resources'
import Card from '../../components/Card'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAppOverviewAction } from '../../redux/action/apps/appsAction'
import { ToastContainer } from 'react-toastify'
import Lottie from 'lottie-react'
import Spectre from '../../media/lottieAnimation/Loader_New.json'
import GlobalStateContext from '../../components/Context/GlobalStates/GlobalStateContext'
import Loader from '../../components/Loaders/LoaderProgress'

function AppOverview() {

  const {appid} = useParams()
  const dispatch = useDispatch()

  const style = {
    width : '160px',
    height : '160px',
  }

  const getAppOverview = async() => {
    let appOverview = {
      projectId : appid
    }
    await dispatch(getAppOverviewAction(appOverview))
  }

  let isLoading = useSelector((state)=>{
    return state.apps.isLoading
  })

  let appInfo = useSelector((state)=>{
    return state.apps.appOverview.appInfo
  })

  let appStatistics = useSelector((state) => {
    return state.apps.appOverview.economy
  })

  let progression = useSelector((state) => {
    return state.apps.appOverview.progression
  })

  let games = useSelector((state) => {
    return state.apps.appOverview.games
  })

  let matches = useSelector((state) => {
    return state.apps.appOverview.matches
  })

  let competition = useSelector((state) => {
    return state.apps.appOverview.competitionDetails
  })

  let events = useSelector((state) => {
    return state.apps.appOverview.eventDetails    
  })

  let activityFeeds = useSelector((state) => {
    return state.apps.appOverview.activityFeed
  })

  useEffect(() => {
    getAppOverview()
  }, [])

  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return (
    <>
      <ToastContainer />
      <div className={styles.appDetails}>
        <div className={styles.appOverview}>
          <AppDetails data={appInfo} className={styles.card} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Economy data={appStatistics} className={styles.card} />
          <div className={styles.build}>
            <Events data={events} className={styles.card} />
            <Progression data={progression} className={styles.card} />
          </div>
          <Competition data={competition} className={styles.card} />
          <Resource data={appStatistics} className={styles.card} />
        </div>
        <div className={styles.col}>
          <Activity data={activityFeeds} className={styles.card} />
          <Matches games={games} matches={matches} className={styles.card} />
          <Games games={games} matches={matches} className={styles.card} />
        </div>
      </div>
    </>
  )
}

export default AppOverview