import { GET_PLAYERS, GET_PLAYERS_REWARDS, GET_PLAYERS_WALLET, GET_PLAYER_DATA, GET_PLAYER_INVENTORY, GET_PLAYER_MATCHES, GET_PLAYER_PROFILE, GET_PLAYER_PROGRESSION, CREATE_CUSTOM_PLAYER, CREATE_PLAYER_PAYOUT, GRANT_PM, FILTER_PLAYERS_SUCCESS, GET_PLAYER_TASK, GET_PLAYERS_SUCCESS, FILTER_PLAYERS_ERROR } from "../../../actionTypes/actionTypes";

const initialState = {
    players : [],
    profile : [],
    playerData : [],
    inverntoryOverview : [],
    inventoryCollection : [],
    inventoryHistory : [],
    walletOverview : [],
    walletHistory : [],
    progressionOverview : [],
    progressionSystem : [],
    progressionHistory : [],
    rewards : [],
    matches : [],
    tasks : [],
    isLoading : true,
    totalCount : null,
    totalInventoryCount : null,
    totalWalletCount : null,
    totalProgressionCount : null,
    totalRewardCount : null,
    totalMatchCount : null,
    totalTaskCount : null,
    isFilterd: false
};

export const PlayerReducer = (state=initialState, action) => {
    switch(action.type){

        case GET_PLAYERS : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_PLAYERS_SUCCESS : {
            return {
                ...state,
                players: action.payload,
                profile : [],
                playerData : [],
                inverntoryOverview : [],
                inventoryCollection : [],
                inventoryHistory : [],
                walletOverview : [],
                walletHistory : [],
                progressionOverview : [],
                progressionSystem: [],
                progressionHistory : [],
                rewards : [],
                matches : [],
                isLoading : false,
                totalCount : action.totalCount,
                isFilterd: false
            }
        }

        case FILTER_PLAYERS_SUCCESS : {
            return {
                ...state,
                filteredPlayer : action.payload,
                isFilterd: true
            }
        }

        case FILTER_PLAYERS_ERROR : {
            return {
                ...state,
                filteredPlayer : [],
                error: action.payload,
                isFilterd: true
            }
        }

        case GET_PLAYER_PROFILE : {
            return {
                ...state,
                profile: action.payload
            }
        }

        case GET_PLAYER_DATA : {
            return {
                ...state,
                playerData: action.payload
            }
        }

        case GET_PLAYER_INVENTORY : {
            return {
                ...state,
                inventoryCollection: action.payload.collectionData,
                inventoryHistory : action.payload.finalUserInventory,
                inventoryOverview : action.payload.inventoryOverview,
                totalInventoryCount : action.totalCount
            }
        }

        case GET_PLAYERS_WALLET : {
            return {
                ...state,
                walletOverview: action.payload.walletOverview,
                walletHistory : action.payload.transactionHistory,
                totalWalletCount : action.totalCount
            }
        }

        case GET_PLAYER_PROGRESSION : {
            return {
                ...state,
                progressionOverview: action.payload.progressionOverview,
                progressionSystems : action.payload.progressionSystems,
                progressionHistory: action.payload.progressionHistory,
                totalProgressionCount : action.totalCount
            }
        }

        case GET_PLAYERS_REWARDS : {
            return {
                ...state,
                rewards: action.payload.rewardHistory,
                totalRewardCount : action.totalCount
            }
        }

        case GET_PLAYER_TASK : {
            return {
                ...state,
                tasks : action.payload.taskBus,
                totalTaskCount : action.totalCount
            }
        }

        case GET_PLAYER_MATCHES : {
            return {
                
                ...state,
                matches: action.payload,
                totalMatchCount : action.totalCount
            }
        }

        case CREATE_CUSTOM_PLAYER : {
            return {
                ...state,
                players : action.payload
            }
        }

        case CREATE_PLAYER_PAYOUT : {
            return {
                ...state,
                walletHistory : action.payload
            }
        }

        case GRANT_PM : {
            return {
                ...state,
                progressionHistory : action.payload
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    