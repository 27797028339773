import React, { useEffect, useState } from "react";
import Card from "../../../../../components/Card";
import cn from "classnames";
import styles from './paymentList.module.sass'
import {ReactComponent as Paypal} from '../../../../../media/images/icons/visa.svg'
import {ReactComponent as Check} from '../../../../../media/images/icons/Checkbox_Enable.svg'
import Visa from '../../../../../media/images/visa.png'
import MasterCard from '../../../../../media/images/mastercard_black.png'
import Rupay from '../../../../../media/images/payment.png'
import Chip from '../../../../../media/images/chip_outline.png'
import ChipGold from '../../../../../media/images/chip_gold.png'
import Icon from "../../../../../components/Icon";
import { useDispatch } from "react-redux";
import { updateCardDetailsAction } from "../../../../../redux/action/organisation/Billing/billingAction";
import { useNavigate } from "react-router-dom";

const PaymentList = ({className, options, plan}) => {

    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const initalState = [{"cardId": "xxxx xxxx xxxx xxxx",
        "customerId": "xxxxxxxxxxxxxxxxxx",
        "firstName": "John Doe",
        "lastName": "John Doe",
        "lastFourDigits": "xxxx",
        "expiryMonth": 'xx',
        "expiryYear": 'xxxx',
        "cardType": "xxxx",
        card: 'dummy'
    }]
    const [list, setList] = useState(initalState)

    const itemPerView = 1
    const [currentIndex, setCurrentIndex] = useState(0)

    const onNext = () => {
        if(currentIndex < list?.length - itemPerView){
            setCurrentIndex(currentIndex + 1)
        }
    }

    const onPrev = () => {
        if(currentIndex > 0){
            setCurrentIndex(currentIndex - 1)
        }
    }

    const onSelect = (id) => {
        setList(option => option.map((x, i) => x.cardId ===  id ? {...x, active: !x.active} : {...x, active: false}))
    }

    const configureList = () => {
        if(options && options?.length > 0){
            setList(prevState => [...(options && options.map(x => ({...x, active: false}))), ...prevState])
        }
    }

    useEffect(() => {
        configureList()
    }, [options])

    const onUpdatePaymentMethod = () => {
        console.log(plan)
        let subscriptionId = {externalSubscriptionId: plan?.subscriptionId}
        dispatch(updateCardDetailsAction(subscriptionId, Navigate))
    }
    
    return(
        <>
            <Card className={styles.card} classCardHead={styles.head} classTitle={cn("title-green", styles.title)}>
                <div className={styles.wrapper}>
                    <div className={styles.cardHead}>
                        <p className={styles.title}>Payment Methods</p>
                        <p className={styles.info}>List of all your saved cards and details.</p>
                    </div>
                    <div className={styles.list}>
                        <div className={styles.cards}>
                            {list && list.slice(currentIndex, currentIndex + itemPerView).map(x => (
                                <>
                                    {x.card !== 'dummy' &&
                                    <>
                                        <div onClick={() => onSelect(x.cardId)} className={cn(styles.card, {[styles.active]: x.active}, {[styles.visa] : x.cardType === 'visa'}, {[styles.master] : x.cardType === 'mastercard'}, {[styles.rupay] : x.cardType === 'rupay'})}>
                                            <div className={styles.type}>
                                                {x.cardType === 'visa' && <><img src={Visa} alt={x.id} /></>}
                                                {x.cardType === 'mastercard' && <><img src={MasterCard} alt={x.id} /></>}
                                                {x.cardType === 'rupay' && <><img src={Rupay} alt={x.id} /></>}
                                                {x.cardType === 'paypal' && <><Paypal /></>}
                                            </div>
                                            <div className={styles.chip}>
                                                <img src={x.cardType === 'mastercard' ? Chip : ChipGold} alt={x.id} />
                                            </div>
                                            <div className={styles.details}>
                                                <div className={styles.cardNo}>{"**** **** **** " + x?.lastFourDigits}</div>
                                                <div className={styles.cardDetail}>
                                                    <div className={styles.name}>
                                                        {x.firstName}
                                                    </div>
                                                    <div className={styles.expiry}>
                                                        <div className={styles.title}>Expiry</div>
                                                        <div className={styles.date}>{x.expiryMonth}/{(x.expiryYear).toString().slice(-2)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {x.card === 'dummy' &&
                                    <>
                                        <div className={cn(styles.card, {[styles.dummy]: true})} onClick={onUpdatePaymentMethod}>
                                            <div className={styles.action}>
                                                <div className={styles.icon}>
                                                    <Icon className={styles.sign} name='add' size='12'/>
                                                    {/* <span className={styles.sign}>+</span> */}
                                                </div>
                                                <div className={styles.text}>Add New Card</div>
                                            </div>
                                        </div>
                                    </>
                                }
                                </>
                            ))}
                            <div className={styles.control}>
                                <button className={cn(styles.prev, {[styles.disabled] : currentIndex === 0})} onClick={onPrev} disabled={currentIndex === 0}><Icon name='arrow-down' size='16' /></button>
                                <button className={cn(styles.next, {[styles.disabled] : currentIndex >= list.length - itemPerView})} onClick={onNext} disabled={currentIndex >= list.length - itemPerView}><Icon name='arrow-down' size='16' /></button>
                            </div>
                        </div>
                        <div className={styles.info}>
                            {list && list.slice(currentIndex, currentIndex + itemPerView).map(x => (
                                <>
                                    <div className={styles.detailInfo}>
                                        <div className={styles.heading}>Card Information</div>
                                        <div className={styles.body}>
                                            <div className={styles.name}>
                                                <div className={styles.label}>Name</div>
                                                <div className={styles.value}>{x?.firstName ? x?.firstName : '-'}</div>
                                            </div>
                                            <div className={styles.number}>
                                                <div className={styles.label}>Card No</div>
                                                <div className={styles.value}>{"**** **** **** " + x?.lastFourDigits}</div>
                                            </div>
                                            <div className={styles.type}>
                                                <div className={styles.group}>
                                                    <div className={styles.field}>
                                                        <div className={styles.label}>Type</div>
                                                        <div className={styles.value}>{x?.cardType === 'visa' ? 'VISA' : x?.cardType === 'mastercard' ? 'MasterCard' : x?.cardType === 'rupay' ? 'RuPay' : x.cardType}</div>
                                                    </div>
                                                    <div className={styles.field}>
                                                        <div className={styles.label}>Expiry</div>
                                                        <div className={styles.value}>{(x?.expiryMonth && x?.expiryYear) ? <>{x?.expiryMonth}/{(x?.expiryYear).toString().slice(-2)}</> : '-'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {x.active &&
                                                <div className={styles.action}>
                                                    <button className={cn(styles.button, 'button-save-small')}>Update</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )

}

export default PaymentList