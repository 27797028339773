import React, { useEffect } from "react";
import Card from "../../../../components/Card";
import styles from './billingHistory.module.sass'
import BillingHistoryTable from '../../../../components/AppSettingComponent/Plans&Billing/BillingHistory'
import cn from 'classnames'
import { useDispatch, useSelector } from "react-redux";

const BillingHistory = ({className, items}) => {

    return(
        <>
            <Card className={styles.card} classCardHead={styles.head} title={'Billing History'} classTitle={cn("title-yellow", styles.title)}>
                <div className={styles.container}>
                    {items?.length === 0 && <p className={styles.warning}>No Billing History Available.</p>}      
                    {items?.length > 0 && <BillingHistoryTable items={items} />}
                </div>
            </Card>
        </>
    )
}

export default BillingHistory