import React from "react";
import styles from "./Item.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../Icon";

const Item = ({className, category, value, icon, children, visible, setVisible, disabled}) => {
  return (
    <div className={cn(styles.item, className, {[styles.active]: visible, [styles.disabled]: disabled })}>
      <div className={styles.head} onClick={!disabled ? () => setVisible(!visible) : null}>
        <Icon name={icon} size="20" />
        <div className={styles.details}>
          <div className={styles.category}>{category}</div>
          <div className={styles.value}>{value != '' ? value : (value == '' && (category == 'Start Date' || category == 'End Date')) ? 'MM DD, YYYY' : (value == '' && (category =='Start Time' || category =='End Time')) ? 'HH:mm' : ''}</div>
        </div>
      </div>
      {!disabled &&
        <div className={styles.body}>
          <OutsideClickHandler onOutsideClick={!disabled ? () => setVisible(false) : null}>
            {children}
          </OutsideClickHandler>
        </div>
      }
    </div>
  );
};

export default Item;
