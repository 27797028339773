import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import EventTooltip from '../../../../CustomTooltips/EventTooltip'
import TooltipGlodal from "../../../../TooltipGlodal";
import {ReactComponent as Play} from "../../../../../media/images/icons/Play.svg"
import {ReactComponent as Stop} from "../../../../../media/images/icons/Stop.svg"
import { useDispatch } from "react-redux";
import { editDefaultEventAction } from "../../../../../redux/action/builds/events/eventActions";
import Modal from "../../../../Modals/ModalComponent";
import EventModal from "../../../../BuildComponents/EventComponent/EventModal";
import cn from "classnames";
import Switch from "../../../../Switch/labelSwitch";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, className, access, value, onChange }) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()
    const [visibleModal, setVisibleModal] = useState(false);

    const toggleSubscribed = (e) => {
        setVisibleModal(true)
    }

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Link to={`/view-default-events/${appid}/${item.id}`}>
                            <TooltipGlodal width={200} itemId={`defaultEvent${item.id}`} title={item.name}><div className={styles.itemName}><span id={`defaultEvent${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        </Link>
                    }
                </div>
                <div className={styles.col}>{item.category != null ? item.category : item.category === null ? '-' : ''}</div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && [...item?.defaultParameterDetails,...item?.optionalParameterDetails, ...item?.specterParameterDetails, ...item?.customParameterDetails] && [...item?.defaultParameterDetails,...item?.optionalParameterDetails,...item?.specterParameterDetails,...item?.customParameterDetails].slice(0,1).map((e,i) =>{
                        return(
                        <span>{e.name}</span>
                        )
                    })}
                    <span>{(item?.defaultParameterDetails?.length + item?.customParameterDetails?.length + item?.optionalParameterDetails?.length + item?.specterParameterDetails?.length) > 1 ? <EventTooltip primaryText='System Parameters' secondaryText='Custom Parameters' defaults={item?.defaultParameterDetails} optional={item?.optionalParameterDetails} specter={item?.specterParameterDetails} custom={item?.customParameterDetails}><span className={styles.number}>&nbsp;&#43;{item?.defaultParameterDetails.length + item?.customParameterDetails.length + item?.optionalParameterDetails.length + item?.specterParameterDetails.length-1}</span></EventTooltip>  : ''}</span>
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Switch size='small' value={item.isSubscribed} onChange={() => toggleSubscribed(item)}/>
                    }
                    {/* <div className={styles.status}>
                        <div className={item.isSubscribed ? styles.active : !item.isSubscribed ? styles.archive : null}>{item.isSubscribed ? 'Subscribed' : !item.isSubscribed ? 'Not Subscribed' : null}</div>
                        <div className={styles.icons}>
                            <Play className={cn(styles.icon, {[styles.disabled] : item.isSubscribed})} onClick={() => toggleSubscribed(item)}/>
                            <Stop className={cn(styles.icon, {[styles.disabled] : !item.isSubscribed})} onClick={() => toggleSubscribed(item)}/>
                        </div>
                    </div> */}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <Control access={access} className={styles.control} id={item.id} appid={appid} archiveStatus={item.archive} deleteStatus={item.active} markerName={item.progressionMarkerName}/>}
                </div>
            </div>
            <Modal width='small'  visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <EventModal type='Default Event' name={item.name} id={item.id} onClose={() => setVisibleModal(false)}  eventStatus={item.isSubscribed}/>
            </Modal>
        </>
    );
};

export default Row;
