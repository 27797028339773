// BASE_URL
export const BASE_URL = 'https://admin.staging.specterapp.xyz/v1'

// Meta Data
export const GET_META_DATA = '/master-data'
export const GET_ALL_COUNTRY = '/get-countries'
export const GET_ALL_STATES = '/get-states'
export const GET_STATES = '/get-states'
export const GET_CITY = '/get-cities'
export const GET_PLATFORMS = '/organisation/get-platforms'
export const GET_GOALS = '/organisation/get-goals'

// Tags Data
export const GET_TAGS = '/tags/get'
export const UPDATE_TAGS = '/tags/update'

// MEDIA APIENDPOINTS

export const GET_MEDIA = '/media/get'
export const ADD_MEDIA = '/media/add'
export const DELETE_MEDIA = '/media/delete'

// ONBOARDING APIENDPOINTS
export const LOGIN = '/member/sign-in'
export const SIGNUP = '/member/sign-up'
export const GET_EMAIL_VERIFICATION = "/member/get-verification-email"
export const SEND_EMAIL_VERIFICATION = "/member/verify-code"
export const GET_RESET_PASSWORD_LINK = '/member/request-reset-password'
export const RESET_PASSWORD = '/member/reset-password'
export const ACCEPT_INVITE = '/member/invite/accept'
export const GET_ALL_ORG = '/member/get-organisations'

// GAMES APIENDPOINTS
export const GET_GAMES = '/game/get'
export const ADD_GAMES = '/game/add'
export const EDIT_GAMES = '/game/update'

// MATCHES APIENDPOINTS
export const GET_MATCHES = '/match/get'
export const ADD_MATCHES = '/match/add'
export const EDIT_MATCHES = '/match/update'
export const GET_LEADERBOARD_MATCHES = '/leaderboard/get-matches'

// LEADERBOARD APIENDPOINTS
export const GET_LEADERBOARD = '/leaderboard/get'
export const GET_LEADERBOARD_RESULT = '/leaderboard/get-leaderboard-details'
export const ADD_LEADERBOARD = '/leaderboard/create'
export const EDIT_LEADERBOARD = '/leaderboard/update'
export const DELETE_LEADERBOARD = '/leaderboard/delete'

// CURRENCY APIENDPOINTS
export const GET_CURRENCY = '/currencies/get'
export const ADD_CURRENCY = '/currencies/add'
export const EDIT_CURRENCY = '/currencies/edit'
// export const DELETE_CURRENCY = '/currencies/delete'

// ITEMS APIENDPOINTS
export const GET_ITEMS = "/items/get"
export const ADD_ITEMS = "/items/add"
export const EDIT_ITEMS = "/items/update"

// BUNDLES APIENDPOINTS
export const GET_BUNDLES = '/bundle/get'
export const ADD_BUNDLES = '/bundle/create'
export const EDIT_BUNDLES = '/bundle/update'

// STORES APIENDPOINTS
export const GET_STORES = '/store/get'
export const ADD_STORES = '/store/create'
export const EDIT_STORES = '/store/update'

// Progression APIENDPOINTS
export const GET_PROGRESSION_MARKER = '/progression-marker/get'
export const ADD_PROGRESSION_MARKER = '/progression-marker/create'
export const EDIT_PROGRESSION_MARKER = '/progression-marker/edit'
export const GET_PROGRESSION_SYSTEM = '/level-system/get'
export const ADD_PROGRESSION_SYSTEM = '/level-system/create'
export const EDIT_PROGRESSION_SYSTEM = '/level-system/edit'

// Events
export const GET_DEFAULT_EVENTS = '/app-event/get/default'
export const GET_CUSTOM_EVENTS = '/app-event/get/custom'
export const CREATE_CUSTOM_EVENTS = '/app-event/add/custom'
export const EDIT_DEFAULT_EVENTS = '/app-event/edit/default'
export const EDIT_CUSTOM_EVENTS = '/app-event/edit/custom'
export const GET_PARAM_VALUES = '/app-event/get/params-value'

// Task
export const GET_TASK = '/task/get'
export const CREATE_TASK = '/task/create'
export const EDIT_TASK = '/task/edit'
export const DELETE_TASK = '/task/delete'

// Step Series
export const GET_STEP_SERIES = '/task-group/get'
export const CREATE_STEP_SERIES = '/task-group/create'
export const EDIT_STEP_SERIES = '/task-group/edit'
export const DELETE_STEP_SERIES = '/task-group/delete'

// Players
export const GET_PLAYERS = '/user/get'
export const GET_PLAYERS_DETAILS = '/user/details'
export const GET_CUSTOM_PLAYER = '/user/signup-custom'
export const GET_USER_WALLET = '/wallet/user-wallet'
export const CREATE_PLAYER_PAYOUT = '/wallet/payout'
export const GET_GRANT_PM = '/progression/get'
export const CREATE_GRANT_PM = '/progression/create'
export const EDIT_PLAYERS_PROFILE = '/user/update-profile'
export const CHANGE_MEMBER_PASSWORD = '/member/update-password'
export const RESET_PLAYER_PASSWORD = '/user/reset-password'

// Competition
export const GET_COMPETITION = '/competitions/get'
export const GET_COMPETITION_RESULT = '/competition/get-competition-leaderboard-details'
export const CREATE_COMPETITION = '/competitions/create'
export const EDIT_COMPETITION = '/competitions/update'
export const DELETE_COMPETITION = '/competition/delete'

//Live-Ops
export const GET_LIVE_OPS_CATEGORY ='/live-ops/get-category'
export const CREATE_LIVE_OPS_CATEGORY ='/live-ops/create-category'
export const EDIT_SCHEDULE_COMPETITION ='/live-ops/reschedule'
export const SCHEDULE_LIVE_OPS ='/live-ops/schedule'
export const RESCHEDULE_LIVE_OPS ='/live-ops/reschedule'
export const GET_SCHEDULE_COMPETITION ='/live-ops/get-liveops'

//Calendar
export const GET_CALENDAR ='/live-ops/get'

export const CREATE_LIVE_OPS_ACHIEVEMENT = '/task/schedule'
export const CREATE_LIVE_OPS_TASK = '/task/schedule'
export const CREATE_LIVE_OPS_TASK_GROUP = '/task-group/schedule'
export const STOP_LIVE_OPS = '/live-ops/cancel'
export const STOP_TASK = '/task/stop'
export const STOP_TASK_GROUP = '/task-group/stop'
export const PAUSE_PLAY_LIVE_OPS = '/live-ops/pause'
export const GET_LIVE_OPS_RESULT = '/live-ops/get-results'
// APP SETTINGS
export const GET_APP_ACCEPTED_MEMBERS = '/member/get'
export const GET_APP_INVITED__MEMBERS = '/member/invite/get'
export const SEND_INVITE = '/member/invite/send'

// APP APIENDPOINTS
export const GET_API_ANALYTICS = '/analytics/usage/api'
export const GET_EVENT_ANALYTICS = '/analytics/usage/event'
export const GET_API_HISTORY = '/analytics/usage/history/api'
export const GET_EVENT_HISTORY = '/analytics/usage/history/event'
export const GET_USAGE_OVERVIEW = '/analytics/usage/overview'
export const GET_APPS = '/app/get'
export const GET_APP_OVERVIEW = '/app/overview'
export const ADD_APPS = '/app/add'
export const EDIT_APPS = '/app/edit'
export const EDIT_PERMISSION = '/member/access/update'
export const EDIT_INVITED_PERMISSION = '/member/invite/update-access'
export const DELETE_MEMBER = '/member/delete'
export const ADD_API_KEY = '/app/api-key/create'

// Organisation Profile
export const GET_ORG_PROFILE = '/organisation/get'
export const EDIT_ORG_PROFILE = '/organisation/update'
export const GET_USER_PROFILE = '/member/get-details'
export const EDIT_USER_PROFILE = '/member/update-details'

// Documents
export const GET_DOCUMENTS = '/documents/get'
export const CREATE_DOCUMENTS = '/documents/add'
export const EDIT_DOCUMENTS = '/documents/update'

// Plan and Billing
export const GET_PLANS = '/get-plans'
export const GET_PRICE_BOOK = '/get-pricebooks'
export const GET_INVOICE = '/get-billing-history'
export const GET_ADD_ONS = '/get-addons'
export const BUY_ADD_ONS = '/buy/add-ons'
export const BUY_NEW_PLAN = '/buy/plan'
export const VERIFY_BILLING = '/organisation/get-billing-details'
export const UPDATE_ORGANISATION = '/organisation/update'
export const GET_MY_PLAN = '/organisation/get-my-plans'
export const GET_PAYMENT = '/get-cards'
export const EDIT_PAYMENT_METHOD = '/organisation/update-card'
export const DOWNLOAD_INVOICE = '/organisation/get-invoice'