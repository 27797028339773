import React, {useState, useEffect} from "react";
import cn from "classnames";
import styles from './config.module.sass'
import TextInput from "../../../../TextInput";

const Config = ({getSetter, levelsDetail, onClose}) => {

    const [levels, setLevels] = useState()
    const [baseValue, setBaseValue] = useState()
    const [multiplyer, setMultiplyer] = useState()
    const [showWarning, setShowWarning] = useState(false)

    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
    };

    const onLevelChange= (e) => {
        setLevels(parseInt(e.target.value))
    }

    const onBaseLevel= (e) => {
        setBaseValue(parseInt(e.target.value))
    }

    const onMultiplyer= (e) => {
        setMultiplyer(parseFloat(e.target.value))
    }

    const GenerateLevels = () => {
        if(levelsDetail.length == 0 ||(levelsDetail[0].name == '' && (levelsDetail[0].value == "" || levelsDetail[0].value == null)  && levelsDetail[0].rewardDetails.length == 0)){
            Generate()
        }else{
            setShowWarning(true)
        }
    }

    const Generate = () => {
        let data = {
            levels : levels, 
            baseValue : baseValue, 
            multiplyer : multiplyer
        }
        getSetter(data)
        onClose();
    }

  return (
    <>
        <div className={styles.configure}>
            <div className={styles.configureContainer}>
                <div className={styles.configHeading}>
                    <div className={styles.headingDash}></div>
                    <div className={styles.heading}>{showWarning == false ? "Config Formula" : 'Warning' }</div>
                </div>
                {showWarning == false &&
                    <>
                        <div className={styles.configGroup}>
                            <TextInput type="number" onWheelCapture={e => {e.currentTarget.blur()}} label="Number of Levels" placeHolder="Enter no of levels" value={levels} onChange={(e)=>{onLevelChange(e)}} onKeyDown={handleKeyDown}/>
                        </div>

                        <div className={styles.configGroup}>
                            <TextInput type="number" onWheelCapture={e => {e.currentTarget.blur()}} step="1" label="Base Value for Level 1" placeHolder="Base Value for Level" value={baseValue} onChange={(e)=>{onBaseLevel(e)}} onKeyDown={handleKeyDown}/>
                        </div>

                        <div className={styles.configGroup}>
                            <TextInput type="number" onWheelCapture={e => {e.currentTarget.blur()}} label="Multiplier" placeHolder="Multiplier" value={multiplyer} onChange={(e)=>{onMultiplyer(e)}} />
                        </div>

                        <div className={styles.configGroup}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={GenerateLevels}>Generate</button>
                        </div>
                    </>
                }
                
                {showWarning == true && 
                    <div className={styles.warning}>
                        <div className={styles.warningContent}>
                            <p className={styles.content}>This action will overwrite current progression system.</p>
                        </div>

                        <div className={styles.btnGroup}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={Generate}>Continue</button>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={()=>setShowWarning(false)}>Cancel</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    </>
  )
}

export default Config