import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './matchesFilter.module.sass'
import Icon from "../../../Icon";
import GenreDropdown from '../../../../components/MultiselectDropDown/GenreDropdown'
import PlatformDropdown from '../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../components/MultiselectDropDown/LocationDropdown'
import axios from "axios";
import { BASE_URL } from "../../../../resources/APIEndpoints";
import Form from "../../../Form";
import Dropdown from '../../../../components/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaDataAction } from "../../../../redux/action/common/commonAction";
import { Range, getTrackBackground } from "react-range";
import Radio from "../../../Radio";
import Checkbox from "../../../Checkbox";
import { getAllMatchesAction } from "../../../../redux/action/builds/matches/matchesAction";
import { useParams } from "react-router-dom";
import { getAllGamesAction } from "../../../../redux/action/builds/games/gamesAction";

const STEP = 1;
const MIN = 0;
const MAX = 500;

const MatchFilterModal = ({className, action, filters, onClose}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()

    let filterList = [{id: 1, name : 'Games'}, {id: 2, name : 'Match Format'}, {id: 3, name : 'Player Range'}, {id: 4, name : 'Match Result Criteria'}, {id: 5, name : 'Has Leaderboard'},{id:6, name: 'Leaderboard Ranking'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)

    const [matchFormatOption, setMatchFormatOption] = useState()
    const [matchOutcomeOption, setMatchOutcomeOption] = useState()
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    // const [minPlayer, setMinPlayer] = useState([0, 100]);
    // const [maxPlayer, setMaxPlayer] = useState([0, 100]);

    const getMetaData = () => {
      dispatch(getAllMetaDataAction())
    }
  
    let isMetaDataLoading = useSelector((state) => {
      return state.common.isLoading
    })
  
    let allMetaData = useSelector((state) => {
      return state.common.metaData
    })
  
    useEffect(() => {
      getMetaData()
    }, [])
    
    useEffect(()=>{ 
      configureMetaData()
    }, [allMetaData])
  
    const configureMetaData = () => {
      setMatchFormatOption(allMetaData.matchFormatIds && allMetaData.matchFormatIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.matchFormatName == 'single_player' ? 'Single Player' : obj.matchFormatName == 'multi_player' ? 'Multiplayer' : obj.matchFormatName == 'multi_player_team' ? 'Multiplayer Team' : ''),
        };
      }))
      setMatchOutcomeOption(allMetaData.matchOutcomeIds && allMetaData.matchOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.matchOutcomeName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      setLeaderboardOutcomeOption(allMetaData.leaderboardOutcomeIds && allMetaData.leaderboardOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
    }

    const saveFilter = () => {
      action()
      onClose()
    }

    const resetFilter = () => {
      filters.setGameId([])
      filters.setLeaderboard(null)
      filters.setMinPlayer([0,0])
      filters.setMaxPlayer([0,0])
      filters.setMatchFormat([])
      filters.setMatchOutcone([])
      filters.setLeaderboardRanking([])
      let matches = {
        projectId : appid,
        // Pagination
        offset: 0,
        limit: 8,
      }
      dispatch(getAllMatchesAction(matches))
      onClose()
    }

  const [filterGames, setFilterGames] = useState('')
  const [filterMatchFormat, setFilterMatchFormat] = useState('')
  const [filterMatchResult, setFilterMatchResult] = useState('')
  const [filterLeaderboard, setFilterLeaderboard] = useState('')

  const onGameId = (e, id) => {
    if(e.target.checked){
      filters.setGameId([...filters.gameId, id])
    }else{
      filters.setGameId(filters.gameId.filter(item => item !== id))
    }
  }

  const onGameFormat = (e, id) => {
    if(e.target.checked){
      filters.setMatchFormat([...filters.matchFormat, id])
    }else{
      filters.setMatchFormat(filters.matchFormat.filter(item => item !== id))
    }
  }

  const onMatchOutcome = (e, name) => {
      if(e.target.checked){
        filters.setMatchOutcone([...filters.matchOutcome, name])
      }else{
        filters.setMatchOutcone(filters.matchOutcome.filter(item => item !== name))
      }
  }

  const onMatchLeaderboard = (e, name) => {
      if(e.target.checked){
        filters.setLeaderboardRanking([...filters.leaderboardRanking, name])
      }else{
        filters.setLeaderboardRanking(filters.leaderboardRanking.filter(item => item !== name))
      }
  }

  const getAllGames = () => {
    let games = {
      projectId : appid,
      isDefault:false,
      offset: 0,
      limit: 20
    }
    dispatch(getAllGamesAction(games))
  }

  useEffect(() => {
    getAllGames()
  }, [])

  let games = useSelector((state) => {
    return state.games.games
  })

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index===activeIndex})} key={index}onClick={() => setActiveIndex(index)}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Games' && filters.gameId.length > 0 && <span className={styles.countPill}>{filters.gameId.length}</span>}
                                                    {e.name == 'Has Leaderboard' && filters.leaderboard != null && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Match Format' && filters.matchFormat.length > 0 && <span className={styles.countPill}>{filters.matchFormat.length}</span>}
                                                    {e.name == 'Match Result Criteria' && filters.matchOutcome.length > 0 && <span className={styles.countPill}>{filters.matchOutcome.length}</span>}
                                                    {e.name == 'Player Range' && (filters.minPlayer[1] > 0 || filters.maxPlayer[1] > 0) && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Leaderboard Ranking' && filters.leaderboardRanking.length > 0 && <span className={styles.countPill}>{filters.leaderboardRanking.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='16'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                    <>
                                       <Form className={styles.form} placeholder="Search Games" type="text" name="search" icon="search" onChange={(e) => {setFilterGames(e.target.value)}}/>
                                        <div className={styles.filterCheckbox}>
                                            {games && games.filter(option => option.name && option.name.toLowerCase().includes(filterGames.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.gameId.includes(x.id)} onChange={(e)=>{onGameId(e, x.id)}}/>
                                                )
                                            })}
                                        </div>
                                    </>
                                    }
                                    {activeIndex == 1 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Match Format" type="text" name="search" icon="search" onChange={(e) => {setFilterMatchFormat(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {matchFormatOption && matchFormatOption.filter(option => option.name && option.name.toLowerCase().includes(filterMatchFormat.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.matchFormat.includes(x.id)} onChange={(e)=>{onGameFormat(e, x.id)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }
                                    {activeIndex == 2 && 
                                      <>
                                        <div className={styles.item}>
                                          <div className={styles.label}>Min Player Range</div>
                                          <Range values={filters.minPlayer} step={STEP} min={MIN} max={MAX} onChange={(values) => {filters.setMinPlayer(values)}}
                                            renderTrack={({ props, children }) => (
                                              <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}
                                                style={{...props.style,width: "100%",paddingTop: 20}}>
                                                <div ref={props.ref}
                                                  style={{
                                                    height: "4px",
                                                    width: "100%",
                                                    borderRadius: "2px",
                                                    background: getTrackBackground({
                                                      values: filters.minPlayer,
                                                      colors: ["#EFEFEF", "#2A85FF", "#EFEFEF"],
                                                      min: MIN,
                                                      max: MAX,
                                                    }),
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  {children}
                                                </div>
                                              </div>
                                            )}
                                            renderThumb={({ index, props, isDragged }) => (
                                              <div
                                                {...props}
                                                style={{
                                                  ...props.style,
                                                  height: "16px",
                                                  width: "16px",
                                                  borderRadius: "50%",
                                                  backgroundColor: "#FFF",
                                                  border: "2px solid #2A85FF",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  boxShadow: "inset 0px 2px 2px #FFFFFF",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "calc(100% + 5px)",
                                                    color: "#fff",
                                                    fontWeight: "600",
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    fontFamily: "Inter",
                                                    padding: "4px 8px",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#272B30",
                                                  }}
                                                >
                                                  {filters.minPlayer[index].toFixed(0)}
                                                </div>
                                              </div>
                                            )}
                                          />
                                      </div>

                                      <div className={styles.item}>
                                      <div className={styles.label}>Max Player Range</div>
                                      <Range values={filters.maxPlayer} step={STEP} min={MIN} max={MAX} onChange={(values) => {filters.setMaxPlayer(values)}}
                                        renderTrack={({ props, children }) => (
                                          <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}
                                            style={{...props.style,width: "100%",paddingTop: 20}}>
                                            <div ref={props.ref}
                                              style={{
                                                height: "4px",
                                                width: "100%",
                                                borderRadius: "2px",
                                                background: getTrackBackground({
                                                  values: filters.maxPlayer,
                                                  colors: ["#EFEFEF", "#2A85FF", "#EFEFEF"],
                                                  min: MIN,
                                                  max: MAX,
                                                }),
                                                alignSelf: "center",
                                              }}
                                            >
                                              {children}
                                            </div>
                                          </div>
                                        )}
                                        renderThumb={({ index, props, isDragged }) => (
                                          <div
                                            {...props}
                                            style={{
                                              ...props.style,
                                              height: "16px",
                                              width: "16px",
                                              borderRadius: "50%",
                                              backgroundColor: "#FFF",
                                              border: "2px solid #2A85FF",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              boxShadow: "inset 0px 2px 2px #FFFFFF",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "absolute",
                                                bottom: "calc(100% + 5px)",
                                                color: "#fff",
                                                fontWeight: "600",
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                fontFamily: "Inter",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                backgroundColor: "#272B30",
                                              }}
                                            >
                                              {filters.maxPlayer[index].toFixed(0)}
                                            </div>
                                          </div>
                                        )}
                                      />
                                      </div>
                                    </>
                                    }
                                    {activeIndex == 3 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Match Outcome" type="text" name="search" icon="search" onChange={(e) => {setFilterMatchResult(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {matchOutcomeOption && matchOutcomeOption.filter(option => option.name && option.name.toLowerCase().includes(filterMatchResult.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.matchOutcome.includes(x.id)} onChange={(e)=>{onMatchOutcome(e, x.id)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }
                                    {activeIndex == 4 && 
                                       <>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                              <span>Has Leaderboard?</span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} name="leaderboard" content="Yes" value={filters.leaderboard != null ? filters.leaderboard : null} onChange={() => {filters.setLeaderboard(true)}}/>
                                                <Radio className={styles.radio} name="leaderboard" content="No" value={filters.leaderboard != null ? !filters.leaderboard : null} onChange={() => {filters.setLeaderboard(false)}}/>
                                            </div>
                                        </div>
                                       </>
                                    }
                                    {activeIndex == 5 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Leaderboard Outcome" type="text" name="search" icon="search" onChange={(e) => {setFilterLeaderboard(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {leaderboardOutcomeOption && leaderboardOutcomeOption.filter(option => option.name && option.name.toLowerCase().includes(filterLeaderboard.toLowerCase())).map((x, i) => {
                                              return(
                                                <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.leaderboardRanking.includes(x.id)} onChange={(e)=>{onMatchLeaderboard(e, x.id)}}/>
                                              )
                                            })}
                                        </div>
                                      </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn(styles.button, "button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MatchFilterModal