import { BASE_URL , LOGIN, SIGNUP, GET_EMAIL_VERIFICATION ,SEND_EMAIL_VERIFICATION, GET_RESET_PASSWORD_LINK, RESET_PASSWORD, ACCEPT_INVITE, GET_ALL_ORG} from '../../resources/APIEndpoints';
import axios from 'axios';
import customAxios from '../../utils/interceptor';

function AuthService() {
    this.login = (body) => axios.post(BASE_URL + LOGIN, body);
    this.SignUp = (body) => axios.post(BASE_URL + SIGNUP, body)
    this.getverifyEmail = (body) => axios.post(BASE_URL + GET_EMAIL_VERIFICATION, body)
    this.sendverifyEmail = (body) => axios.post(BASE_URL + SEND_EMAIL_VERIFICATION , body)
    this.resetPasswordLink = (body) => axios.post(BASE_URL + GET_RESET_PASSWORD_LINK , body)
    this.resetPassword = (body) => axios.post(BASE_URL + RESET_PASSWORD , body)
    this.acceptInvitedMember = (body) => axios.post(BASE_URL + ACCEPT_INVITE, body)
    this.getAllOrganisation = (body) => customAxios.post(BASE_URL + GET_ALL_ORG, body)
}

export default new AuthService();
