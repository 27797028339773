import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './progressionFilter.module.sass'
import Icon from "../../../Icon";
import GenreDropdown from '../../../../components/MultiselectDropDown/GenreDropdown'
import PlatformDropdown from '../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../components/MultiselectDropDown/LocationDropdown'
import axios from "axios";
import { BASE_URL } from "../../../../resources/APIEndpoints";
import Form from "../../../Form";
import Dropdown from '../../../../components/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaDataAction } from "../../../../redux/action/common/commonAction";
import { Range, getTrackBackground } from "react-range";
import Radio from "../../../Radio";
import Checkbox from "../../../Checkbox";
import { useParams } from "react-router-dom";
import { getAllProgressionSystemAction } from "../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction";

const ProgressionFilterModal = ({className, filters, action, onClose}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()

    let filterList = [{id: 1, name : 'PS Parameter'}, {id: 2, name : 'PS Type'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)

    const parameterOption = [{id: 1, name: 'Progression Marker'}, {id: 2, name: "Statistic"}]
    const psTypeOption = [{id: 1, name: 'Linear'}, {id: 2, name: "Non Linear"}]

    const [filterParameter, setFilterParameter] = useState('')
    const [filterPSType, setFilterPSType] = useState('')

    const onPSType = (e, name) => {
        if(e.target.checked){
            filters.setPSType([...filters.psType, name])
        }else{
            filters.setPSType(filters.psType.filter(item => item !== name))
        }
    }

    const onParameter = (e, name) => {
        if(e.target.checked){
            filters.setParameter([...filters.parameter, name])
        }else{
            filters.setParameter(filters.parameter.filter(item => item !== name))
        }
    }

    const saveFilter = () => {
        action()
        onClose()
    }

    const resetFilter = () => {
        filters.setParameter([])
        filters.setPSType([])
        let getAllPS = {
            projectId: appid,
            offset: 0,
            limit: 8,
        }
        dispatch(getAllProgressionSystemAction(getAllPS))
        onClose()
    }

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index === activeIndex})} key={index} onClick={() => {setActiveIndex(index)}}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name === 'PS Parameter' && filters.parameter.length > 0 && <span className={styles.countPill}>{filters.parameter.length}</span>}
                                                    {e.name === 'PS Type' && filters.psType.length > 0 && <span className={styles.countPill}>{filters.psType.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='16'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Parameter" type="text" name="search" icon="search" onChange={(e) => {setFilterParameter(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {parameterOption && parameterOption.filter(option => option.name && option.name.toLowerCase().includes(filterParameter.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox className={styles.checkbox} key={i} toggle={true} reverse={true} content={x.name} value={filters.parameter.includes(x.name)} onChange={(e)=>{onParameter(e, x.name)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }
                                    {activeIndex == 1 && 
                                       <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search PS Type" type="text" name="search" icon="search" onChange={(e) => {setFilterPSType(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {psTypeOption && psTypeOption.filter(option => option.name && option.name.toLowerCase().includes(filterPSType.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox className={styles.checkbox} key={i} toggle={true} reverse={true} content={x.name} value={filters.psType.includes(x.name)} onChange={(e)=>{onPSType(e, x.name)}}/>
                                                )
                                            })}
                                        </div>
                                       </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn(styles.button, "button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProgressionFilterModal