import React, { useEffect, useState } from "react";
import styles from './billing.module.sass'
import Plans from "../Plans";
import BillingCycle from "../BillingCycle";
import Payment from "../Payments";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loaders/LoaderProgress";
import { getAddOnsAction, getAllPaymentAction, getInvoiceAction, getMyPlanAction, getPriceBookAction } from "../../../../redux/action/organisation/Billing/billingAction";
import OrganizationHeader from '../../../../components/Header/OrganizationHeader'
import Overview from "./Overview";
import TopUp from "./TopUp";
import { useNavigate } from "react-router-dom";
import AddOn from './AddOn'

const BillingOverview = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currency, setCurrency] = useState(null)
    const [priceId, setPriceId] = useState(null)

    const getMyPlan = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== null && customerId !== undefined){
            let userDetail = {
                externalCustomerId: JSON.parse(customerId)
            }
            dispatch(getMyPlanAction(userDetail))
        }
    }

    const getAllPayments = () => {
        let customerId = sessionStorage.getItem('customerId')
        if(customerId !== null && customerId !== undefined){
            let customer = {
                customerId: JSON.parse(customerId)
            }
            dispatch(getAllPaymentAction(customer))
        }
    }

    let {myPlan, payment, addOns, price, isLoadingPlans, isLoadingPayments, isLoadingAddOns} = useSelector((state) => state.billing)

    useEffect(() => {
        getMyPlan()
        getAllPayments()
    }, [])

    const getAllPriceBook = () => {
        let priceBook = {}
        dispatch(getPriceBookAction(priceBook))
    }

    const getSelectedPriceBook = () => {
        let priceBook = {
            id: priceId
        }
        dispatch(getPriceBookAction(priceBook))
    }

    const findSelectedPriceBook = () => {
        if(price && price !== null && Array.isArray(price)){
            if(orgProfile && orgProfile.location && orgProfile.location !== null){
                if(orgProfile.location === 'India'){
                    let priceBookId = price && price.filter(x => x.name === 'India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Rupee')
                }else{
                    let priceBookId = price && price.filter(x => x.name === 'Outside India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Dollar')
                }
            }
        }
    }

    let {orgProfile} = useSelector(state => state.organisation)

    useEffect(() => {
        getAllPriceBook()
    }, [])

    useEffect(() => {
        findSelectedPriceBook()
    }, [price])

    useEffect(() => {
        if(priceId !== null){
            getSelectedPriceBook()
        }
    }, [priceId])

    if(isLoadingPlans || isLoadingPayments){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    return(
        <>
            <OrganizationHeader title='Billings' />
            <div className={styles.wrapper}>
                <Overview plan={myPlan} payments={payment} currency={currency} />
            </div>
        </>
    )

}

export default BillingOverview