import React, {useContext, useEffect, useState} from "react";
import styles from './userdetail.module.sass'
import PersonalDetail from '../Personal'
import OrganizationDetail from '../Organization'
import PlatformDetail from '../Platform'
import GoalDetail from '../Goals'
import Icon from "../../../../components/Icon";
import {ReactComponent as SpecterLogo} from '../../../../media/images/Specter_Logo.svg'
import {ReactComponent as YellowFrame} from '../../../../media/images/yellow_frame.svg'
import {ReactComponent as BlueFrame} from '../../../../media/images/blue_frame.svg'
import {ReactComponent as FillCross} from '../../../../media/images/fill_cross.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as HalfElipse} from '../../../../media/images/half_ellipse.svg'
import TextInput from "../../../../components/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {getGoalsAction, getPlatformAction} from "../../../../redux/action/common/commonAction";
import GlobalStateContext from "../../../../components/Context/GlobalStates/GlobalStateContext";
import Lottie from "lottie-react";
import Loader from '../../../../media/lottieAnimation/Specter_Animation.json'
import BillingDetails from "../BillingDetails";

const UserDetails = ({setShowLoader, setLoaderType}) => {

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNo: '',
        password: '',
        solo: false,
        typeId: 2,
        name: '',
        teamSize: '',
        location: '',
        isGST: true,
        billingAddress: {
            streetLineOne: '',
            streetLineTwo: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            gstNo: '',
        },
        goals: [],
        platform: [],
        googleId: null,
        timezone:'',
        gstTreatment: null
    })
    const [activeIndex, setActiveIndex] = useState(0)
    const [showPersonal, setShowPersonal] = useState(true)
    const globalStates = useContext(GlobalStateContext)

    const dispatch = useDispatch()

    const getGoals = () => {
        let goals = {}
        dispatch(getGoalsAction(goals))
    }

    const getPlatforms = () => {
        let platforms = {}
        dispatch(getPlatformAction(platforms))
    }

    const getActiveIndex = () => {
        const AI = localStorage.getItem('signup')
        console.log(AI == 'google')
        if(AI == 'google'){
            setActiveIndex(1)
            setShowPersonal(false)
            let googleUserName = localStorage.getItem('userName')
            let name = JSON.parse(googleUserName).split(" ")
            setUser((user) => ({...user, firstName : name[0], lastName : name[1]}))
        }else{
            setActiveIndex(0)
            setShowPersonal(true)
        }
    }

    let goalsOption = useSelector((state) => {
        return state.common.goals
    })

    let platformOption = useSelector((state) => {
        return state.common.platforms
    })


    const configGoal = () => {
        if (goalsOption) {
            setUser((user) => ({
                ...user,
                goals: goalsOption && goalsOption.map((goals, i) => ({name: goals, active: false}))
            }))
        }
    }

    const configPlatform = () => {
        if (platformOption) {
            setUser((user) => ({
                ...user,
                platform: platformOption && platformOption.map((platform, i) => ({
                    name: (platform == 'IOS' ? platform.charAt(0).toLowerCase() + platform.slice(1) : platform),
                    active: false
                }))
            }))
        }
    }

    useEffect(() => {
        configGoal()
    }, [goalsOption])

    useEffect(() => {
        configPlatform()
    }, [platformOption])

    useEffect(() => {
        getUserEmail()
        getGoals()
        getPlatforms()
        getGoogleId()
        getActiveIndex()
    }, [])

    const getUserEmail = () => {
        let userEmail = JSON.parse(localStorage.getItem('userEmail'))
        setUser((user) => ({...user, email: userEmail}))
    }

    const getGoogleId = () => {
        let userGoogleId = JSON.parse(localStorage.getItem('userGoogleId'))
        setUser((user) => ({...user, googleId: userGoogleId}))
    }

    const style = {
        display :'block',
        margin : '0px auto',
        width : '160px',
        height : '160px'
    }

    return (
        <>
            {globalStates.showLoader &&
                <div className={styles.loaderContainer}>
                    <div className={styles.loaderWrapper}>
                        <div className={styles.loader}>
                            <Lottie animationData={Loader} loop={true} autoPlay={true} style={style} />
                        </div>
                        <div className={styles.loaderTitle}>
                            {globalStates.loaderType === 0 && <div className={styles.title}>Crafting your Specter experience...<br />Welcome aboard!</div>}
                        </div>
                    </div>
                </div>
            }
            {!globalStates.showLoader &&
            <div className={styles.userDetail}>
                <div className={styles.userDetail_Container}>
                    <div className={styles.userDetail_Container_Outer}>
                        <div className={styles.logo}>
                            <SpecterLogo/>
                        </div>
                        <div className={styles.userDetail_Container_Inner}>
                            <div className={styles.formContainer}>
                                <div className={styles.fillCrossSvg}><FillCross/></div>
                                <div className={styles.fullHollowCircleSvg}><FullHollowCircle/></div>
                                <div className={styles.FullCircleSvg}><FullCircle/></div>
                                <div className={styles.HalfElipseSvg}><HalfElipse/></div>
                                <div className={styles.fullCircleSvgForm}><FullCircle/></div>
                                {showPersonal && activeIndex == 0 &&
                                    <PersonalDetail user={user} setUser={setUser} onSave={setActiveIndex}/>
                                }
                                {activeIndex == 1 &&
                                    <OrganizationDetail showPersonal={showPersonal} user={user} setUser={setUser} onSave={setActiveIndex}/>
                                }
                                {activeIndex == 2 &&
                                    <BillingDetails showPersonal={showPersonal} user={user} setUser={setUser} onSave={setActiveIndex}/>
                                }
                                {activeIndex == 3 &&
                                    <GoalDetail user={user} setUser={setUser} onSave={setActiveIndex}/>
                                }
                                {activeIndex == 4 &&
                                    <PlatformDetail setShowLoader={setShowLoader} setLoaderType={setLoaderType} user={user} setUser={setUser} onSave={setActiveIndex}/>
                                }
                            
                            </div>

                            <div className={styles.grapicDesign}>
                                {activeIndex == 0 && <YellowFrame/>}
                                {activeIndex == 1 && <BlueFrame/>}
                                {activeIndex == 2 && <YellowFrame/>}
                                {activeIndex == 3 && <BlueFrame/>}
                                {activeIndex == 4 && <YellowFrame/>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default UserDetails