import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { acceptInvitedMemberAction } from "../../../redux/action/authenticate/authenticationAction";
import styles from './invite.module.sass'
import cn from "classnames";

const AcceptInvite = ({className}) => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [member, setMember] = useState(null)
    const [organisation, setOrganisation] = useState(null)
    const [email, setEmail] = useState(null)
    const [name, setName] = useState(null)
    const [icon, setIcon] = useState(null)

    const searchParameter = () => {
        const searchParams = new URLSearchParams(location.search)
        const memberId = searchParams.get('memberId')
        setMember(memberId)
        const organisationId = searchParams.get('organisationId')
        setOrganisation(organisationId)
        const email = searchParams.get('email')
        setEmail(email)
        const name = searchParams.get('name')
        setName(name)
        const icon = searchParams.get('orgIconUrl')
        setIcon(icon)
    }

    const validateEmailAddress = (emailAddress) => {
        let atSymbol = emailAddress.indexOf("@");
        let dotSymbol = emailAddress.lastIndexOf(".");
        let spaceSymbol = emailAddress.indexOf(" ");
    
        if ((atSymbol != -1) &&
            (atSymbol != 0) &&
            (dotSymbol != -1) &&
            (dotSymbol != 0) &&
            (dotSymbol > atSymbol + 1) &&
            (emailAddress.length > dotSymbol + 1) &&
            (spaceSymbol == -1)) {
            return true;
        } else {
            return false;
        }
    }

    const acceptInvitedMember = () => {
        let members = {
            memberId: member,
            organisationId: organisation,
            email: email
        }
        dispatch(acceptInvitedMemberAction(members, navigate))
    }

    useEffect(() => {
        if((member && member !== null) && (email && email !== null && validateEmailAddress(email)) && (organisation && organisation !== null)){
            acceptInvitedMember()
        }
    }, [location, member, organisation, email])

    useEffect(() => {
        searchParameter()
    }, [location])

    return(
        <>
            <div className={styles.wrapper}>
                {/* <div className={styles.accept}>
                    <div className={styles.container}>
                        <div className={styles.logo}>
                            <img src={icon} alt='logo' />
                        </div>
                        <div className={styles.detail}>
                            <span className={styles.name}>{name}</span> <br /> has Invited you to join thier organisation.
                        </div>
                        <div className={styles.group}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={acceptInvitedMember}>Accept</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default AcceptInvite