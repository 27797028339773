import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './confirm.module.sass'

const ConfirmModal = ({className,onProceed, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>Warning!</div>
          <div className={styles.warningContent}>
            <p>Unsaved changes will be lost. Are you sure you want to proceed?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-danger-small-full')} onClick={onProceed}>Proceed</button>
        </div>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-cancel-small-full')} onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal