import React, { useState } from "react";
import cn from "classnames";
import Icon from "../../../../../Icon";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
// import EyeIcon from '../../../../../../media/images/icons/eye.png'
import ViewIcon from '../../../../../../media/images/file.png'
import { downloadInvoiceAction } from "../../../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch } from "react-redux";

const Control = ({className, id, appid, status, name, item}) => {

  const dispatch = useDispatch()

  const downloadInvoice = async(item) => {
    try {
      let binaryData = await dispatch(downloadInvoiceAction(item.invoiceId))
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Invoice_${item.invoiceNumber}(${item.invoiceDate}).pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove()
    }catch (err) {
      console.log(err)
    } finally{
      console.log('downloaded')
    }
  }

  const viewInvoice = async(item) => {
    try {
      let binaryData = await dispatch(downloadInvoiceAction(item.invoiceId))
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank')
    }catch (err) {
      console.log(err)
    } finally{

    }
  }

  const actions = [
    {
      icon: 'eye',
      action: ()=> viewInvoice(item)
    },
    {
      icon: 'download',
      action: ()=> downloadInvoice(item)
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions && actions.map((x, index) => {
            return(
              <Link className={styles.button} key={index} onClick={x.action}>
                {x.icon === 'eye' && <img src={ViewIcon} width={16} height={16} className={styles.icon} />}
                {x.icon !== 'eye' && <Icon name={x.icon} size='20' />}
              </Link>
            )
          }
        )}
      </div>
    </>
  );
};

export default Control;
