import React, {useEffect, useState, useRef, useContext} from 'react'
import SessionLoger from './sessionLog'

const TerminalInfo = ({ className, onClose, setIsChanged}) => {

    return (
        <>
            <SessionLoger className={className}/>
        </>
    )
}



export default TerminalInfo