import React, {useState, useEffect, useContext} from "react";
import cn from "classnames";
import styles from './navHeader.module.sass'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../Icon";
import EnvironmentSwitchTooltip from "../CustomTooltips/EnvironmentSwitchTooltip";
import { ReactComponent as  ServerIcon} from "../../media/images/icons/server_icon.svg";
import ProfilePlaceHolder from '../../media/images/placeholders/Profile.png'
import { useSelector } from "react-redux";
import ActivityFeed from "../Header/Activity"
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
import { ReactComponent as  Home} from "../../media/images/icons/home.svg";
import { ReactComponent as  Apps} from "../../media/images/icons/apps.svg";
import { ReactComponent as  Settings} from "../../media/images/icons/settings.svg";
import { ReactComponent as  AccessManager} from "../../media/images/icons/access_manager.svg";
import { ReactComponent as  Sandbox} from "../../media/images/icons/sandbox.svg";
import WorkspaceTooltip from '../../components/CustomTooltips/WorkspaceTooltip'

const NavHeader = ({className, wide, onOpen, type, isChanged, setIsChanged, loaderType, setLoaderType, appName, setAppName, showLoader, setShowLoader}) => {
    
    const {appid} = useParams()
    const Navigate = useNavigate();
    const { pathname } = useLocation();

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [lnk, setLnk] = useState()
    const [environmentOption, setEnvironmentOption] = useState([{id : 1, name : 'Production', active: (localStorage.getItem('environment') =='Production' ? true :false) },{id : 2, name : 'Quality Assurance', active: (localStorage.getItem('environment') =='Staging' ? true :false)}, {id : 3, name : 'Development', active: (localStorage.getItem('environment') =='Development' ? true :false)}])
    const [environment, setEnvironment] = useState()
    const [workSpace, setWorkSpace] = useState([])
  
    const handleClick = () => {
      onOpen();
      setVisible(false);
    };
  
    const navigation = [
      {
        title: "Currencies",
        url: `/currency/${appid}`,
      },
      {
        title: "Items",
        url: `/item/${appid}`,
      },
      {
        title: "Bundles",
        url: `/bundle/${appid}`,
      },
      {
        title: "Stores",
        url: `/store/${appid}`,
      }
    ]
  
    const progressionNavigation = [
      {
        title: "Progression Markers",
        url: `/progression-marker/${appid}`,
      },
      {
        title: "Progression Systems",
        url: `/progression-system/${appid}`,
      },
    ]
  
    const engageNavigation = [
      {
        title: "Tasks",
        url: `/task/${appid}`,
      },
      {
        title: "Missions",
        url: `/mission/${appid}`,
      },
      {
        title: "Step Series",
        url: `/step-series/${appid}`,
      }
    ]
  
    const competitionNavigation = [
      {
        title: 'Tournaments',
        url : `/competition-tournament/${appid}`
      },
      {
        title : 'Instant-Battles',
        url : `/competition-instant-battle/${appid}`
      }
    ]
    
    const appSettingsNavigation = [
      {
        title : 'Global App Information',
        url : `/app-information/${appid}`
      },
      {
        title: "Media",
        url: `/app-media/${appid}`,
      },
      {
        title : 'Members',
        url : `/app-accepted-members/${appid}`
      },
      {
        title : 'API',
        url : `/app-api/${appid}`
      }
    ]
  
    const LiveOpsNavigation = [
      {
        title : 'Competitions',
        url : `/competition-schedule/${appid}`
      },
      {
        title : 'Calendar',
        url : `/calendar/${appid}`
      }
    ]
  
    const ProfileSettingsNavigation = [
      {
        title : 'Organization',
        url : `/settings-organization-profile`
      },
      {
        title : 'Member',
        url : `/settings-member-profile`
      }
    ]
  
    const NavTo = (x) => {
      if (isChanged==true) {
        setLnk(x)
        setVisibleModal(true);
      } else {
        Navigate(x)
      }
    }

    const loaderConfigure = (loaderType) => {
      setLoaderType(loaderType)
      setShowLoader(true)
      setTimeout(() => {
          setShowLoader(false)
      }, 4000)
    }

  const getWorkSpace = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    setWorkSpace(user.organisations)
  }
  
    const getLocalStorageData = () => {
      const env = localStorage.getItem('environment')
      setEnvironment(env == 'Staging' ? 'Quality Assurance' : env)
    }

    useEffect(()=>{
      getLocalStorageData()
      getWorkSpace()
    }, [])

    const resetEnvironment = () => {
      localStorage.setItem('environment', 'Development')
      loaderConfigure(5)
    }

    const refreshPage = () => {
      localStorage.setItem('environment', "Development")
      loaderConfigure(4)
    }

    const [organisation, setOrganisation] = useState({name : '', iconUrl : ''})

    let OrgProfile = useSelector((state) => {
      return state.organisation.orgProfile
    })

    const configOrganisation = () => {
      if(OrgProfile){
        setOrganisation(org => ({...org, name : OrgProfile.name, iconUrl : OrgProfile.defaultLogoUrl}))
      }
    }
  
    useEffect(()=>{
      configOrganisation()
    }, [OrgProfile])
    

    return (
        <>
            <header className={cn(styles.header, className, {[styles.wide]: wide})}>
              {type === 'Organization' &&
                  <>
                    <WorkspaceTooltip wide={true} data={workSpace} setLoaderType={setLoaderType} showLoader={showLoader} setShowLoader={setShowLoader}>
                      <div className={styles.organization}>
                        <div className={styles.organizationLogo}>
                          {organisation.iconUrl !== null && <img src={organisation.iconUrl} alt="orgLogo" />}
                          {organisation.iconUrl === null && <div className={styles.backGround}><div className={styles.txt}>P</div></div>}
                        </div>
                        <div className={styles.organizationName}>
                          <div className={styles.orgText}>
                            <span className={styles.name}>{organisation.name}</span>
                          </div>
                          <div className={styles.arrow}>
                            <Icon name='arrow-next' size='16' />
                          </div>
                        </div>
                      </div>
                    </WorkspaceTooltip>
                    {window.location.pathname.includes('/home') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Home /></div>
                          <div className={styles.title}>Home</div>
                        </div>
                      </>
                    }
                    {window.location.pathname.includes('/apps') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Apps /></div>
                          <div className={styles.title}>Apps</div>
                        </div>
                      </>
                    }
                    {window.location.pathname.includes('/settings') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Settings /></div>
                          <div className={styles.title}>Settings</div>
                        </div>
                      </>
                    }
                    {(window.location.pathname.includes('/billing-overview') || window.location.pathname.includes('/billing-settings') || window.location.pathname.includes('/invoices') || window.location.pathname.includes('/add-on') || window.location.pathname.includes('/top-up')) &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Icon name='payment' size='16' className={styles.payment} fill='#6F767E' /></div>
                          <div className={styles.title}>Billing</div>
                        </div>
                      </>
                    }
                    {window.location.pathname.includes('/notificationFeed') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Icon name="notification" size='16' className={styles.payment} fill='#6F767E' /></div>
                          <div className={styles.title}>Notification</div>
                        </div>
                      </>
                    }
                    {window.location.pathname.includes('/access-manager') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><AccessManager /></div>
                          <div className={styles.title}>People</div>
                        </div>
                      </>
                    }
                    {window.location.pathname.includes('/usage-analytics') &&
                      <>
                        <div className={styles.breadcrumbs}>
                          <div className={styles.icon}><Icon name='pie-chart' size='16' className={styles.payment} fill='#6F767E' /></div>
                          <div className={styles.title}>Usage</div>
                        </div>
                      </>
                    }
                  </>
              }
              {(type === 'App' && !window.location.pathname.includes('/home')) &&
                <>
                  <div className={styles.environmentWrapper}>
                    <EnvironmentSwitchTooltip type='detail' setLoaderType={setLoaderType} showLoader={showLoader} setShowLoader={setShowLoader} appName={appName} data={environmentOption} environment={environment}>
                      <div className={styles.environment}>
                        <ServerIcon className={environment == 'Production' ? styles.productionLogo : environment == 'Quality Assurance' ? styles.stagingLogo : styles.developLogo} />
                      </div>
                      <div className={styles.appDetail}>
                        <p className={styles.appName}>{appName}</p>
                        <p className={styles.env}>{environment}</p>
                      </div>
                      <div className={styles.downArrow}>
                        <Icon name='arrow-down' size='12' />
                      </div>
                    </EnvironmentSwitchTooltip>
                    <div className={styles.options}>
                      <ActivityFeed />
                      <div className={styles.setting} onClick={() => Navigate(`/app-information/${appid}`)}>
                        <Icon className={styles.settingIcon} name='setting' size='16' />
                      </div>
                    </div>
                  </div>
                </>
              }
              {type == 'AppSettings' &&
                <div className={styles.appSetting}>
                  <div className={styles.app}>
                    <div className={styles.name}>{appName} Settings</div>
                  </div>
                  <div className={styles.back} onClick={() => Navigate(`/app-overview/${appid}`)}>
                    <Icon name='arrow-next' size='16' />
                    <div className={styles.title}>Back To Overview</div>
                  </div>
                </div>
              }
            </header> 
        </>
    );
}

export default NavHeader