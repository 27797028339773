import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import cn from "classnames";
import Card from "../../../components/Card";
import styles from './media.module.sass'
import FileUpload from '../../../components/File/FileUpload'
import { useParams } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Toast";
import { BASE_URL } from "../../../resources/APIEndpoints";
import FileCardComponent from '../../../components/AppSettingComponent/MediaNewFrame'
import { useDispatch, useSelector } from "react-redux";
import { addMediaAction, getAllMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import EmptyStates from "../../../components/EmptyStates";
import Loader from "../../../components/Loaders/LoaderProgress";
import GlobalStateContext from "../../../components/Context/GlobalStates/GlobalStateContext";
import { useContext } from "react";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Form from '../../../components/Form';
import {ReactComponent as Folder} from '../../../media/images/icons/folder 2.svg'
import Icon from "../../../components/Icon"
import {ReactComponent as ArchiveSvg} from '../../../media/images/icons/archive.svg'
import { Link } from "react-router-dom";
import app,{increaseUnreadCountForAllUsers} from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const Media = ({className}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    const Tabs = ["Images", "Videos"]
    const [activeIndex, setActiveIndex] = useState(0)

    const [contents, setContents] = useState([])

    const getMedia = () => {
        let media = {
            projectId : appid,
            // category : null,
            filters : {
                categories: [
                    "icons",
                    "videos",
                    "misc"
                ]},
            offset: ((page-1)*limit),
            limit: limit,
        }
        dispatch(getAllMediaAction(media))
    }

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(12)
    const [count, setCount] = useState()
    const [search,setSearch]=useState('')

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
            }
        }else{

        }
    }
    
    useEffect(() => {
      getUserAccess()
      getCategoryInfo()
    }, [])

    useEffect(() => {
        onSearch()
    }, [page,activeIndex,search])

    let media = useSelector((state) => {
        return state.media.media
    })
    
    let isLoading = useSelector((state) => {
        return state.media.isLoading
    })

    const [showLoader, setShowLoader] = useState(false)

    const handlePageChange = (event, value)  => {
        setPage(value)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const db = getFirestore(app);

    const save = async() => {
        let fileContent = [...contents]
        const forEachPromise = new Promise((resolve) => {
            setShowLoader(true)
            fileContent && fileContent.forEach(async(e, i) => {
                const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${e.contentType}`)
                let fileUrl = res.data.getUrl
                fileContent[i].url = fileUrl
                var config = {method: 'put', url: res.data.postUrl,data : e.data}
                axios(config)
                .then(function (response) {
                    e.target.value = ''
                })
                .catch(function (error) {

                })
                // If it's the last iteration, resolve the Promise
                if(i === fileContent.length - 1) {
                    resolve();
                }
            })   
        });
        
        await forEachPromise;

        let files = {
            projectId : appid,
            contents : fileContent && fileContent.map(obj => {const { data, ...rest } = obj; return rest;})
        }
        try {
            await dispatch(addMediaAction(files, setContents, appid, activeIndex, setShowLoader))
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id:appid,
                resource: "MEDIA",
                // resource_id: appid, // Use the ID from response
                description: `Media added successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(files),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
        } catch (error) {
            
        }
    }
    
    
    const Cancel = () => {
        setContents([])
    }
    
    const getSearchMedia = async(search) => {
        let getMediaData = {
            search : search,
            projectId : appid,
            filters : {
                categories: [
                    "icons",
                    "videos",
                    "misc"
                ]},
            // category : null,
            offset: ((page-1)*limit),
            limit: limit,
        }
        await dispatch(getAllMediaAction(getMediaData))
    }
    
    
    const onSearch = () => {
        if(search == ''){
            getMedia()
        }else{
            getSearchMedia(search)
        }
    }
    
    const [folders,setFolders] =useState([
        {
            name:"icons",
            link:`/media/folders/icons/${appid}`,
            title:"Icons",
            count:0
        },
        // {
        //     name:"assets",
        //     link:`/media/folders/assets/${appid}`,
        //     title:"Assets",
        //     count:0
        // },
        {
            name:"videos",
            link:`/media/folders/videos/${appid}`,
            title:"Videos",
            count:0
        },
        // {
        //     name:"bundles",
        //     link:`/media/folders/bundles/${appid}`,
        //     title:"Bundles",
        //     count:0
        // },
        {
            name:"misc",
            link:`/media/folders/misc/${appid}`,
            title:"Misc",
            count:0
        }
    ])
    
    const getCategoryInfo = async()=>{
        let categories = {
            projectId : appid,
            categories: [
                "icons",
                "videos",
                "misc"
            ]
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        axios.post(`${BASE_URL}/media/get/category-info`, categories, headers)
        .then(res=>{
            
            res?.data?.data&& res?.data?.data.map((e,i)=>{
                folders.map((x,y)=>{
                    if (x.name==e.category) {
                        setFolders((prev)=>{
                            const tmpfld=[...prev];
                            tmpfld[y].count=e.mediaCount
                            return tmpfld
                        })
                    }
                })
                
            })
        })
    }

    if(isLoading){
        return(
        <>
            <div className={styles.loader}>
            <   Loader />
            </div>
        </>
        )
    }

    return(
        <>
            <ToastContainer />
            <Card className={styles.card} classCardHead={styles.head} title="Folders" classTitle={cn("title-yellow", styles.title)} >
                <div className={styles.folderList}>
                    {
                        folders.map((e,i)=>{
                            return  (
                                <>
                                <div className={styles.folderGroup}>
                                <Link to={e.link}>
                                    <div className={styles.folderDetails}>
                                        <Folder/>
                                        <div className={styles.titleName}>
                                            <div>{e.title}</div>
                                            <div style={{color:"#2A85FF"}}>{`${e.count} Files`}</div>
                                        </div>

                                    </div>
                                </Link>
                                </div>
                            </>)
                           

                        })
                    }
                </div>
            </Card>
            <Card className={styles.card} classCardHead={styles.head} title="Recent" classTitle={cn("title-blue", styles.title)} >
                <div style={{height:"1px",background:"#EFEFEF",margin:"12px"}}></div>
                <div style={{marginBottom:"8px",display:"flex"}}>
                    <div style={{marginLeft:"auto"}}><Form size='sm' className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {setSearch(e.target.value)}}/></div>
                </div>
                <div className={styles.wrapper}> 

                    {media?.mediaDetails && media?.totalCount == 0 &&
                        (activeIndex == 0 ?
                            <>
                                <EmptyStates type='Media'/>
                            </>
                        : activeIndex == 1 ? 
                            <>
                                <EmptyStates type='Media'/>
                            </> 
                        : '')
                    }

                    {media?.mediaDetails && media?.mediaDetails.length > 0 && isLoading == false &&
                        <div className={styles.filePreview}>
                            <div className={styles.fileWrapper}>
                                {media?.mediaDetails && media?.mediaDetails.map((x, index) => (
                                    <FileCardComponent access={userAccess} type={x.type == 'image' ? 'Images' :x.type == 'video' ? 'Videos':'others'} className={styles.product} item={x} key={index} released />
                                ))}
                            </div>
                        </div>
                    }
                {!isLoading && media?.mediaDetails&& media?.mediaDetails?.length > 0 &&
                    <div className={styles.pagination}>
                        <Stack spacing={2}>
                        <Pagination renderItem={(item) => (
                            <PaginationItem
                            {...item}
                            sx={{
                                '&.MuiPaginationItem-root': {
                                minWidth: '28px',
                                height: '28px',
                                fontSize: '12px'
                                },
                                '&.Mui-selected': {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                                },
                                '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                                }
                            }}
                            />
                        )} siblingCount={1} page={page} count={Math.ceil(media?.totalCount/limit)} color="primary" onChange={handlePageChange} />
                        </Stack>
                    </div>
                }
                </div> 
            </Card>    
        </>
    )
}

export default Media