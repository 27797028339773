import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import styles from './delete.module.sass'

const DeleteMedia = ({className,onProceed, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>Warning!</div>
          <div className={styles.warningContent}>
            <p>Are you sure you want to delete <span className={styles.currencyName}></span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-danger-small-full')} onClick={()=>{onProceed();onClose()}}>Yes</button>
        </div>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-cancel-small-full')} onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default DeleteMedia