import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getMembers.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import MembersTableLayout from '../../../../components/AppSettingComponent/MemberComponent/MemberTableComponent'
import { acceptedUser } from '../mockData/acceptedUser'
// import { invitedUser } from '../mockData/invitedUser'
import Control from './Control'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import Loader from '../../../../components/Loaders/LoaderProgress'
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import EmptyState from '../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'
import Navigation from '../../../../components/Header/SecondaryNavigation';
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton';

const Tabs = ["Accepted", "Invited"];

const GetMembers = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])

  const getAllAppMembers = () => {
    let getMember = {
      organisationId : localStorage.getItem('orgId'),
      projectId : appid,
    }
    dispatch(getAcceptedMembersAction(getMember, configureSkeleton))
  }

  useEffect(() =>{
    getAllAppMembers()
  }, [page])

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  let acceptedMembers = useSelector((state) => {
    return state.members.acceptedMembers
  })

  let invitedMembers = useSelector((state) => {
    return state.members.invitedMembers
  })

  let isLoading = useSelector((state)=>{
    return state.members.isLoading
  })

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <ToastContainer />
        <Navigation type='Accepted Members' />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.wrapper}>
            {showSkeleton && acceptedMembers?.length === 0 && 
              <SkeletonEmptyState />
            }
            {showSkeleton && acceptedMembers?.length > 0 && 
              <SkeletonTable rows={acceptedMembers.length} column={3} controls={1} type={['recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[36, 100, 100, 36]} colHeight={[36, 15, 25, 36]} />
            }
            {!showSkeleton && acceptedMembers?.length === 0 &&
              <EmptyState memberAction='add' access={userAccess} usedFor='app' type="Members" />
            }
            {!showSkeleton && acceptedMembers?.length > 0 &&
              <MembersTableLayout to='accepted' items={acceptedMembers} access={userAccess} title="Last edited" />
            }
          </div>
        </Card>        
    </>
  )

}

export default GetMembers