import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './appmodal.module.sass'
import Modal from '../../../../Modals/ModalComponent'
import Card from '../../../../Card'
import Icon from "../../../../Icon";
import TextInput from '../../../../TextInput'
import {createAppAction, getallAppAction} from '../../../../../redux/action/apps/appsAction'
import { useNavigate } from 'react-router-dom'
import {ReactComponent as AddSvgIcon} from '../../../../../media/images/icons/modal_add_btn.svg'
import AppPlaceHolder from '../../../../../media/images/placeholders/App.png'
import Toast from '../../../../Toast'
import { toast } from 'react-toastify';
import Dropdown from '../../../../Dropdown/SearchDropdown'
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction'
import { BASE_URL } from '../../../../../resources/APIEndpoints'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const AddAppModal = ({className, onClose, setApp, setShowLoader, setLoaderType}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef()

  const [appName, setAppName] = useState('')
  const [appIcon, setAppIcon] = useState(null)
  const [category, setCategory] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [categoriesOption, setCategoriesOption] = useState([])
  const [categoryErr, setCategoryErr] = useState(false)

  const placeholder = AppPlaceHolder
  const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

  const uploadLogo = async(e) => {
    if(e.target.files[0]) {
        setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
    } 
    var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setAppIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
          toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
  }

  // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
      var BASE64_MARKER = ';base64,';
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));
              
      for(var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i);
      }
      return array;
  }
        
  // To Convert file into base64 format
  const convertbase64 = (file) => {
      return new Promise((resolve, err)=>{
      const fileReader = new FileReader();
      fileReader.onload = (eve) =>{
          resolve(fileReader.result)
      }
      fileReader.readAsDataURL(file)
      })
  }

  const Cancel = () => {
      setAppIcon(null)
      setLogo({src: placeholder , alt: 'Upload an Image'})
      ref.current.value = ''
  }

  const onChangeAppName = (e) => {
    if(e.target.value == ''){
      setAppName('')
    }else{
      setAppName(e.target.value)
      errors.nameErr = ''
    }
  }


  const [errors, setErrors] = useState({nameErr : '', categoryIdErr : ''})

  const validation = (data) => {
    let error = {}
    let isValid = true
    if(data.name == '' || data.name == null){
      error.nameErr = 'Name is required'
      isValid = false
    }

    if(data.projectCategoryMasterId == '' || data.projectCategoryMasterId == null){
      error.categoryIdErr = 'Category is required'
      isValid = false
    }

    setErrors(error)
    if(isValid){
      return true
    }

  }

  const db = getFirestore(app);

  const save = async () => {
      let saveApp = {
        name: appName,
        iconUrl: appIcon,
        projectCategoryMasterId : categoryId,
        organisationId : localStorage.getItem('orgId')
      }
      if(validation(saveApp)){
        try {
          // Await the response from createGameAction
          await dispatch(createAppAction(saveApp, navigate, setApp, setShowLoader, setLoaderType))
          // Access the response data (e.g., game ID)
              // const IdFromResponse = res?.data?.data?.id;

              const user = JSON.parse(localStorage.getItem('user'))
              const memberID = user?.id
              const memberName = user?.name
              const orgId = (localStorage.getItem('orgId'))

              // Log activity in Firestore
              const activityLog = {
                  timestamp: new Date().toISOString(),
                  action_type: "CREATE",
                  // app_id:appid,
                  resource: "APP",
                  // resource_id: IdFromResponse, // Use the ID from response
                  description: `App '${appName}' created successfully`,
                  quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                  operation_status: "SUCCESS",
                  activity_feed: false,
                  priority: "LOW",
                  performed_by_id: memberID,
                  organization_id: orgId,
                  performed_by:memberName,
                  request_json:JSON.stringify(saveApp),
              };
              await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers();
              console.log("Activity logged successfully");
          
      } catch (error) {
          console.error("Error creating game or logging activity:", error);
      }
        onClose()
      }
  }


  useEffect(()=> {
    dispatch(getAllMetaDataAction())
  }, [])

  let categoryOptions = useSelector((state) => {
    return state.common.metaData.projectCategoryMasterIds
  })

  const configCategories = () => {
    if(categoryOptions && categoryOptions.length > 0){
      setCategoriesOption(categoryOptions && categoryOptions.map(e => ({ id : e.id, name : e.categoryName})))
    }
  }

  useEffect(()=>{
    configCategories()
  }, [categoryOptions])

  return (
    <div className={styles.addAppModal}>
      <div className={styles.addAppModalBody}>
        <div className={styles.modalHeading}>
          <div className={styles.block}></div><span>Global App Information</span>
        </div>
        <div className={styles.field}>
          <TextInput type='text' label='Name*' errorMessage={errors.nameErr} placeholder='Enter App Name' onChange={(e)=>{onChangeAppName(e)}}/>
        </div>
        <div className={styles.field}>
          <Dropdown label='Category*' name='category' errorMessage={errors.categoryIdErr} placeholder='Select Category' options={categoriesOption} value={category} setValue={setCategory} setId={setCategoryId} error={setCategoryErr} />
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
        <button className={cn(styles.button, 'button-cancel-small')} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}

export default AddAppModal