import React, { useEffect, useState } from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import JsonTextInput from '../../../../../components/JsonTextInput'
import Radio from '../../../../../components/Radio'
import styles from './playerData.module.sass'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import Dropdown from '../../../../../components/Dropdown/PlayerDataDropdown'
import { JsonEditor as Editor } from 'jsoneditor-react18';
import Icon from '../../../../../components/Icon'
import Control from '../../../../../components/MetaInput'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { editPlayerProfile, editPlayerProfileAction } from '../../../../../redux/action/engage/Players/PlayersAction'
// import 'jsoneditor-react/es/editor.min.css';
import 'jsoneditor-react18/es/editor.min.css';
import cn from 'classnames'
import {ReactComponent as AddIcon} from '../../../../../media/images/icons/add_meta.svg'
const permissionOption = [{id : 1, name : "Private"}, {id : 2, name : "Public"}]

const PlayerData = ({data,editActivity, access, activeIndex, setIsChanged, className}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()

    const [playerData, setPlayerData] = useState(null)

    const savePlayerData = async() => {
        const metaDataList = [...playerData]
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = {value : metaDataList[i].value, permission : metaDataList[i].permission}
        }

        let playerProfileData = {
            projectId : appid,
            userId : id,
            playerData : finalMetaData
        }
        try {
            await dispatch(editPlayerProfileAction(playerProfileData, appid, id , null, activeIndex))
            editActivity(playerProfileData)
            setIsChanged(false)
            
        } catch (error) {
            
        }
    }

    const configurPlayerData = () => {   
        if(data && data.length > 0){
            if(JSON.stringify(data[0].playerData) != '{}' && data[0].playerData != null && data[0].playerData != undefined){
                let meta = []
                data[0].playerData && Object.keys(data[0].playerData).forEach(e => {
                    meta.push({key : e, value : data[0].playerData[e].value, permission : data[0].playerData[e].permission })
                })
                setPlayerData(meta)
            }else{
                setPlayerData([{key : null, value : null, permission : null}])
            }
            //setPlayerData((data[0].playerData != null && data[0].playerData != undefined && data[0].playerData.length > 0) ? data[0].playerData : [{key : null, value : {}, permission : null}])
        }
    }

    useEffect(()=>{
        configurPlayerData()
    }, [data, activeIndex])
    

    const onChangePlayerKey = (e, index) => {
        let playerDataList = [...playerData]
        playerDataList[index].key = e.target.value
        setPlayerData(playerDataList)
    }

    const onChangePlayerPermission = (e, index) => {
        let playerDataList = [...playerData]
        playerDataList[index].permission = e.target.value
        setPlayerData(playerDataList)
    }

    const addPlayerData = () => {
        let playerDataList = [...playerData]
        playerDataList.push({key : null, value : null, permission : null})
        setPlayerData(playerDataList)
    }

    const removePlayerData = (index) => {
        let removeField = playerData
        let playerDataList = removeField.filter((e,i,c)=>{
            return i != index
        })
        setPlayerData(playerDataList)
    }

    const setchange = () => {  
        let meta = []
        if(data && data.length > 0){
            if(data[0].playerData != null && data[0].playerData != undefined){
                data[0].playerData && Object.keys(data[0].playerData).forEach(e => {
                    meta.push({key : e, value : data[0].playerData[e].value, permission : data[0].playerData[e].permission })
                })
            }else{
                meta=[{key : null, value : null, permission : null}]
            }
        }
        if(data[0]&&(JSON.stringify(meta) == JSON.stringify(playerData))){
            setIsChanged(false)
        }else {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        
    },[playerData])

    useEffect(()=>{
        setchange()
    },[playerData])

    return (
        <>
            <Card title="Player Data" classTitle="title-red">
                <div className={styles.overview}>
                    <div className={styles.tableWrapper}>
                        <div className={styles.group}>
                            <span className={styles.playerData}>Player Data {!access && <span onClick={addPlayerData} className={styles.playerDataIcon}><AddIcon /></span>}</span>
                        </div>
                        {playerData && playerData.length > 0 &&
                            <div className={styles.group}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.fields}>Value (JSON)</div>
                                <div className={styles.field}>Permission</div>
                            </div>
                        }
                        <div className={styles.group}>
                            {playerData && playerData.map((e, index) => {
                                return(
                                    <>
                                        <TextInput disabled={access} key={index} className={styles.field} type='text' value={e.key} onChange={(e)=>{onChangePlayerKey(e, index)}} />
                                        <Control disabled={access} key={index} className={styles.fields} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={playerData} setValue={setPlayerData} index={index}/>
                                        <div className={styles.field}>
                                            <Dropdown disabled={access} key={index} placeholder='Select Permission' options={permissionOption} value={e.permission} data={playerData} setData={setPlayerData} index={index}/>
                                        </div>
                                        {!access && <span onClick={()=>{removePlayerData(index)}}><Remove key={index} className={styles.remove} /></span>}
                                    </>
                                )
                            })
                            }
                        </div>
                        <div className={styles.group}>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button disabled={access} className={cn(styles.button, 'button-save-small')} onClick={savePlayerData}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default PlayerData