import React, { useContext, useEffect, useRef, useState } from 'react'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import styles from './profile.module.sass'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import Toast from '../../../../../../components/Toast'
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar'
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction'
import { ReactComponent as AddButtonIcon } from '../../../../../../media/images/icons/add_button.svg'
import ProfilePlaceholder from '../../../../../../media/images/placeholders/Profile.png'
import SearchDropdown from '../../../../../../components/Dropdown/SearchDropdown'
import {ReactComponent as Internet} from '../../../../../../media/images/icons/internet.svg'
import { getAllMetaDataAction, getRegionsAction } from '../../../../../../redux/action/common/commonAction'
import { editOrgProfileAction } from '../../../../../../redux/action/organisation/ProfileAction/profileAction'
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'
import { updateOrganisationAction } from '../../../../../../redux/action/organisation/Billing/billingAction'
import cn from 'classnames'
import _ from 'lodash'

const Address = ({data, className, locationOption}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const globalStates = useContext(GlobalStateContext)

    const [orgAddress, setOrgAddress] = useState({street: null, country: null, state: null, city: null, zip: null, gstNo: null})
    const [streetOne, setStreetOne] = useState(null)
    const [streetTwo, setStreetTwo] = useState(null)
    const [location, setLocation] = useState('')
    const [locationId, setLocationId] = useState(null)
    const [regionOption, setRegionOptions] = useState([])
    const [region, setRegion] = useState('')
    const [regionId, setRegionId] = useState(null)

    const configureAddress = () => {
        if(data && data?.billingAddress){
            let address = data?.billingAddress
            setOrgAddress(org => ({...org, street: (address?.street ? address?.street : ''), country: (address?.country ? address?.country : ''), state: (address?.state ? address?.state : ''), city: (address?.city ? address?.city : ''), zip: (address?.zip ? address?.zip : ''), gstNo: (data?.gstNo ? data?.gstNo  : null)}))
            setLocation(address?.country ? address?.country : '')
            setRegion(address?.state ? address?.state : '')
            const street = address.street;
            if(street){
                const firstCommaIndex = street.indexOf(',');
                if (firstCommaIndex === -1) {
                  // If no commas, assign all to addressPart1 and leave addressPart2 empty
                  setStreetOne(street.trim());
                  setStreetTwo('');
                } else {
                  // Find the second comma, only if the first comma exists
                  const secondCommaIndex = street.indexOf(',', firstCommaIndex + 1);
                  
                  if (secondCommaIndex === -1) {
                    // If there's only one comma, all goes to addressPart1 and addressPart2 remains empty
                    setStreetOne(street.trim());
                    setStreetTwo('');
                  } else {
                    // Split the string at the second comma
                    const addressPart1 = street.substring(0, secondCommaIndex).trim();
                    const addressPart2 = street.substring(secondCommaIndex + 1).trim();
                    setStreetOne(addressPart1);
                    setStreetTwo(addressPart2);
                  }
                }
            }
        }
    }

    const onChangeBillingAddress = (e, type) => {
        const {name, value} = e.target
        if(name === 'street'){
            // Update the corresponding input state
            if (type === 'streetOne') {
                setStreetOne(value);
                // Update orgAddress object by combining both parts
                updateOrgAddress(value, streetTwo)
            } else if (type === 'streetTwo') {
                setStreetTwo(value);
                // Update orgAddress object by combining both parts
                updateOrgAddress(streetOne, value)
            }
        }else{
            setOrgAddress(org => ({...org, [name]: value}))
        }
    }

    const updateOrgAddress = (part1, part2) => {
        if (part1 === '' && part2 === '') {
          // If both inputs are empty, set street to an empty string
          setOrgAddress((prevAddress) => ({
            ...prevAddress,
            street: '',
          }));
        } else {
          // Otherwise, join the parts with a comma
          setOrgAddress((prevAddress) => ({
            ...prevAddress,
            street: (part1 ? part1 : '') + (part1 && part2 ? ', ' : '') + (part2 ? part2 : ''),
          }));
        }
    }

    const onChangeLocation = (e) => {
        setOrgAddress(org => ({...org, country: e, state: '', city: ''}))
        setRegion('')
        setRegionId(null)
    }

    const onChangeRegion = (e) => {
        setOrgAddress(org => ({...org, state: e}))
    }

    const getRegions = () => {
        let country = {
            country : location,
            offset : 0,
            limit : 50
        }
        dispatch(getRegionsAction(country))
    }

    let regionOptions = useSelector((state) => {
        return state.common.region
    })

    const cancelChanges = () => {
        configureAddress()
    }

    useEffect(() => {
        configureAddress()
    }, [data])

    useEffect(() => {
        if(location !== null && location !== ''){
            getRegions()
            onChangeLocation(location)
        }
    }, [locationId])

    useEffect(() => {
        if(region !== null && region !== ''){
            onChangeRegion(region)
        }
    }, [regionId])

    const save = () => {
        let address = Object.fromEntries(Object.entries(orgAddress).filter(([_, v]) => v != null && v !== ''))
        let billingAddress = {
            billingAddress : address
        }
        billingAddress.organisationId = localStorage.getItem('orgId')
        dispatch(updateOrganisationAction(billingAddress, null, true))
    }

    const isObjectEmpty = (obj) => {
        for (let key in obj) {
            // Check if the key is not null or an empty string
            if (obj[key] !== null && obj[key] !== '') {
              return true; // Return true if we find any non-null, non-empty value
            }
        }
        return false
    }

    const hasObjectChanged = (obj1, obj2) => {
        for (const key in obj1) {
            // Check if the key is in both objects and if the value is different
            if(obj2[key] === 'location'){
                if(obj1.location !== location){
                    return true
                }
                return false
            }else if(obj2[key] === 'state'){
                if(obj1.state !== region){
                    return true
                }
                return false
            }else{
                if (_.has(obj2, key) && !_.isEqual(obj1[key], obj2[key])) {
                    return true; // Value has changed
                }
            }
          }
          return false
    }

    const [isChanged, setIsChanged] = useState(false)

    const checkChanges = () => {
        if(isObjectEmpty(orgAddress)){
            let isChanged = hasObjectChanged(data.billingAddress, orgAddress)
            setIsChanged(isChanged)
        }
    }

    useEffect(() => {
        checkChanges()
    }, [orgAddress, location, regionId])

    return (
        <>
            <ToastContainer />
            <div className={styles.wrapper}>
            <Card classTitle="title-purple">
                <div className={styles.overview}>
                    <div className={styles.cardHead}>
                        <p className={styles.title}>Address</p>
                        <p className={styles.info}>Your Organisation address and other information.</p>
                    </div>
                    <div className={styles.cardBody}>
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <SearchDropdown placeholder='Country' value={location} setValue={setLocation} setId={setLocationId} options={locationOption} />
                        </div>
                        <TextInput className={styles.field} position={`bottom`} type='text' name='zip' placeHolder='Zipcode' value={orgAddress.zip} onChange={(e) => onChangeBillingAddress(e)} />
                    </div>
                    <div className={styles.group}>
                        <TextInput placeHolder='Address 1' className={styles.block} name='street' position={`bottom`} value={streetOne} onChange={(e) => onChangeBillingAddress(e, 'streetOne')}/>
                    </div>
                    <div className={styles.group}>
                        <TextInput placeHolder='Address 2' className={styles.block} name='street' position={`bottom`} value={streetTwo} onChange={(e) => onChangeBillingAddress(e, 'streetTwo')}/>
                    </div>
                    <div className={styles.group}>
                        <div className={styles.field}>
                            <SearchDropdown placeholder='State' value={region} setValue={setRegion} setId={setRegionId} options={regionOptions}/>
                        </div>  
                        <TextInput className={styles.field} position={`bottom`} type='text' name='city' placeHolder='City' value={orgAddress.city} onChange={(e) => onChangeBillingAddress(e)}/>
                    </div>
                    <div className={styles.group}>
                        <TextInput placeHolder='GST No' position={`bottom`} className={styles.block} name='gstNo' value={orgAddress.gstNo} onChange={(e) => onChangeBillingAddress(e)}/>
                    </div>

                    <div className={styles.group}>
                        <div className={styles.cardAction}>
                            <div className={styles.cardSave}>
                                <button disabled={!isChanged} className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                            </div>
                            <div className={styles.cardCancel}>
                                <button disabled={!isChanged} className={cn(styles.button, 'button-cancel-small')} onClick={cancelChanges}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Card>
            </div>
        </>
    )
}

export default Address