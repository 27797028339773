import React from 'react'
// import CustomValueEditor from './AppInfo/index'
import TerminalInfo from './TerminalInfo'
import styles from './terminal.module.sass'
import { ToastContainer } from 'react-toastify'
import {QueryBuilder, add, ActionWithRulesProps} from 'react-querybuilder'
import { useState } from 'react'

const Terminal = ({isChanged, setIsChanged}) => {

  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
          <TerminalInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default Terminal