import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './event.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import { getAllMetaDataAction } from '../../../../redux/action/common/commonAction'
import { stopLiveOpsAction, stopTaskAction, stopTaskGroupAction } from '../../../../redux/action/engage/LiveOps/LiveOpsAction'
import app from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { editCustomEventAction, editDefaultEventAction } from '../../../../redux/action/builds/events/eventActions.js'

const EventModal = ({className, type, onClose, id, name, eventStatus}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const Stop = async () => {
    let events = {
        projectId: appid,
        id: id,
        isSubscribed: !eventStatus
    }
    if(type === 'Default Event'){
      dispatch(editDefaultEventAction(events, null, appid, null, true))
    }else{
      dispatch(editCustomEventAction(events, null, appid, null, true))
    }
    onClose()
  }

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeading}>
                  <span>{eventStatus ? 'Unsubscribe' : !eventStatus ? 'Subscribe' : null} to {type}</span>
                </div>
            </div>

            <div className={styles.modalBody}>
                <div className={styles.eventWarning}>Are you sure you want to {eventStatus ? 'unsubscribe' : !eventStatus ? 'subscribe' : null} to <span className={styles.name}>{name}</span>?</div>
            </div>

            <div className={styles.modalFooter}>
              <div className={styles.fields}>
                <button className={cn(styles.button, 'button-danger-small-full')} onClick={Stop}>Confirm</button>
              </div>
              <div className={styles.fields}>
                <button className={cn(styles.button, 'button-cancel-small-full')} onClick={onClose}>Cancel</button>
              </div>
            </div>

          </div>
        </div>
  )
}

export default EventModal