import React, { useState } from "react";
import cn from "classnames";
import styles from './dropdownButton.module.sass'
import { Link } from "react-router-dom";
import addIcon from '../../../../media/images/icons/light.png'
import Icon from "../../../Icon";
import OutsideClickHandler from "react-outside-click-handler";

const DropdownButton = ({className, children, exportOption, action, toggle, exportAction}) => {

    const [visible, setVisible] = useState(false)
    const [showOption, setShowOption] = useState(false)

    return(
        <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children}>
                        {children}
                        {toggle && 
                            <div className={cn(styles.dropdown, {[styles.toggle] : toggle})} onClick={() => setVisible(!visible)}>
                                <Icon name='arrow-next' size='16' />
                            </div>
                        }
                    </div>
                    {/* <div className={styles.tooltipContainer}>
                        {exportOption && exportOption.map((e, i) => {
                            return(
                                <>
                                    <div className={styles.text} onClick={e.name === 'Import' ? null : () => setShowOption(!showOption)}>
                                        {e.name}    
                                    </div>
                                    {showOption && e.name === 'Export' && <div className={styles.seprator}></div>}
                                    {showOption && e.options && e.options.map(x => (
                                        <div className={styles.text} onClick={() => exportAction(x.name)}>
                                            {x.name}
                                        </div>
                                    ))}
                                </>
                            )
                        })}
                    </div> */}
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default DropdownButton