import React, {useState, useEffect, useContext} from "react";
import cn from "classnames";
import styles from './navigation.module.sass'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../../Icon";
import { useSelector } from "react-redux";
import GlobalStateContext from "../../Context/GlobalStates/GlobalStateContext";

const NavHeader = ({className, title, wide, onOpen, type, isChanged, setIsChanged, loaderType, setLoaderType, appName, setAppName, showLoader, setShowLoader}) => {
    
    const {appid} = useParams()
    const Navigate = useNavigate();
    const {pathname} = useLocation();
    const globalStates = useContext(GlobalStateContext)
    const [show, setShow] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [navLinks, setNavLinks] = useState([])
    let links = []

    const configureLinks = (title) => {
        switch(title){
            case "Create Currency" : 
            case "Edit Currency" : 
            case "View Currency" :
            case "Create Item" : 
            case "Edit Item" : 
            case "View Item" :
            case "Create Bundle" : 
            case "Edit Bundle" : 
            case "View Bundle" :
            case "Create Store" : 
            case "Edit Store" : 
            case "View Store" :
                links = [
                    {
                        title: "Currencies",
                        url: `/currency/${appid}`,
                    },
                    {
                        title: "Items",
                        url: `/item/${appid}`,
                    },
                    {
                        title: "Bundles",
                        url: `/bundle/${appid}`,
                    },
                    {
                        title: "Stores",
                        url: `/store/${appid}`,
                    }
                ]
                break;
            case 'Create Progression Marker' :
            case 'Edit Progression Marker' :
            case 'View Progression Marker' :
            case 'Create Progression System' :
            case 'Edit Progression System' :
            case 'View Progression System' :
                links = [
                    {
                        title: "Progression Markers",
                        url: `/progression-marker/${appid}`,
                    },
                    {
                        title: "Progression Systems",
                        url: `/progression-system/${appid}`,
                    },
                ];
                break;
            case 'Create Custom Event' :
            case 'Edit Custom Event' :
            case 'View Custom Event' :
            case 'Edit Default Event' :
            case 'View Default Event' :
                links = [
                    {
                        title: "Default Events",
                        url: `/default-events/${appid}`,
                    },
                    {
                        title: "Custom Events",
                        url: `/custom-events/${appid}`,
                    }
                ];
                break;
            case 'Create Task' :
            case 'Edit Task' :
            case 'View Task' :
            case 'Create Mission' :
            case 'Edit Mission' :  
            case 'View Mission' :    
            case 'Create Step Series' :
            case 'Edit Step Series' :
            case 'View Step Series' :
                links = [
                    {
                      title: "Tasks",
                      url: `/task/${appid}`,
                    },
                    {
                      title: "Missions",
                      url: `/mission/${appid}`,
                    },
                    {
                      title: "Step Series",
                      url: `/step-series/${appid}`,
                    }
                ]
                break;
            case 'Create Tournament' :
            case 'Edit Tournament' :
            case 'View Tournament' :
            case 'Create Instant Battle' :
            case 'Edit Instant Battle' :
            case 'View Instant Battle' :
                links = [
                    {
                      title: 'Tournaments',
                      url : `/competition-tournament/${appid}`
                    },
                    {
                      title : 'Instant-Battles',
                      url : `/competition-instant-battle/${appid}`
                    }
                ]
                break;
            default :
            links = [
                {
                    title: title, 
                    url: null
                }
            ]
        }
        setNavLinks(links)
    }

    useEffect(() => {
        configureLinks(title)
    }, [title])

    const checkLinks = (str1, str2) => {
        if(str1){
            const parts = str2.toLowerCase().replace(/^(Edit|View|Create|Delete|Update)\s+/i, '').replace(/-/g, ' ').trim()
            return str1.replace(/-/g, ' ').includes(parts)
        }else{
            return true
        }
    }

    const onChangeAccessRead = () => {
        globalStates.setAccess(false)
        setToggle(false)
    }

    const onChangeAccessView = () => {
        globalStates.setAccess(true)
        setToggle(true)
    }

    useEffect(() => {
        getUserAccess()
    }, [])

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setToggle(globalStates.access)
            setShow(true)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setShow(appPermission[0].permissions.id === 1 ? false : true)
                setToggle(globalStates.access)
            }
        }else{

        }
    }

    return (
        <>
            <div className={cn(styles.header, className)}>
                <div className={styles.list}>
                    {navLinks && navLinks.map((x,index)=>{
                        return(
                            <div className={cn(styles.item, {[styles.active]: checkLinks(x.url, title)})} onClick={()=> Navigate(x?.url)} key={index}>
                                {x.title}
                            </div>
                        )
                    })}
                </div>

                {!(window.location.pathname.includes('/app-overview') || window.location.pathname.includes('/terminal') || window.location.pathname.includes('/env-information') || window.location.pathname.includes('/player-details') || type === 'AppSettings') &&
                    <div className={styles.warning}>
                        {(window.location.pathname.includes('/create') || window.location.pathname.includes('/edit') || window.location.pathname.includes('/view')) &&
                            <>
                                <div className={styles.label}>You're Currently in&nbsp;<span className={styles.text}>{window.location.pathname.includes('/create') ? 'Create' : window.location.pathname.includes('/edit') ? 'Edit' : window.location.pathname.includes('/view') ? <>{globalStates.access ? 'view-only' : 'edit'}</> : null}&nbsp;mode.</span></div>
                                {show && window.location.pathname.includes('/view') ?
                                    <>
                                        {toggle && <div className={styles.icon} onClick={onChangeAccessRead}><Icon name='edit' size='12'/></div>}
                                        {!toggle && <div className={styles.icon} onClick={onChangeAccessView}><Icon name='close' size='12'/></div>}
                                    </>
                                :
                                null
                                }
                            </>
                        }
                    </div>
                }
                
            </div> 
        </>
    );
}

export default NavHeader