import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams} from 'react-router-dom'
import styles from './delete.module.sass'
import { deleteMemberAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import app,{increaseUnreadCountForAllUsers} from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const DeleteMembers = ({className, onClose, id}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {appid} = useParams()

  const db = getFirestore(app);

  const onDelete = async () => {
    let member = {
      memberId: id,
      organisationId : localStorage.getItem('orgId')
    }
    try {
        await dispatch(deleteMemberAction(member))
        const user = JSON.parse(localStorage.getItem('user'))
        const memberID = user?.id
        const memberName = user?.name
        const orgId = (localStorage.getItem('orgId'))

        // Log activity in Firestore
        const activityLog = {
            timestamp: new Date().toISOString(),
            action_type: "ARCHIVE",
            // ...(appid&&{app_id:appid}),
            resource: "MEMBER",
            // resource_id: IdFromResponse, // Use the ID from response
            description: `Member archived successfully`,
            quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
            activity_feed: false,
            priority: "LOW",
            performed_by_id: memberID,
            organization_id: orgId,
            performed_by:memberName,
            request_json:JSON.stringify(member),
        };
        await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
        console.log("Activity logged successfully");
    } catch (error) {
        console.error("Error creating game or logging activity:", error);
    }
    onClose()
  }
  
  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>Warning!</div>
          <div className={styles.warningContent}>
              <p>Are you sure you want to delete <span className={styles.currencyName}></span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-danger-small-full')} onClick={onDelete}>Yes</button>
        </div>
        <div className={styles.fields}>
          <button className={cn(styles.button, 'button-cancel-small-full')} onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default DeleteMembers