import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import { Link, useParams } from "react-router-dom";
import Game from '../../../../../media/images/placeholders/Game.png'
import {ReactComponent as GoldMedal} from '../../../../../media/images/placeholders/Gold.svg'
import {ReactComponent as SilverMedal} from '../../../../../media/images/placeholders/Silver.svg'
import {ReactComponent as BronzeMedal} from '../../../../../media/images/placeholders/Bronze.svg'
import appPlaceHolder from '../../../../../media/images/placeholders/App.png'
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({type, item, value, onChange, to, setAppName, setShowLoader, setLoaderType, userType }) => {
    
    const {appid} = useParams()

    const refreshPage = (name,x) => {
        setAppName(name)
        localStorage.setItem('AppIcon', x.iconUrl)
        localStorage.setItem('App', name)
        localStorage.setItem('environment', "Development")
        setShowLoader(true)
        setLoaderType(4)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
    }

    return (
        <>
                <div className={styles.row}>
                    <div className={styles.col} onClick={()=> {refreshPage(item.name,item)}}>
                        {item.iconUrl !== undefined &&
                            <Link to={`/app-overview/${item.id}`}>
                                <div className={styles.player}>
                                    <div className={styles.playerInsigne}>
                                        <img src={item.iconUrl != null ? item.iconUrl : appPlaceHolder} alt={item.name} />
                                    </div>
                                    <div className={styles.playerDetails}>
                                        <div className={styles.userName}>{item.name != null ? item.name : '-'}</div>
                                        <div className={styles.playerId}>{item.id != null ? item.id : '-'}</div>
                                    </div>
                                </div>
                            </Link>
                        }
                    </div>
                    <div className={styles.col}>
                        {Object.keys(item).length !== 0 &&
                            <>
                                {item.active == true ? <div className={styles.price}>Active</div> : <div className={styles.price}>InActive</div>}
                            </>
                        }
                    </div>
                    <div className={styles.col}>{Object.keys(item).length !== 0 && '-'}</div>
                    <div className={styles.col}>{Object.keys(item).length !== 0 && <div>{moment(item.createdAt).format('DD/MM/YYYY')}</div>}</div>
                    <div className={styles.col}>
                        {userType === 'admin' && Object.keys(item).length !== 0 &&
                            <Control  usedFor='app' setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType} className={styles.control} id={item.id} name={item.name}/>
                        }
                    </div>
                </div>
            
        </>
    );
};

export default Row;
