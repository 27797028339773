import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './upgrade.module.sass'
import Card from "../../../../../../components/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { buyAddOnsAction, getAddOnsAction, getPriceBookAction } from "../../../../../../redux/action/organisation/Billing/billingAction";
import { useSelector } from "react-redux";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";
import OrganizationHeader from '../../../../../../components/Header/OrganizationHeader'

const Upgrade = ({className}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const [activeIndex, setActiveIndex] = useState(0)
    const [addOn, setAddOn] = useState([])
    const [currency, setCurrency] = useState(null)
    const [priceId, setPriceId] = useState(null)
    const [planCode, setPlanCode] = useState(null)
    const [subscriptionId, setSubscriptionId] = useState(null)
    const modes = [{name: 'Add-Ons'}, {name: 'Top-Ups'}]

    let {addOns, price, isLoadingAddOns} = useSelector((state) => state.billing)
    let {orgProfile} = useSelector(state => state.organisation)

    const getPlanCode = () => {
        const params = new URLSearchParams(location.search)
        const code = params.get('planCode')
        const type = params.get('type')
        const id = params.get('subscriptionId')
        setActiveIndex(parseInt(type))
        setSubscriptionId(id)
        setPlanCode(code)
    }

    const getAllAddOn = () => {
        dispatch(getAddOnsAction())
    }

    const getAllPriceBook = () => {
        let priceBook = {}
        dispatch(getPriceBookAction(priceBook))
    }

    const getSelectedPriceBook = () => {
        let priceBook = {
            id: priceId
        }
        dispatch(getPriceBookAction(priceBook))
    }

    const configureAddOns = () => {
        let updatedAddOns = addOns && addOns.map(x => {
            const matchObj = price?.pricebook_items && price?.pricebook_items.find(y => x.addonCode === y.product_code)
            return{
                ...x,
                price: matchObj ? matchObj.pricebook_rate : x.priceBrackets[0]?.price
            }
        })
        setAddOn(updatedAddOns && updatedAddOns.map(e => ({...e, quantity: 0, active: false, disabled: (e.name === 'TaskFlow Engine Automation Overage' ? true : false)})))
    }

    const findSelectedPriceBook = () => {
        if(price && price !== null && Array.isArray(price)){
            if(orgProfile && orgProfile.location && orgProfile.location !== null){
                if(orgProfile.location === 'India'){
                    let priceBookId = price && price.filter(x => x.name === 'India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Rupee')
                }else{
                    let priceBookId = price && price.filter(x => x.name === 'Outside India').map(y => y.pricebook_id)[0]
                    setPriceId(priceBookId)
                    setCurrency('Dollar')
                }
            }
        }
    }

    const onAddOns = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, active: !e.active, quantity: 1} : {...e}))
    }

    const onAdd = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: e.quantity + 1} : {...e, quantity: e.quantity}))
    }

    const onRemove = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: (e.quantity !== 1 ? e.quantity - 1 : 1)} : {...e, quantity: e.quantity}))
    }

    useEffect(() => {
        getAllAddOn()
        getAllPriceBook()
        getPlanCode()
    }, [])

    useEffect(() => {
        configureAddOns()
    }, [addOns, priceId])

    useEffect(() => {
        findSelectedPriceBook()
    }, [price])

    useEffect(() => {
        if(priceId !== null){
            getSelectedPriceBook()
        }
    }, [priceId])

    const [toggle, setToggle] = useState(false)

    const toggleAddon = (id) => {
        setAddOn(addOn && addOn.map(x => x.addonCode === id?.addonCode ? {...x, quantity: 1, active: !x.active} : {...x, disabled: (x.name !== 'TaskFlow Engine Automation Overage' ? false : toggle)}))
        setToggle(!toggle)
    }

    const buyAddOn = () => {
        let addonsDetails = {
            subscriptionId: subscriptionId,
            addonsDetails: addOn && addOn.filter(x => x.active).map(y => ({addonCode: y.addonCode, type: y.type, quantity: y.quantity, price: y.priceBrackets[0]?.price, addonDescription: 'add on'}))
        }
        dispatch(buyAddOnsAction(addonsDetails))
    }

    return(
        <>
            <OrganizationHeader title='Upgrade' />
            <div className={styles.container}>
                <Card className={styles.card} classCardHead={styles.head} classTitle={cn("title-purple", styles.title)}>
                    <div className={styles.wrapper}>
                        <div className={styles.cardHead}>
                            <p className={styles.title}>One-Time Add-Ons</p>
                            <p className={styles.info}>Add one time top up you need to manage and grow your business.</p>
                        </div>
                        
                        <div className={styles.table}>
                            <div className={styles.addOnsList}>
                                {addOn && addOn.filter(x => x.type === 'one_time').map(e => (
                                    <div className={cn(styles.addOn, {[styles.disabled]: e.disabled})}>
                                        <div className={styles.addOnDetail}>
                                            <div className={styles.detail}>
                                                <div className={styles.name}>{e.name}</div>
                                                <div className={styles.description}>{e.description}</div>
                                            </div>
                                            <div className={styles.checkbox}>
                                                <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                                            </div>
                                        </div>
                                        <div className={styles.addOnPurchase}>
                                            <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                                            {e.active &&
                                                <div className={styles.quantity}>
                                                    <div className={styles.label}>Qty</div>
                                                    <div className={styles.amount}>{e.quantity}</div>
                                                    <div className={styles.operation}>
                                                        <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                        <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.action}>
                                <button disabled={addOn && addOn.filter(x => x.active)?.length === 0} className={cn(styles.button, 'button-save-small')} onClick={buyAddOn}>Buy</button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>


            {/* <div className={styles.container}>
                <div className={styles.specialOffer}>
                    {addOns && addOns.filter(x => x.name === 'TaskFlow Engine Automation (1 million tasks)').map(x => (
                        <div className={styles.offer}>
                            <div className={styles.content}>
                                <div className={styles.title}>{x?.name}</div>
                                <div className={styles.description}>1 million TaskFlow Engine Automation</div>
                            </div>
                            <div className={styles.action}>
                                <button className={cn(styles.button, 'button-save-small')} onClick={() => {toggleAddon(x)}}>{!toggle ? 'Activate' : 'Deactivate'}</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.wrapper}>
                    <Card>
                        <div className={styles.upgrade}>
                            <div className={styles.toggle}>
                                {modes && modes.map((x, index) => (
                                    <button className={cn(styles.link, {[styles.active] : activeIndex === index})} onClick={() => setActiveIndex(index)}>
                                        <p>{x.name}</p>
                                    </button>
                                ))}
                            </div>

                            {activeIndex === 0 &&
                                <div className={styles.addOnsList}>
                                    {addOn && addOn.filter(x => x.type === 'recurring').filter(x => planCode === 'Plan_04' ? {...x} : (x.name === 'TaskFlow Engine Automation Overage')).map(e => (
                                        <div className={cn(styles.addOn, {[styles.disabled]: e.disabled})}>
                                            <div className={styles.addOnDetail}>
                                                <div className={styles.detail}>
                                                    <div className={styles.name}>{e.name}</div>
                                                    <div className={styles.description}>{e.description}</div>
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                                                </div>
                                            </div>
                                            <div className={styles.addOnPurchase}>
                                                <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                                                {e.active &&
                                                    <div className={styles.quantity}>
                                                        <div className={styles.label}>Qty</div>
                                                        <div className={styles.amount}>{e.quantity}</div>
                                                        <div className={styles.operation}>
                                                            <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                            <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {activeIndex === 1 &&
                                <div className={styles.addOnsList}>
                                    {addOn && addOn.filter(x => x.type === 'one_time').map(e => (
                                        <div className={cn(styles.addOn, {[styles.disabled]: e.disabled})}>
                                            <div className={styles.addOnDetail}>
                                                <div className={styles.detail}>
                                                    <div className={styles.name}>{e.name}</div>
                                                    <div className={styles.description}>{e.description}</div>
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                                                </div>
                                            </div>
                                            <div className={styles.addOnPurchase}>
                                                <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                                                {e.active &&
                                                    <div className={styles.quantity}>
                                                        <div className={styles.label}>Qty</div>
                                                        <div className={styles.amount}>{e.quantity}</div>
                                                        <div className={styles.operation}>
                                                            <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                            <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            <div className={styles.action}>
                                <button disabled={addOn && addOn.filter(x => x.active)?.length === 0} className={cn(styles.button, 'button-save-small')} onClick={buyAddOn}>Buy</button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div> */}
        </>
    )

}

export default Upgrade