import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Row from "./Row";

const Table = ({items}) => {

  const desiredLength = 10;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Invoice Number</div>
          <div className={styles.col}>Invoice Amount</div>
          <div className={styles.col}>Date</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row item={x} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Table;
