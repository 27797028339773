import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import {ReactComponent as GoogleIcon} from '../../../../../media/images/icons/google.svg'
import {ReactComponent as FacebookIcon} from '../../../../../media/images/icons/facebook.svg'
import {ReactComponent as UserProfile} from '../../../../../media/images/icons/Player_Icon.svg'
/* Deleted Schedule, Modal, Modal Products and Actions */
import moment from "moment";
import TooltipGlodal from "../../../../TooltipGlodal";

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    {item.thumbUrl !== undefined &&
                        <div className={styles.item}>
                            <div className={styles.preview}>
                                {item && item.thumbUrl == null && <UserProfile />}
                                {item && item.thumbUrl != null && <img src={item.thumbUrl} alt={`Players_${item.id}`} />}     
                            </div>
                            <div className={styles.playerDetails}>
                                <TooltipGlodal width={180} itemId={`player${item.id}`} title={(item.displayName != null && item.displayName != '') ? item.displayName : (item.username != null && item.username != '') ? item.username : (item.firstName != null && item.firstName != '') ? item.firstName : (item.customId != null && item.customId != '') ? item.customId : (item.email != null && item.email != '') ? item.email : '-'}><div className={styles.itemName}><span id={`player${item.id}`}>{(item.displayName != null && item.displayName != '') ? item.displayName : (item.username != null && item.username != '') ? item.username : (item.firstName != null && item.firstName != '') ? `${item.firstName} ${item.lastName}`: (item.customId != null && item.customId != '') ? item.customId : (item.email != null && item.email != '') ? item.email : '-'}</span></div></TooltipGlodal>
                                <div className={styles.id}>{item.id != null ? item.id : null}</div>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.col}><div className={styles.account}>{item.googleId != null ? <><span className={styles.linkedAccount}><GoogleIcon /><span className={styles.account}>{item.email != null ? item.email : '-'}</span></span></> : item.facebookId != null ? <span className={styles.linkedAccount}><FacebookIcon /><span className={styles.account}>{item.email != null ? item.email : '-'}</span></span> : item.customId != null ? <span className={styles.account}>{item.customId}</span> : item.email != null ? <span className={styles.account}>{item.email}</span> : null}</div></div>
                <div className={styles.col}>{item?.createdAt ? moment(item.createdAt).format('DD/MM/YYYY hh:mm A') : null}</div>
                <div className={styles.col}>{item?.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY hh:mm A') : null}</div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control className={styles.control} id={item.id != null ? item.id : null} appid={appid}playerName={item.name}/>
                    }
                </div>
                    
            </div>
        </>
    );
};

export default Row;
